<template>
    <div class="container-fluid">

        <div class="d-flex flex-column" v-if="company != null">
            <div class="d-flex mt-1">
                <button v-for="(year, index) in company.years" :key="index"
                        @click="selectedYear = index" class="yearBtn mx-1" 
                        :class="(selectedYear == index)? 'selectedBtn': ''">
                    {{ index }}  
                </button>
            </div>

            <div class="d-flex m-1" v-if="selectedYear != null">
                <router-link v-for="periodYear in company.years[selectedYear]" 
                            :key="periodYear.id" :to="'/period/'+periodYear.id"
                            class="periodBtn mx-1" :class="(periodYear.id == period.id)? 'selectedBtn': ''">
                    {{ periodYear.period }}
                    
                    <b-icon-check-lg v-if="periodYear.is_carryover"></b-icon-check-lg>
                </router-link>
            </div>
        </div>

        <div v-if="company != null">
            <h3>
                {{ company.name }} - 
                <span>{{ period.year }} / {{ period.period }}</span>
            </h3>

            <div class="d-flex justify-content-center">
                <template v-if="period.is_carryover">
                    <small> مرحّل </small>
                    <!-- <button class="btn btn-sm btn-outline-dark rounded-pill" @click="carryover">
                        الغاء الترحيل 
                        <b-icon-x-circle></b-icon-x-circle>
                    </button> -->
                </template>
                <template v-else>
                    <small> غير مرحّل </small>
                    <!-- <button class="btn btn-sm btn-warning rounded-pill" @click="carryover">
                        ترحيل الفترة 
                        <b-icon-file-arrow-up></b-icon-file-arrow-up>
                    </button> -->
                </template>

                <div class="form-check form-switch">
                    <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault" @change="carryover" :checked="(period.is_carryover == 1)">
                    <label class="form-check-label" for="flexSwitchCheckDefault"></label>
                </div>
            </div>

            <hr>
            <div class="d-flex justify-content-around w-75 m-auto">
                <button type="button" class="btn btn-outline-primary rounded rounded-pill" @click.prevent="showTablesAndPrint">
                    طباعة جميع البيانات <b-icon-printer-fill></b-icon-printer-fill>
                </button>
            </div>
        </div>
        
        <div v-if="company != null"> 
            <div class="row mt-4">
                <div class="col-5">
                    <GeneralEndorsmentTable :endorsment="endorsment" :period="period" :company="company"/>
                </div>

                <div class="col">
                    <ul class="nav nav-tabs" id="myTab" role="tablist">
                        <li class="nav-item" role="presentation">
                            <button class="nav-link active" id="purchases-tab" data-bs-toggle="tab" data-bs-target="#purchasesAndOutlays" type="button" role="tab" aria-controls="purchasesAndCustards" aria-selected="true">
                                المشتريات + المصاريف
                            </button>
                        </li>
                        <li class="nav-item" role="presentation">
                            <button class="nav-link" id="custards-tab" data-bs-toggle="tab" data-bs-target="#custards" type="button" role="tab" aria-controls="contact" aria-selected="false">
                                المستوردات
                            </button>
                        </li>
                        <li class="nav-item" role="presentation">
                            <button class="nav-link" id="imported-services-tab" data-bs-toggle="tab" data-bs-target="#importedServices" type="button" role="tab" aria-controls="contact" aria-selected="false">
                                الخدمات المستوردة
                            </button>
                        </li>
                        <li class="nav-item" role="presentation">
                            <button class="nav-link" id="sales-tab" data-bs-toggle="tab" data-bs-target="#sales" type="button" role="tab" aria-controls="profile" aria-selected="false">
                                المبيعات
                            </button>
                        </li>
                        <li class="nav-item" role="presentation">
                            <button class="nav-link" id="exported-sales-tab" data-bs-toggle="tab" data-bs-target="#exportedSales" type="button" role="tab" aria-controls="profile" aria-selected="false">
                                المبيعات المصدرة
                            </button>
                        </li>
                        <li class="nav-item" role="presentation">
                            <button class="nav-link" id="free-tax-sales-tab" data-bs-toggle="tab" data-bs-target="#freeTaxSales" type="button" role="tab" aria-controls="profile" aria-selected="false">
                                المبيعات غير خاضعة للضرائب
                            </button>
                        </li>

                        <li>
                            <div class="reports-men-layout position-relative">
                                <button class="btn" type="button" id="reportsToggleBtn" @click="showReportsLinks = !showReportsLinks">
                                    التقارير
                                </button>

                                <div class="reports-links-menu" v-if="showReportsLinks">
                                    <button class="dropdown-item" id="sales-report-tab" data-bs-toggle="tab" data-bs-target="#salesReport" type="button" role="tab" aria-controls="profile" aria-selected="false" @click="showReport('sales')">
                                        تقارير المبيعات
                                    </button>
                                    <button class="dropdown-item" id="purchases-report-tab" data-bs-toggle="tab" data-bs-target="#purchasesReport" type="button" role="tab" aria-controls="profile" aria-selected="false" @click="showReport('purchases')">
                                        تقارير المشتريات
                                    </button>
                                    <button class="dropdown-item" id="income-list-report-tab" data-bs-toggle="tab" data-bs-target="#incomeListReport" type="button" role="tab" aria-controls="profile" aria-selected="false" @click="showReport('income-list')">
                                        تقرير قائمة الدخل
                                    </button>
                                    <button class="dropdown-item" id="endorsements-analysis-report-tab" data-bs-toggle="tab" data-bs-target="#endorsementsAnalysisReport" type="button" role="tab" aria-controls="profile" aria-selected="false" @click="showReport('endorsements-analysis')">
                                        تقرير تحليل الاقرارات
                                    </button>
                                </div>
                            </div>
                        </li>
                    </ul>

            
                    <div class="tab-content" id="myTabContent">
                        <div class="tab-pane fade show active" id="purchasesAndOutlays" role="tabpanel" aria-labelledby="purchases-tab">
                            <PurchasesTables :period="period" :company="company" />
                        </div>
                        <div class="tab-pane fade" id="sales" role="tabpanel" aria-labelledby="sales-tab">
                            <SalesTable :period="period" :company="company" />
                        </div>
                        <div class="tab-pane fade" id="custards" role="tabpanel" aria-labelledby="custards-tab">
                            <CustardsTable :period="period" :company="company" />
                        </div>
                        <div class="tab-pane fade" id="importedServices" role="tabpanel" aria-labelledby="imported-services-tab">
                            <ImportedServicesTable :period="period" :company="company" />
                        </div>
                        <div class="tab-pane fade" id="exportedSales" role="tabpanel" aria-labelledby="exported-sales-tab">
                            <ExportedSalesTable :period="period" :company="company" />
                        </div>
                        <div class="tab-pane fade" id="freeTaxSales" role="tabpanel" aria-labelledby="free-tax-sales-tab">
                            <FreeTaxSalesTable :period="period" :company="company" />
                        </div>
                        
                        <!-- Reports Section -->
                        <div class="tab-pane fade" id="purchasesReport" role="tabpanel" aria-labelledby="purchases-report-tab">
                            <PurchasesReport :period="period" :company="company" />
                        </div>
                        <div class="tab-pane fade" id="salesReport" role="tabpanel" aria-labelledby="sales-report-tab">
                            <SalesReport :period="period" :company="company" />
                        </div>
                        <div class="tab-pane fade" id="incomeListReport" role="tabpanel" aria-labelledby="income-list-report-tab">
                            <template v-if="report_type == 'income-list'">
                                <IncomeListReport :company="company" :period="period" />
                            </template>
                        </div>
                        <div class="tab-pane fade" id="endorsementsAnalysisReport" role="tabpanel" aria-labelledby="endorsements-analysis-report-tab">
                            <template v-if="report_type == 'endorsements-analysis'">
                                <EndorsementsAnalysisReport :company="company" :period="period" />
                            </template> 
                        </div>
                    </div>
                </div>
            </div>

            <!-- Print All Period Data Section -->
            <div v-if="printAllTables" id="printAllTables">
                <h3 class="text-center mt-3 mb-1 dsip">شركة {{ company.name }}</h3>
                <h4 class="text-center mb-1">الفترة {{ period.year }} / {{ period.period }}</h4>

                <h4 class="text-end my-3">الجدول العام</h4>
                <GeneralEndorsmentTable :endorsment="endorsment" :period="period" :company="company"/>
                <PurchasesTables :period="period" :company="company" />

                <h4 class="text-end my-3">جدول المبيعات</h4>
                <SalesTable :period="period" :company="company" />
                
                <h4 class="text-end my-3">جدول المستوردات</h4>
                <CustardsTable :period="period" :company="company" />

                <h4 class="text-end my-3">جدول الخدمات المستوردة</h4>
                <ImportedServicesTable :period="period" :company="company" />

                <h4 class="text-end my-3">جدول المبيعات المصدّرة</h4>
                <ExportedSalesTable :period="period" :company="company" />

                <h4 class="text-end my-3">جدول المبيعات غير خاضعة للضرائب</h4>
                <FreeTaxSalesTable :period="period" :company="company" />
            </div>
        </div>
        <div v-else>
            <img src="/images/loader.gif" alt="loader image" >
        </div>
    </div>
</template>


<style>
    .yearBtn{
        background: #e3e3e3;
        border: 1px solid #b3b3b3;
        color: #484848;
        border-radius: 6px;
        padding: 4px 20px;
    }

    .yearBtn:hover{
        font-weight: bold;
        box-shadow: 1px 1px 6px #dbdbdb;
    }

    .periodBtn{
        background: #4e4e4e;
        border: 1px solid transparent;
        color: #ffffff;
        border-radius: 6px;
        padding: 1px 17px;
        text-decoration: none;
        transition:0.3s;
    }

    .periodBtn:hover{
        font-weight: bold;
        box-shadow: 2px 2px 8px #afafaf;
        transform: translateY(-3px);
    }

    .selectedBtn{
        background: #e14141;
        color:white;
    }

    .nav-link.active {
        border-color: #a8ccfd !important;
    }

    .header-background{ background-color: #e9e9e9;}
    .footer-background{ background-color: #e9e9e9;}


    .reports-links-menu {
        position: absolute;
        background: white;
        top: 40px;
        left: -44px;
        box-shadow: 1px 1px 3px #dbdbdb;
    }

    #reportsToggleBtn {
        color: #2c73fd;
    }

    .nav-link.active {
        border-color: #6b8bb5 !important;
        font-weight: bold;
        color: #495e7a !important;
    }
</style>

<script>
import GeneralEndorsmentTable from '../components/endorsment/generalTable.vue';
import PurchasesTables from '../components/endorsment/purchasesTables.vue';
import CustardsTable from '../components/endorsment/custardsTable.vue';
import SalesTable from '../components/endorsment/salesTable.vue';
import ExportedSalesTable from '../components/endorsment/exportedSalesTable.vue';
import PurchasesReport from '../components/endorsment/purchasesReport.vue';
import SalesReport from '../components/endorsment/salesReport.vue';
import FreeTaxSalesTable from '../components/endorsment/freeTaxSales.vue';
import ImportedServicesTable from '../components/endorsment/importedServices.vue';
import IncomeListReport from '../components/reports/incomeListReport.vue';
import EndorsementsAnalysisReport from '../components/reports/endorsementsAnalysisReport.vue';
import config from "../../public/js/config";
import printJS from 'print-js';
import $ from 'jquery';

export default {
    name: "Period",
    components: {
        GeneralEndorsmentTable,
        PurchasesTables,
        CustardsTable,
        SalesTable,
        ExportedSalesTable,
        FreeTaxSalesTable,
        PurchasesReport,
        SalesReport,
        IncomeListReport,
        EndorsementsAnalysisReport,
        ImportedServicesTable
    },
    data(){
        return {
            endorsment: null,
            company: null,
            selectedYear: null,
            printAllTables: false,
            selectedYear: null,
            showReportsLinks: false,
            report_type: null,
        }
    },
    computed: {
        'period': function(){
            return this.$store.state.generalTablePeriod.period;
        }  
    },
    methods: {
        "showTablesAndPrint": async function(){
            this.printAllTables = true;
            setTimeout(async () =>  {
                await this.print();
                this.printAllTables = false;
            }, 500);
        },
        async print() {
            let target = $('#printAllTables').clone();
            $(target).attr('id', "clonePrinting");
            $(target).find('.dsip').remove();

            $(target).find('input').replaceWith(function(){
                return $("<span></span>", {html: $(this).val()});
            });

            $(target).find('.table-striped').removeClass('table-striped');
            $(target).find('thead tr').addClass('header-background');
            $(target).find('tfoot tr').addClass('footer-background');
            $(target).append('<div class="footer-print">'+
                            '<span class="mx-3">'+ this.$store.state.auth.user.name + '</span> - ' + 
                            '<span class="mx-2 text-muted">'+ new Date().toLocaleString() + '</span></div>');

            $(target).find('#printGeneralTable table tr td').css({'padding': '2px', 'font-size': '13px'});
            $(target).find('#printGeneralTable #periodNotice').css({'width': '80%'});
            $(target).find('hr').remove();


            $('body').append(target);

            // height of A4 page is : 1123 px
            let sections = $(target).find('.print-section');
            for (let i = 0; i < sections.length; i++) {
                let elementHeight = $(sections[i]).innerHeight();
                let result;
                if( Math.ceil(elementHeight / 1013) > 0 ){
                    result = 1013 - (elementHeight % 1013);
                }
                else{
                    result = 1013 - elementHeight;
                }
                // console.log( elementHeight , result);

                let marginTop = (i == 0)? 65 : result;

                if(i < sections.length - 1 )
                    $(sections[i]).append("<div style='height: "+marginTop+"px; width: 500px;'></div>");
            }
            
            printJS({
                printable: "clonePrinting", 
                type: 'html', 
                scanStyles: false,
                header: "<h2 class='text-center my-2' >"+ this.company.name +"</h2>" ,
                css: "/css/bootstrap.min.css",
            });

            $('#clonePrinting').remove();
        },
        "carryover": function(event){
            let newStatus = event.target.checked;
            let token = this.$store.state.auth.user['token'];
            this.axios.put(config.api_url() +'periods/'+ this.period.id, {'is_carryover': newStatus}, {headers: {"Authorization": "Bearer " + token}} )
                .then((response) => {
                    if(response.status == 200){
                        this.$store.commit('generalTablePeriod/setPeriod', response.data.period );
                    }
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        alert('تم انتهاء جلسة الدخول , أعد تسجبل الدخول .');
                        window.location.href = "/logout";
                    }
                    console.log(error);
                });
        },

        "showReport": function(type){
            this.showReportsLinks = false;

            if( type != 'sales' && type != 'purchases' ){
                this.report_type = type;
            }
            else{
                this.report_type = null;
            }
        }
    },
    created(){
       let period_id = this.$route.params.id;
       let token = this.$store.state.auth.user['token'];

       this.axios.get(config.api_url() +'periods/'+ period_id, {headers: {"Authorization": "Bearer " + token}})
        .then((response) => {
            // console.log(response);
            if(response.status == 200){
                this.period = response.data.period;
                this.endorsment = response.data.period.endorsment;
                this.company = response.data.period.company;
                this.$store.commit('generalTablePeriod/setPeriod', response.data.period );
                this.$store.commit('generalTablePeriod/setPurchasesTable', response.data.period.purchases );
                this.$store.commit('generalTablePeriod/setOutlaysTable', response.data.period.outlays);
                this.$store.commit('generalTablePeriod/setCustardsTable', response.data.period.custards);
                this.$store.commit('generalTablePeriod/setImportedServicesTable', response.data.period.imported_services);
                this.$store.commit('generalTablePeriod/setExportedSalesTable', response.data.period.exported_sales);
                this.$store.commit('generalTablePeriod/setFreeTaxSalesTable', response.data.period.free_tax_sales);
                this.$store.commit('generalTablePeriod/setSalesTable', response.data.period.sales);
                this.$store.commit('generalTablePeriod/setSettingsList', response.data.settings);
            }
        })
        .catch((error) => {
            if (error.response.status === 401) {
                alert('تم انتهاء جلسة الدخول , أعد تسجبل الدخول .');
                window.location.href = "/logout";
            }
            console.log(error);
        });

        $('td input').on('keyup', function(e) {
            console.log(e.key);
        });
        // TODO
        // add event listener for entry key, on inputs to switch to another one,
        // and if next switch is add button, so trigger click it
    }
}
</script>