<template>
    <div class="container">

        <addUserTemplate />

        <hr>

        <usersTableTemplate />

        <!-- <div class="editUserLayout">
            <form>
                <div class="m-auto my-1">
                    <div class="col d-flex flex-column align-items-start">
                        <label for="editusername">إسم المستخدم</label>
                        <input type="text" id="editusername" class="form-control" placeholder="إسم المستخدم">
                    </div>
                    
                    <div class="col d-flex flex-column align-items-start">
                        <label for="edituseremail">البريد الإلكتروني</label>
                        <input type="text" id="edituseremail" class="form-control" placeholder="البريد الإلكتروني">
                    </div>

                    <div class="col d-flex flex-column align-items-start">
                        <label for="editusername">كلمة المرور</label>
                        <input type="password" id="edituserpassword" class="form-control" placeholder="كلمة المرور">
                    </div>
                </div>
            
                <button @click.prevent="editUser" class="btn btn-success">تعديل</button>
            </form>
        </div> -->
    </div>


</template>

<style scoped>
.editUserLayout{
    position: absolute;
    top:20vh;
    left: 25%;
    width: 50%;
    background: white;
    display: none;
}
</style>

<script>
import axios from "axios";
import config from "../../../public/js/config.js";
import $ from "jquery";
import alertFunctions from "../../../public/js/alertFunctions.js";
import usersTableTemplate from "../../components/dashboard/users/users.vue";
import addUserTemplate from "../../components/dashboard/users/add.vue";

export default {
    name: "mainPageDashboard",
    components: {
        usersTableTemplate,
        addUserTemplate
    },
    data(){
        return {
            users: [],
        }
    },
    methods: {
    },
    created: function(){
        let admin = JSON.parse(window.localStorage.getItem('admin'));
        axios.get(config.api_url() + 'dashboard/users', {headers: {"admin-token": admin.token} })
            .then((response) => {
                if(response.status == 200){
                    this.users = response.data.users;
                }
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    alert('تم انتهاء جلسة الدخول , أعد تسجبل الدخول .');
                    window.location.href = "/logout";
                }
                console.log(error);
            });
    },
    
}
</script>