<template>
    <tr>
        <td>
            <input type="text" id="invoiceNumber" v-model="invoiceNumber" class="form-control p-xxl-1 p-0" @keydown.enter="switchFocus" placeholder="رقم الفاتورة">
        </td>
        <td style="width: 150px;font-size: 12px;">
            <input type="text" id="invoiceDate" v-model="invoiceDate" class="form-control p-xxl-1 p-0" @keydown.enter="switchFocus" placeholder="اليوم - الشهر - السنة">
        </td>
        <td>
            <select id="invoiceRate" class="form-control py-0 px-1 p-xxl-1" v-model="invoiceRateFromSelect" @keydown.enter="switchFocus">
                <option selected>-</option>
                <option v-for="setting in settings" :key="setting.id" :value="setting.tax">{{ setting.tax }}</option>

                <template v-if="withZeroAndExemptTaxes">
                    <option value="zero">صفرية</option>
                    <option value="exempt">معفاة</option>
                </template>

                <option value="other">أخرى</option>
            </select>
            <input type="number" min="0" step="0.5" v-if="invoiceRateFromSelect == 'other'" v-model="invoiceRate" id="invoiceRate" class="form-control p-0" @keydown.enter="switchFocus" placeholder="النسبة">
        </td>
        <td>
            <input type="number" min="0" v-model="invoiceValue" @change="invoiceValueChanged" id="invoiceValue" class="form-control p-xxl-1 p-0" @keydown.enter="switchFocus" placeholder="القيمة">
        </td>
        <td>
            <input type="number" min="0" v-model="invoiceTax" @change="invoiceTaxChanged" id="invoiceTax" class="form-control p-xxl-1 p-0" @keydown.enter="switchFocus" placeholder="الضريبة"
                    v-show="invoiceRateFromSelect != 'exempt' && invoiceRateFromSelect != 'zero'">
        </td>
        <td>
            <input type="number" min="0" v-model="invoiceTotal" id="invoiceTotal" class="form-control p-xxl-1 p-0" placeholder="الإجمالي" @keydown.enter="switchFocus" readonly>
        </td>
        <td class="d-flex">
            <button class="btn btn-success btn-sm rounded-circle save-btn" @click="editInvoice">
                <b-icon-pencil></b-icon-pencil>
            </button>
            <button class="btn btn-warning btn-sm rounded-circle me-1" @click="backFromEdit">
                <b-icon-arrow-return-left></b-icon-arrow-return-left>
            </button>
        </td>
    </tr>
</template>

<script>
import alertFunctions from "../../../public/js/alertFunctions";
import config from "../../../public/js/config";
import functions from "../../../public/js/globalFunctions";
import $ from 'jquery';

export default {
    name: "EditInvoiceWithTax",
    props: ['period_id'],
    data: () => {
        return {
            invoice: null,
            type: "",
            invoiceNumber: "",
            invoiceTax: "",
            invoiceRate: "",
            invoiceValue: "",
            invoiceDate: "",
            invoiceTotal: "",
            invoiceRateFromSelect: "",
            isRequesting: false,
        }
    },
    watch: {
        "invoiceDate": function(){
            this.invoiceDate+= this.invoiceDate.length == 2 ? "-" : "";
            this.invoiceDate+= this.invoiceDate.length == 5 ? "-" : "";
            this.invoiceDate = this.invoiceDate.replace('--', '-');
            this.invoiceDate = (this.invoiceDate.length > 10)? this.invoiceDate.slice(0,10) : this.invoiceDate;
        },
        "invoiceRate": function(){
            if( this.selectedInvoiceForEdit.type != "custards" ){   // prevent calculate for custards section
                if(this.invoiceTax != null && this.invoiceTax != undefined){
                    this.invoiceValue = (this.invoiceTax / (this.rightRateInput.value /100)).toFixed(3);
                    this.invoiceTotal = parseFloat(this.invoiceTax + this.invoiceValue).toFixed(3);
                }
                else if(this.invoiceValue != null && this.invoiceValue != undefined){
                    this.invoiceTax = ((this.rightRateInput.value/100) * this.invoiceValue).toFixed(3);
                    this.invoiceTotal = parseFloat(this.invoiceTax + this.invoiceValue).toFixed(3);
                }
            }
        },
    },
    computed: {
        settings: function(){
            return this.$store.state.generalTablePeriod.settings;
        },
        rightRateInput: function(){
            if(['zero', 'exempt'].includes(this.invoiceRateFromSelect)) {
                return {value: 0, type: this.invoiceRateFromSelect};
            }
            else {
                return (this.invoiceRateFromSelect != 'other')? 
                    {value: this.invoiceRateFromSelect, type: null} :
                    {value: this.invoiceRate, type: 'other'};
            }
        },
        selectedInvoiceForEdit: function(){
           return {
                "invoice": this.$store.state.invoiceData.editInvoice , 
                "type": this.$store.state.invoiceData.typeEditInvoice
            };
        },
        withZeroAndExemptTaxes: function(){
            return (['sales', 'purchases', 'custards'].includes(this.type));
        }
    },
    methods: {
        "editInvoice": function(){
            if(this.isRequesting == true)
                return false;

            let token = this.$store.state.auth.user['token'];
            let rate = (this.rightRateInput.type == null || this.rightRateInput.type == 'other')? 
                    this.rightRateInput.value : this.rightRateInput.type; 

            let formData = {
                invoiceNumber: this.invoiceNumber,
                invoiceTax: this.invoiceTax,
                invoiceValue: this.invoiceValue,
                invoiceDate: this.invoiceDate,
                invoiceTotal: ( ((!isNaN(this.invoiceTax))? this.invoiceTax : 0) + this.invoiceValue),
                invoiceRate: rate,
                invoiceType: this.type,
                period_id: this.period_id,
            };

            this.isRequesting = true;
            this.axios.post(config.api_url() + "update-invoice/" + this.invoice.invoice.id, formData, {headers: {"Authorization": "Bearer " + token}})
                .then((response) => {
                    if(response.data.status == true){

                        let table = null;
                        switch(this.type){
                            case "purchases":  table = this.$store.state.generalTablePeriod.purchasesTable; break;
                            case "outlays":  table = this.$store.state.generalTablePeriod.outlaysTable; break;
                            case "custards":  table = this.$store.state.generalTablePeriod.custardsTable; break;
                            case "sales":  table = this.$store.state.generalTablePeriod.salesTable; break;
                            case "imported_services":  table = this.$store.state.generalTablePeriod.importedServicesTable; break;
                        }
                        for( let i = 0; i < table.length; i++ ){
                            if( table[i]['invoice_id'] == this.invoice.invoice.id ){
                            
                                let obj = table[i];
                                obj.invoice.number = formData.invoiceNumber;
                                obj.invoice.tax = parseFloat(formData.invoiceTax);
                                obj.invoice.value = parseFloat(formData.invoiceValue);
                                obj.invoice.rate = (rate != 'zero' && rate != 'exempt')? rate : 0;
                                obj.invoice.date = this.reverseString(this.invoiceDate);
                                obj.invoice.total = parseFloat(formData.invoiceTotal);

                                if(this.type == 'sales')
                                    obj.sales_type = (rate == 'zero' || rate == 'exempt')? rate : null;
                                if(this.type == 'purchases')
                                    obj.purchases_type = (rate == 'zero' || rate == 'exempt')? rate : null;
                                if(this.type == 'custards')
                                    obj.custards_type = (rate == 'zero' || rate == 'exempt')? rate : null;

                                table[i] = obj;
                            }
                        }         

                        if(response.data.invoice != null){
                            switch(this.type){
                                case "purchases": this.$store.commit('generalTablePeriod/setPurchasesTable', table); break;
                                case "outlays":  this.$store.commit('generalTablePeriod/setOutlaysTable', table); break;
                                case "custards":  this.$store.commit('generalTablePeriod/setCustardsTable', table); break;
                                case "sales":  table = this.$store.commit('generalTablePeriod/setSalesTable', table); break;
                            }

                            this.backFromEdit();
                            functions.getEndorsmentData(this.period_id);
                            alertFunctions.SuccessAlert("تم التعديل على الفاتورة بنجاح .");
                        }
                    }
                    else{
                        alertFunctions.ErrorAlert(response.data.message);
                    }
                    this.isRequesting = false;
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        alert('تم انتهاء جلسة الدخول , أعد تسجبل الدخول .');
                        window.location.href = "/logout";
                    }
                    else{
                        alertFunctions.ErrorAlert("حدث خطأ ما اثناء التعديل على الفاتورة !");
                    }
                    this.isRequesting = false;
                });

        },
        "backFromEdit": function(){
            this.$store.commit('invoiceData/setEditInvoice', {invoice: null, type:''});
        },
        "invoiceValueChanged": function(){
            if( this.selectedInvoiceForEdit.type != "custards" ){   // prevent calculate for custards section
                if(this.rightRateInput != null && this.rightRateInput != undefined)
                    this.invoiceTax = parseFloat((this.rightRateInput.value/100) * this.invoiceValue).toFixed(3);
            }

            this.invoiceTotal = (parseFloat(this.invoiceTax) + parseFloat(this.invoiceValue)).toFixed(3);
        },
        "invoiceTaxChanged": function(){
            if( this.selectedInvoiceForEdit.type != "custards" ){   // prevent calculate for custards section
                if(this.rightRateInput != null && this.rightRateInput != undefined)
                    this.invoiceValue = (this.invoiceTax / (this.rightRateInput.value /100)).toFixed(3);
            }
            this.invoiceTotal = (parseFloat(this.invoiceTax) + parseFloat(this.invoiceValue)).toFixed(3);
        },
        "reverseString": function(str){
            var splitString = str.split("-");
            var reverseArray = splitString.reverse();
            var joinArray = reverseArray.join("-");
            return joinArray;
        },
        "switchFocus": function() {
            var next = $(':focus').parent().next();
            if ( next.find('.save-btn').length > 0 ) {
                next.find('.save-btn').trigger('click');
            }
            else {
                next.find('input,select').focus();
            }
        }
    },
    created: function() {
        this.invoice = this.selectedInvoiceForEdit.invoice;
        this.type = this.selectedInvoiceForEdit.type;

        this.invoiceNumber = this.invoice.invoice.number;
        this.invoiceTax = this.invoice.invoice.tax;
        this.invoiceRate = this.invoice.invoice.rate;
        this.invoiceValue = this.invoice.invoice.value;
        this.invoiceDate = this.reverseString(this.invoice.invoice.date);
        this.invoiceTotal = this.invoice.invoice.total;
        
        if( this.type == 'sales' ) {
            if( this.invoice.sales_type != 'zero' && this.invoice.sales_type != 'exempt'){
                let isExists = this.settings.filter((item) => item.txt == this.invoice.invoice.rate);
                if( isExists ) {
                    this.invoiceRateFromSelect = this.invoice.invoice.rate;
                }
                else {
                    this.invoiceRateFromSelect = 'other';
                }
            }
            else {
                this.invoiceRateFromSelect = this.invoice.sales_type;
            }
        }
        else if( this.type == 'purchases' ) {
            if( this.invoice.purchases_type != 'zero' && this.invoice.purchases_type != 'exempt'){
                let isExists = this.settings.filter((item) => item.txt == this.invoice.invoice.rate);
                if( isExists ) {
                    this.invoiceRateFromSelect = this.invoice.invoice.rate;
                }
                else {
                    this.invoiceRateFromSelect = 'other';
                }
            }
            else {
                this.invoiceRateFromSelect = this.invoice.purchases_type;
            }
        }
        else if( this.type == 'custards' ) {
            if( this.invoice.custards_type != 'zero' && this.invoice.custards_type != 'exempt'){
                let isExists = this.settings.filter((item) => item.txt == this.invoice.invoice.rate);
                if( isExists ) {
                    this.invoiceRateFromSelect = this.invoice.invoice.rate;
                }
                else {
                    this.invoiceRateFromSelect = 'other';
                }
            }
            else {
                this.invoiceRateFromSelect = this.invoice.custards_type;
            }
        }
        else {
            let isExists = false;
            for (let index = 0; index < this.settings.length; index++) {
                if(this.settings[index].tax == this.invoice.invoice.rate){
                    isExists = true;
                    this.invoiceRateFromSelect = this.invoice.invoice.rate;
                    break;
                }
            }
            if( !isExists ){
                this.invoiceRateFromSelect = "other";
            }
        }
   
    },
}
</script>