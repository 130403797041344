<template>
    <tr>
        <td>
            <input type="text" id="invoiceNumber" v-model="invoiceNumber" class="form-control p-xxl-1 p-0" @keydown.enter="switchFocus" placeholder="رقم الفاتورة">
        </td>
        <td style="width: 150px;font-size: 14px;">
            <input type="text" id="invoiceDate" v-model="invoiceDate" class="form-control p-xxl-1 p-0" @keydown.enter="switchFocus" placeholder="اليوم - الشهر - السنة">
        </td>
        <td>
            <input type="number" min="0" v-model="invoiceValue" id="invoiceValue" class="form-control p-xxl-1 p-0" @keydown.enter="switchFocus" placeholder="القيمة">
        </td>
        <td>
            <input type="number" min="0" v-model="invoiceTotal" id="invoiceTotal" class="form-control p-xxl-1 p-0" @keydown.enter="switchFocus" placeholder="الإجمالي" readonly>
        </td>
        <td class="d-flex">
            <button class="btn btn-dark btn-sm rounded-circle save-btn" @click="saveInvoice">
                <b-icon-plus-lg></b-icon-plus-lg>
            </button>
            <button class="btn btn-danger btn-sm rounded-circle me-1" @click="resetInputs">
                <b-icon-x-lg></b-icon-x-lg>
            </button>
        </td>
    </tr>
</template>

<script>
import alertFunctions from "../../../public/js/alertFunctions.js";
import config from "../../../public/js/config";
import functions from "../../../public/js/globalFunctions";
import $ from 'jquery';

export default {
    name: "AddInvoiceWithoutTax",
    props: ["period_id", "invoiceType"],
    data: () => {
        return {
            invoiceNumber: "",
            invoiceDate: "",
            invoiceTotal: "",
            invoiceValue: "",
            isRequesting: false,
        }
    },
    watch: {
        "invoiceDate": function(){
            this.invoiceDate+= this.invoiceDate.length == 2 ? "-" : "";
            this.invoiceDate+= this.invoiceDate.length == 5 ? "-" : "";
            this.invoiceDate = this.invoiceDate.replace('--', '-');
            this.invoiceDate = (this.invoiceDate.length > 10)? this.invoiceDate.slice(0,10) : this.invoiceDate;
        },
        "invoiceValue": function(){
            this.invoiceTotal =  this.invoiceValue;
        },
        "invoiceTotal": function(){
            let total = (this.invoiceTotal > 0)? (this.invoiceTotal * 1.0) : 0.0;
            this.invoiceTotal = total.toFixed(3);
        }
    },
    methods: {
        "saveInvoice": function(){
            if(this.isRequesting == true)
                return false;
            
            if( this.validateInputs() == false ) {
                alertFunctions.ErrorAlert("يرجى تعبئة قيم بيانات الفاتورة قبل الحفظ !");
                return false;
            }

            let token = this.$store.state.auth.user['token'];
            let formData = new FormData();
            formData.append('invoiceNumber', this.invoiceNumber);
            formData.append('invoiceValue', this.invoiceValue);
            formData.append('invoiceDate', this.invoiceDate);
            formData.append('invoiceTotal', this.invoiceTotal);
            formData.append('period_id', this.period_id);

            this.isRequesting = true;
            this.axios.post(config.api_url() + this.invoiceType, formData, {headers: {"Authorization": "Bearer " + token}})
                .then((response) => {
                    if( response.data.status == "success" ){
                        alertFunctions.SuccessAlert("تم حفظ الفاتورة بنجاح");
                        
                        let arr = [];
                        switch (this.invoiceType) {
                            case "zeros-sales":
                                arr = this.$store.state.generalTablePeriod.zerosSalesTable;
                                arr.push(response.data.sale);
                                this.$store.commit('generalTablePeriod/setZerosSalesTable', arr);
                                break;
                    
                            case "exported-sales":
                                arr = this.$store.state.generalTablePeriod.exportedSalesTable;
                                arr.push(response.data.sale);
                                this.$store.commit('generalTablePeriod/setExportedSalesTable', arr);
                                break;

                            case "exempt-sales":
                                arr = this.$store.state.generalTablePeriod.exemptSalesTable;
                                arr.push(response.data.exemptSale);
                                this.$store.commit('generalTablePeriod/setExemptSalesTable', arr);
                                break;

                            case "freeTax-sales":
                                arr = this.$store.state.generalTablePeriod.freeTaxSalesTable;
                                arr.push(response.data.sale);
                                this.$store.commit('generalTablePeriod/setFreeTaxSalesTable', arr);
                                break;
                        }

                        functions.getEndorsmentData(this.period_id);
                        this.resetInputs();
                    }
                    else{
                        let errors = response.data.messages;
                        let result = "";
                        errors.forEach(error => {
                            result+= error + "\n";
                        });
                        alertFunctions.ErrorAlert(result);
                    }
                    this.isRequesting = false;
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        alert('تم انتهاء جلسة الدخول , أعد تسجبل الدخول .');
                        window.location.href = "/logout";
                    }
                    else{
                        alertFunctions.ErrorAlert("حدث خطأ ما !!");
                    }
                    this.isRequesting = false;
                });
        },
        "validateInputs": function(){
            if( this.invoiceNumber == "" ||
                this.invoiceDate == "" ||
                this.invoiceTotal == "" ||
                this.invoiceValue == ""
            ){
                return false;
            }

            return true;
        },
        "resetInputs": function(){
            this.invoiceNumber = "";
            this.invoiceDate = "";
            this.invoiceValue = "";
        },
        "switchFocus": function() {
            var next = $(':focus').parent().next();
            if ( next.find('.save-btn').length > 0 ) {
                next.find('.save-btn').trigger('click');
            }
            else {
                next.find('input,select').focus();
            }
        }
    },
}
</script>