<template>
    <div class="mb-2">
        <label class="import_file_label" @click.prevent="showForm = !showForm">استيراد ملف</label>

        <div v-if="showForm">
            <div v-if="invoicesType == 'sales'" class="d-flex">
                <div class="mx-2">
                    <label for="normalSalesType">مبيعات مع ضريبة</label>
                    <input type="radio" name="sales_type" value="normal" id="normalSalesType" v-model="sales_type" :checked="sales_type == 'normal'">
                </div>
                <div class="mx-2">
                    <label for="exemptSalesType">مبيعات معفاة</label>
                    <input type="radio" name="sales_type" value="exempt" id="exemptSalesType" v-model="sales_type" :checked="sales_type == 'exempt'">
                </div>
                <div class="mx-2">
                    <label for="zeroSalesType">مبيعات صفرية</label>
                    <input type="radio" name="sales_type" value="zero" id="zeroSalesType" v-model="sales_type" :checked="sales_type == 'zero'">
                </div>
            </div>
            <div v-else-if="invoicesType == 'purchases'" class="d-flex">
                <div class="mx-2">
                    <label for="normalPurchasesType">مشتريات مع ضريبة</label>
                    <input type="radio" name="purchases_type" value="normal" id="normalPurchasesType" v-model="purchases_type" :checked="purchases_type == 'normal'">
                </div>
                <div class="mx-2">
                    <label for="exemptPurchasesType">مشتريات معفاة</label>
                    <input type="radio" name="purchases_type" value="exempt" id="exemptPurchasesType" v-model="purchases_type" :checked="purchases_type == 'exempt'">
                </div>
                <div class="mx-2">
                    <label for="zeroPurchasesType">مشتريات صفرية</label>
                    <input type="radio" name="purchases_type" value="zero" id="zeroPurchasesType" v-model="purchases_type" :checked="purchases_type == 'zero'">
                </div>
            </div>
            <div v-else-if="invoicesType == 'custards'" class="d-flex">
                <div class="mx-2">
                    <label for="normalCustardsType">مستوردات مع ضريبة</label>
                    <input type="radio" name="custards_type" value="normal" id="normalCustardsType" v-model="custards_type" :checked="custards_type == 'normal'">
                </div>
                <div class="mx-2">
                    <label for="exemptCustardsType">مستوردات معفاة</label>
                    <input type="radio" name="custards_type" value="exempt" id="exemptCustardsType" v-model="custards_type" :checked="custards_type == 'exempt'">
                </div>
                <div class="mx-2">
                    <label for="zeroCustardsType">مستوردات صفرية</label>
                    <input type="radio" name="custards_type" value="zero" id="zeroCustardsType" v-model="custards_type" :checked="custards_type == 'zero'">
                </div>
            </div>
            <br>

            <div>
                <button @click.prevent="downloadFile" class="btn btn-outline-success btn-sm btn-rounded rounded-pill m-1">
                    تنزيل النموذج
                    <img src="/images/excel-icon.png" alt="excel icon" width="35">
                </button>

                <label :for="'import_file_'+invoicesType" class="btn btn-outline-success btn-sm btn-rounded rounded-pill m-1">
                    إختيار الملف
                    <img src="/images/upload-icon.png" alt="upload icon" width="35">
                </label>

                <input type="file" class="importExcelFile" :id="'import_file_'+invoicesType" @change="previewFiles">
            </div>
            <br>

            <button type="button" class="btn btn-success btn-sm import_file_btn" @click.prevent="importInvoices" v-if="showUploadBtn">
                رفع <b-icon-cloud-upload></b-icon-cloud-upload>
            </button>

            <span v-html="uploadStatus"></span>
            <hr>
        </div>
     
    </div>
</template>

<style scoped>
    .import_file_label{
        border: 1px solid #565656;
        background: #4c5256;
        color: white;
        padding: 2px 9px;
        border-radius: 4px;
        margin: 3px 7px;
        box-shadow: 0px 0px 7px #e5e5e5;
    }
    .import_file_label:hover{
        cursor: pointer;
    }
    .importExcelFile{
        display: none;
    }

    .table-note{
        font-size: 14px;
        color: #878787;
    }
</style>

<script>
import config from "../../../public/js/config.js";
import alertFunctions from "../../../public/js/alertFunctions.js";

export default {
    name: "ImportFromExcel",
    props: ["invoicesType", "period_id"],
    data: () => {
        return{
            showForm: false,
            showUploadBtn: false,
            uploadStatus: null,
            sales_type: "normal",
            purchases_type: "normal",
            custards_type: "normal"
        }
    },
    methods: {
        "previewFiles": function(){
            this.showUploadBtn = true;
        },
        "downloadFile":function(){
            let list = ["exported_sales", "free_tax_sales"];
            let fileType = 'with-tax';
            
            if(list.includes(this.invoicesType)) {
                fileType = 'without-tax';
            }
            else {
                if(this.invoicesType == 'sales' && this.sales_type != 'normal') {
                    fileType = 'without-tax';
                }
                else if(this.invoicesType == 'purchases' && this.purchases_type != 'normal') {
                    fileType = 'without-tax';
                }
                else if(this.invoicesType == 'custards') {
                    if(this.custards_type != 'normal') {
                        fileType = 'without-tax';
                    }
                    else {
                        fileType+= (anotherList.includes(this.invoicesType))? '-and-val-col' : '';
                    }
                }
            }

            window.open(config.url()  + 'download-standard-excel-file?type='+fileType);
        },
        "importInvoices": function(){
            let token = this.$store.state.auth.user['token'];
            let formData = new FormData();
            let file = document.getElementById('import_file_'+this.invoicesType).files[0];
            if(file != null){
                formData.append("excel_file", file);
                formData.append("type", this.invoicesType);
                formData.append("sales_type", this.sales_type);
                formData.append("purchases_type", this.purchases_type);
                formData.append("custards_type", this.custards_type);
                this.uploadStatus = "يرجى الانتظار ...";

                this.axios.post(config.api_url() + "import-invoices/"+this.period_id, formData, {headers: {"Authorization": "Bearer " + token}})
                    .then((response) => {
                        if(response.data.status == true){
                            if( response.data.result != null ) {
                                this.uploadStatus = "<span class='text-danger'>"+
                                                "هناك فواتير غير صحيحة, يرجى تنزيل هذا " + 
                                                "<a href='"+config.url()+ 'download-invalid-invoices?filename=' + response.data.result +"' target='_blank'> الملف </a>" +
                                                "والتعديل عليه, ومن ثم رفعه مرة أخرى ."+
                                                "</span>";

                            }
                            else {
                                alertFunctions.SuccessAlert("تم استيراد الملف بنجاح .");

                                setTimeout(() => {
                                    window.location.reload();
                                }, 1500);
                            }
                        }
                        else{
                            alertFunctions.ErrorAlert(response.data.result);
                        }
                    })
                    .catch((error) => {
                        if (error.response.status === 401) {
                            alert('تم انتهاء جلسة الدخول , أعد تسجبل الدخول .');
                            window.location.href = "/logout";
                        }
                        else{
                            this.uploadStatus = "حدث خطأ ما !";
                            alertFunctions.ErrorAlert("حدث خطأ ما في استيراد الملف .");
                        }
                        console.log(error);
                    });
            }
            else{
                alertFunctions.ErrorAlert("يجب أن ترفع ملفاً ...");
            }
        }
    },
}
</script>