<template>
   <div id="printEndorsementsAnalysisReport" dir="rtl">
    
        <div class="row justify-content-center">
            <div class="col-10 d-flex justify-content-between">
                <h3 class="dsip">تقرير تحليل الإقرارات</h3>

                <button type="button" class="btn btn-primary btn-sm mb-1 dsip" @click.prevent="print">
                    طباعة <b-icon-printer-fill></b-icon-printer-fill>
                </button>
            </div>

            <div class="col-10">
                {{ requestStatus }}
                <table class="table table-bordered table-striped table-hover">
                    <thead>
                        <tr>
                            <th>النسبة</th>
                            <th>المبيعات</th>
                            <th>المشتريات</th>
                            <th>الربح</th>
                            <th>القيمة المضافة</th>
                        </tr>
                    </thead>

                    <tbody v-if="items != null">
                        <tr>
                            <td>معفاة</td>
                            <td>{{ items['exempt']['sales'] }}</td>
                            <td>{{ items['exempt']['purchases'] }}</td>
                            <td>{{ exempt['profit'] }}</td>
                            <td>{{ exempt['added_value'] }}</td>
                        </tr>
                        
                        <tr>
                            <td>صفري</td>
                            <td>{{ items['zeros']['sales'] }}</td>
                            <td>{{ items['zeros']['purchases'] }}</td>
                            <td>{{ zeros['profit'] }}</td>
                            <td>{{ zeros['added_value'] }}</td>
                        </tr>

                        <tr v-for="tax in taxes" :key="tax">
                            <td>
                                {{ tax }}
                            </td>
                            <td>{{ parseFloat(items[tax]['sales']).toFixed(3) }}</td>
                            <td>{{ parseFloat(items[tax]['purchases']).toFixed(3) }}</td>
                            <td>{{ taxesResults[tax]['profit'] }}</td>
                            <td dir="ltr">
                                {{ taxesResults[tax]['added_value'] }}
                                {{ (!isNaN(taxesResults[tax]['added_value']))? '%' : ''  }}
                            </td>
                        </tr>
                    </tbody>

                    <tfoot>
                        <tr>
                            <td><b>المجموع</b></td>
                            <td><b>{{ sales_sum }}</b></td>
                            <td><b>{{ purchases_sum }}</b></td>
                            <td><b>{{ profit_sum }}</b></td>
                            <td dir="ltr"><b>{{ added_values_sum }} %</b></td>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import config from '../../../public/js/config';
import GlobalFunctions from "../../../public/js/globalFunctions";
import $ from 'jquery';

export default {
    name: "EndorsementsAnalysisReport",
    props: ["company", "period"],
    data: function(){
        return {
            requestStatus: null,
            items: null,
            taxes: [],
        }
    },
    computed: {
        exempt: function(){
            if( this.items == null ) return null;
            let profit = this.items['exempt']['sales'] - this.items['exempt']['purchases'];
            return {
                'profit': parseFloat(profit).toFixed(3),
                'added_value': '-' ,
            };
        },
        zeros: function(){
            if( this.items == null ) return null;
            let profit = this.items['zeros']['sales'] - this.items['zeros']['purchases'];
            return {
                'profit': parseFloat(profit).toFixed(3),
                'added_value': '-' ,
            };
        },
        taxesResults: function(){
            if( this.items == null ) return null;
            let obj = {};
            this.taxes.map((tax) => {
                let profit = parseFloat(this.items[tax]['sales'] - this.items[tax]['purchases']);
                let added_value = profit / (this.items[tax]['purchases']);
                added_value = (isNaN(added_value))? parseFloat(0).toFixed(3) : parseFloat(added_value * 100).toFixed(3);

                obj[tax] = {
                    'profit': parseFloat(profit).toFixed(3),
                    'added_value': added_value,
                };
            });
            return obj;
        },

        sales_sum() {
            let sum = 0;
            if(this.items != null) {
                sum+= this.items['zeros']['sales'];
                sum+= this.items['exempt']['sales'];
    
                this.taxes.forEach(tax => {
                    sum+= this.items[tax]['sales'];
                });
            } 
            return parseFloat(sum).toFixed(3);
        },
        
        purchases_sum() {
            let sum = 0;
            if(this.items != null) {
                sum+= this.items['zeros']['purchases'];
                sum+= this.items['exempt']['purchases'];
    
                this.taxes.forEach(tax => {
                    sum+= this.items[tax]['purchases'];
                });
            }
            return sum;
        },

        profit_sum: function() {
            let taxesResults = this.taxesResults;
            let sum = 0;
            if(this.exempt != null && this.zeros != null && this.items != null && this.exempt.hasOwnProperty('profit') && this.zeros.hasOwnProperty('profit')) {
                sum+= parseFloat(this.exempt.profit);
                sum+= parseFloat(this.zeros.profit);
           
                if(taxesResults != null && taxesResults != {}) {
                    this.taxes.forEach(function(tax) {
                        sum+= parseFloat(taxesResults[tax]['profit']);
                    });
                }
            }
            
            return parseFloat(sum).toFixed(3);
        },

        added_values_sum: function() {
            let taxesResults = this.taxesResults;
            let sum = 0;
            if(this.items != null && taxesResults != null && taxesResults != {}) {
                this.taxes.forEach(function(tax) {
                    if( taxesResults[tax]['added_value'] && !isNaN(taxesResults[tax]['added_value']) ){
                        sum+= parseFloat(taxesResults[tax]['added_value']);
                    }
                });
            }
            
            return parseFloat(sum).toFixed(3);
        },
    },
    methods: {
       async print() {
            let target = $('#printEndorsementsAnalysisReport').clone();
            $(target).attr('id', "clonePrinting");
            let title = "تقرير تحليل الإقرارات";
            GlobalFunctions.print(target, this.company, this.period, title);
        },
    },
    created: function(){
        let token = this.$store.state.auth.user['token'];
        this.requestStatus = "يرجى الانتظار ...";
        this.axios.get(config.api_url() + 'periods/'+this.period.id+'/reports/endorsement-analysis', {headers: {"Authorization": "Bearer " + token}} )
            .then( response => {
                this.items = response.data.result;
                this.taxes = response.data.taxes;
                this.requestStatus = null;
            })
            .catch(error => {
                console.log(error);
            });
    }
}
</script>