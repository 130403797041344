<template>
    <div class="dsip">
        <!-- Upload File Form  -->
        <div class="d-flex">
            <div>
                <small :class="section + '-uploading-message'"></small>
                <label :for="'uploadFile'+section" class="inputFilesLabel ">رفع ملف</label>
                <input type="file" :id="'uploadFile'+section" class="inputFiles" multiple @change="previewSelectedFile">
            </div>

            <button class="btn btn-sm btn-success upload-btn" v-show="urls != null" @click="uploadFiles" type="button">رفع</button>
        </div>

        <!-- show Link Of File And Delete Button Section  -->
        <ul v-if="period.files != null">
            <li v-for="section_file in section_files" :key="section_file.id" class="file-item">
                <button class="btn btn-link" @click="downloadFile(section_file.id)" type="button">
                    {{ section_file.file_title }}
                </button>
                <button class="btn btn-sm btn-danger" @click="deleteFile(section_file.id)" type="button">
                    <b-icon-trash></b-icon-trash>
                </button>
            </li>
        </ul>
    </div>
</template>

<style scoped>
    .inputFiles{
        display: none;
    }

    .inputFilesLabel{
        border: 1px dashed #ababab;
        padding: 3px 1px;
        margin: 1px 5px;
    }

    .inputFilesLabel:hover{
        cursor: pointer;
        border: 1px dashed #3e3e3e;
        box-shadow: 0px 0px 6px #f3f3f3;
    }

    .file-item{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

</style>

<script>
import config from "../../../public/js/config";
import alertFunctions from "../../../public/js/alertFunctions";
import $ from 'jquery';

export default {
    name: "FilesForm",
    props: ["period", "section"],
    data(){
        return {
            files: null,
            urls: null,
        }
    },
    computed: {
        "section_files": function(){
            let data = this.period.files;
            let pathes = data.filter((item) => item.section == this.section);
            return pathes;
        }
    },
    methods: {
        "previewSelectedFile": function(e){
            let tmpFiles = [], tmpUrls = [];
            for (let i = 0; i < e.target.files.length; i++) {
                const file = e.target.files[i];
                tmpFiles.push(file);
                tmpUrls.push( URL.createObjectURL(file) );
            }

            this.files = tmpFiles;
            this.urls = tmpUrls;

            $('.'+this.section+'-uploading-message').text('تم اختيار الملف بنجاح ' + ` (${tmpFiles.length}) `).css('color', 'black');
        },
        "uploadFiles": function(){
            if( this.files != null ){
                $('.'+this.section+'-uploading-message').text('يرجى الانتظار ...').css('color', 'blue');

                let token = this.$store.state.auth.user['token'];
                let formData = new FormData();
                formData.append('section', this.section);
                this.files.forEach(file => {
                    formData.append('files[]', file);
                });
                
                this.axios.post(config.api_url() +'periods-files/'+ this.period.id, formData, {headers: {"Authorization": "Bearer " + token}})
                    .then((response) => {
                        if(response.status == 200){
                            if(response.data.status){
                                alertFunctions.SuccessAlert('تم التخزين بنجاح .')
                                $('.'+this.section+'-uploading-message').text('تم الرفع .').css('color', 'green');

                                this.period.files = response.data.files;
                                this.files = null;
                                this.urls= null;
                            }
                            else{
                                alertFunctions.ErrorAlert( response.data.message );
                                this.files = null;
                                this.urls= null;
                                $('.'+this.section+'-uploading-message').text('نوع ملف غير مسموح به !').css('color', 'red');
                            }
                        }
                    })
                    .catch((error) => {
                        if (error.response.status === 401) {
                            alert('تم انتهاء جلسة الدخول , أعد تسجبل الدخول .');
                            window.location.href = "/logout";
                        }
                        console.log(error);
                    });
            }
            else{
                alertFunctions.ErrorAlert( "يجب أن ترفع ملف !" );
            }
        },
        "downloadFile": function(section_file_id){
            window.open( config.url() + `download-period-files/${section_file_id}`, null);
        },
        "deleteFile": function(section_file_id){
            this.$swal({
                title: 'هل أنت متأكد ؟',
                text: "سيتم حذف الملف .",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'نعم',
                cancelButtonText: "الغاء"
            }).then((result) => {
                if( result.isConfirmed == true ){
                    let token = this.$store.state.auth.user.token;
                    let formData = new FormData();
                    formData.append('section', this.section);
                    formData.append('section_file_id', section_file_id);

                    this.axios.post(config.api_url() +'periods-files/'+ this.period.id + "/delete", formData, {headers: {"Authorization": "Bearer " + token}})
                        .then((response) => {
                            if(response.status == 200){
                                if(response.data.status){
                                    alertFunctions.SuccessAlert('تم حذف الملف بنجاح .')
                                    $('.'+this.section+'-uploading-message').text('تم الحذف .').css('color', 'red');
                                    this.period.files = response.data.files;
                                }
                            }
                        })
                        .catch((error) => {
                            if (error.response.status === 401) {
                                alert('تم انتهاء جلسة الدخول , أعد تسجبل الدخول .');
                                window.location.href = "/logout";
                            }
                            console.log(error);
                        });
                }
            });
        }
    },
}
</script>