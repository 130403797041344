<template>
    <div class="m-auto">
        <template v-if="users != null">
            <button type="button" class="btn btn-primary rounded-pill" @click.prevent="print">
                طباعة <b-icon-printer-fill></b-icon-printer-fill>
            </button>

            <div dir="rtl" id="printUsers" class="my-2">
                <table class="table table-bordered table-striped" id="usersTable">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>الإسم</th>
                            <th>البريد الإلكتروني</th>
                            <th>نوع الحساب</th>
                            <th class="text-success">تاريخ التسجيل</th>
                            <th class="text-danger">تاريخ الانتهاء</th>
                            <th class="dsip">إجراءات</th>
                        </tr>
                    </thead>

                    <tbody>
                        <template v-if="users != undefined && users.length > 0">
                            <tr v-for="(user, index) in users" :key="user.id" :class="(user.is_expired)? 'text-danger': ''">
                                <td>{{ index + 1}}</td>
                                <td>{{ user.name }}</td>
                                <td>{{ user.email }}</td>
                                <td>{{ (user.main_user_id == null)? 
                                    "حساب رئيسي " + " ("+ user.sub_users.length +") "
                                    : "حساب فرعي" }}
                                    
                                    <div v-if="user.sub_users.length > 0">
                                        <ul class="p-0 list-unstyled">
                                            <li v-for="subUser in user.sub_users" :key="subUser.id">
                                                <router-link :to="{ name: 'editUser', params: {id: subUser.id } }" >{{ subUser.name }}</router-link>
                                            </li>
                                        </ul>
                                    </div>
                                </td>

                                <td>{{ user.created_at }}</td>
                                <td>{{ user.expiry_date }}</td>
                                <td class="dsip">
                                    <router-link :to="{ name: 'editUser', params: {id: user.id } }" class="btn btn-sm btn-primary mx-1">تعديل</router-link>
                                    <button @click.prevent="confirmDeleteUser(user.id)" class="btn btn-danger btn-sm">حذف</button>

                                    <template v-if="user.is_suspended == true">
                                        <button @click.prevent="changeSuspended(user.id, false, index)" class="btn btn-success mx-1 btn-sm">تفعيل</button>
                                    </template>
                                    <template v-else>
                                        <button @click.prevent="changeSuspended(user.id, true, index)" class="btn btn-outline-danger mx-1 btn-sm">إيقاف</button>
                                    </template>
                                </td>
                            </tr>
                        </template>
                        <template v-else>
                            <tr>
                                <td colspan="7">
                                    لا يوجد مستخدمين بعد ...
                                </td>
                            </tr>
                        </template>
                    </tbody>
                </table>
             </div>
        </template>
        <template v-else>
            <img src="/images/loader.gif" alt="loader image" >
        </template>
    </div>
</template>

<script>
import axios from "axios";
import config from "../../../../public/js/config";
import swal from 'sweetalert2';
import alertFunctions from "../../../../public/js/alertFunctions.js";
import printJS from 'print-js';
import $ from 'jquery';

export default {
    name: "usersTableTemplate",
    data(){
        return {
            users: null,
        }
    },
    methods: {
        "confirmDeleteUser": function(userId){
            new swal({
                title: 'هل أنت متأكد ؟',
                text: "لا يمكنك التراجع عن الحذف إذا تم التأكيد !",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'حذف',
                cancelButtonText: 'لا'
            })
            .then((result) => {
                if( result.isConfirmed == true ){
                    this.deleteUser(userId);
                }
            });
        },
        "deleteUser": function(userId){
            let admin = this.$store.state.auth.admin;
            axios.delete(config.api_url() + 'dashboard/users/'+userId, {headers: {"admin-token": admin.token} })
                .then((response) => {
                    if(response.status == 200){
                        // console.log(response.data);
                        if(response.data.status){
                            let itemIndex = null;
                            for (let index = 0; index < this.users.length; index++) {
                                const element = this.users[index];
                                if(element.id == userId){
                                    itemIndex = index;
                                    break;
                                }
                            }
                            this.users.splice(itemIndex, 1);

                            alertFunctions.SuccessAlert("تم حذف المستخدم وبياناته بنجاح");                        
                        }
                        else{
                            alertFunctions.ErrorAlert("حدث خطأ ما اثناء عملية الحذف !");
                        }
                    }
                })
                .catch(() => {
                    console.log('error');
                });
        },
        "changeSuspended": function(userId, newStatus, index){
            let admin = this.$store.state.auth.admin;
            axios.post(config.api_url() + 'dashboard/change-user-suspended/'+userId, {'suspended': newStatus} ,{headers: {"admin-token": admin.token} })
                .then((response) => {
                    if(response.status == 200){
                        // console.log(response.data);
                        if(response.data.status == true){
                            let user = this.users[index];
                            user.is_suspended = newStatus;
                            this.users[index] = user;
                        }
                    }
                })
                .catch(() => {
                    console.log('error');
                });
        },
        async print() {
            let target = $('#printUsers').clone();
            $(target).attr('id', "clonePrinting");
            $(target).find('.dsip').remove();

            $('body').append(target);

            printJS({
                printable: "clonePrinting", 
                type: 'html', 
                properties: ['prop1'], 
                header: "<h2 class='text-center my-2' >مسك للأعمال المحاسبية</h2>" ,
                css: "https://cdn.jsdelivr.net/npm/bootstrap@5.1.1/dist/css/bootstrap.min.css",
            });

            $('#clonePrinting').remove();
        },
    },
    created: function(){
        let admin = this.$store.state.auth.admin;
        axios.get(config.api_url() + 'dashboard/users', {headers: {"admin-token": admin.token} })
            .then((response) => {
                if(response.status == 200){
                    this.users = response.data.users;
                    this.$store.commit('DashboardData/setUsers', response.data.users );
                    // console.log(this.users);
                }
            })
            .catch(() => {
                console.log('error');
            });
    },
}
</script>