<template>
    <div id="printIncomeListReport" dir="rtl">
    
        <div class="row justify-content-center">
            <div class="col-10 d-flex justify-content-between">
                <h3 class="dsip">تقرير قائمة الدخل</h3>

                <button type="button" class="btn btn-primary btn-sm mb-1 dsip" @click.prevent="print">
                    طباعة <b-icon-printer-fill></b-icon-printer-fill>
                </button>
            </div>

            <div class="col-10">
                {{ requestStatus }}
                <table class="table table-bordered table-striped table-hover">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>القيمة</th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr>
                            <td><b>المبيعات</b></td>
                            <td>{{ sales }}</td>
                        </tr>

                        <tr>
                            <td><b>بضاعة أول المدة</b></td>
                            <td>
                                <input type="number" v-model="periodBeginingItems" class="form-control w-50 text-center m-auto">
                            </td>
                        </tr>

                        <tr>
                            
                            <td><b>المشتريات</b></td>
                            <td>{{ purchases }}</td>
                        </tr>

                        <tr>
                            <td><b>بضاعة آخرالمدة</b></td>
                            <td>
                                <input type="number" v-model="periodEndItems" class="form-control w-75 text-center my-1 mx-auto">

                                <div class="input-group mb-3 w-75 mx-auto">
                                    <input type="number" v-model="periodEndItemsRate" class="form-control text-center m-auto" style="font-size: 14px;" placeholder="النسبة لاستخراج القيمة">
                                    <div class="input-group-append" >
                                        <span class="input-group-text">%</span>
                                    </div>
                                </div>

                            </td>
                        </tr>

                        <tr>
                            <td><b>الكلفة</b></td>
                            <td>{{ cost }}</td>
                        </tr>
            
                        <tr>
                            <td><b>مجمل الربح</b></td>
                            <td>{{ overallProfit }}</td>
                        </tr>

                        <tr>
                            <td><b>نسبة القيمة المضافة</b></td>
                            <td>{{ addedValueRate }}</td>
                        </tr>

                        <tr>
                            <td><b>المصاريف</b></td>
                            <td>
                                <input type="number" v-model="outlays" class="form-control w-50 text-center m-auto">
                            </td>
                        </tr>

                        <tr>
                            <td><b>صافي الربح</b></td>
                            <td>{{ netProfit }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import config from '../../../public/js/config';
import GlobalFunctions from "../../../public/js/globalFunctions";
import $ from 'jquery';

export default {
    name: "IncomeListReport",
    props: ["company", "period"],
    data: function(){
        return {
            requestStatus: null,
            sales: 0,
            purchases: 0,
            periodBeginingItems:0,
            periodEndItems: 0,
            outlays: 0,
            periodEndItemsRate: null,
        }
    },
    computed: {
        cost: function(){
            let cost = 0;
            cost+= (this.periodBeginingItems)? parseFloat(this.periodBeginingItems) : 0;
            cost+= parseFloat(this.purchases); 
            cost-= (this.periodEndItems)? parseFloat(this.periodEndItems) : 0;
            return parseFloat(cost).toFixed(3);
        },
        overallProfit: function(){
            return parseFloat(this.sales - this.cost).toFixed(3);
        },
        netProfit: function(){
            return parseFloat(this.overallProfit - (this.outlays)?? 0).toFixed(3);
        },
        addedValueRate: function(){
            if( isNaN(this.overallProfit / this.cost) ) return "";
            return parseFloat(this.overallProfit / this.cost * 100).toFixed(3) + ' % ';
        },
    },
    watch: {
        periodEndItemsRate: function() {
            let rate = this.periodEndItemsRate / 100;
            let cost = this.sales / (1 + rate);
            let periodEndItems = this.periodBeginingItems +  this.purchases - cost;
            this.periodEndItems = parseFloat(periodEndItems).toFixed(3);
        }
    },
    methods: {
       async print() {
            let target = $('#printIncomeListReport').clone();
            $(target).attr('id', "clonePrinting");
            let title = "تقرير قائمة الدخل";
            GlobalFunctions.print(target, this.company, this.period, title);
        },
    },
    created: function(){
        let token = this.$store.state.auth.user['token'];
        this.requestStatus = "يرجى الانتظار ...";
        this.axios.get(config.api_url() + 'periods/'+this.period.id+'/reports/income-list', {headers: {"Authorization": "Bearer " + token}} )
            .then( response => {
                if(response.status == 200){
                    this.sales = parseFloat(response.data.original.sales).toFixed(3);
                    this.purchases = parseFloat(response.data.original.purchases).toFixed(3);
                    this.requestStatus = null;
                }
            })
            .catch(error => {
                console.log(error);
            });
    }
}
</script>