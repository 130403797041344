<template>
    <div>
        <form class="w-75 m-auto add-form">
            <div class="d-flex justify-content-around">
                <div class="d-flex flex-column align-items-start w-25">
                    <label for="addCompanyName">إسم الشركة</label>
                    <input type="text" class="form-control" v-model="companyName" id="addCompanyName" placeholder="إسم الشركة">
                    <div v-if="v$.companyName.$error" class="text-danger">
                        {{ v$.companyName.$errors[0].$message }}
                    </div>
                </div>
                
                <div class="d-flex flex-column align-items-start w-25">
                    <label for="addCompanyNum">الرقم الضريبي</label>
                    <input type="text" class="form-control" v-model="taxNumber" id="addCompanyNum" placeholder="الرقم الضريبي">
                    <div v-if="v$.taxNumber.$error" class="text-danger">
                        {{ v$.taxNumber.$errors[0].$message }}
                    </div>
                </div>

                <div class="d-flex flex-column align-items-start w-25">
                    <label for="addCompanySign">المفوّض بالتوقيع</label>
                    <input type="text" class="form-control" v-model="authSign" id="addCompanySign" placeholder="المفوّض بالتوقيع">

                    <div v-if="v$.authSign.$error" class="text-danger">
                        {{ v$.authSign.$errors[0].$message }}
                    </div>
                </div>
            </div>
           
            <div class="d-flex justify-content-around my-2">
                <div class="d-flex flex-column align-items-start w-25">
                    <label for="addCompanyPhone">رقم الهاتف</label>
                    <input type="text" class="form-control" v-model="phone" id="addCompanyPhone" placeholder="رقم الهاتف">
                    <div v-if="v$.phone.$error" class="text-danger">
                        {{ v$.phone.$errors[0].$message }}
                    </div>
                </div>

                <div class="d-flex flex-column align-items-start w-25">
                    <label for="addPeriodType">نوع الفترة</label>

                    <select v-model="periodType" class="form-select" id="addPeriodType">
                        <option value="" selected>---</option>
                        <option value="even">زوجي 1+2</option>
                        <option value="odd">فردي 12+1</option>
                    </select>
                </div>

                <div class="d-flex flex-column align-items-start w-25">
                    <label for="addCompanyDir">مديرية الشركة</label>
                    <select v-model="directorate" id="addCompanyDir" class="form-select w-100" required>
                        <option value="" selected>---</option>
                        <option>كبار المكلفين</option>
                        <option>شمال عمان</option>
                        <option>شرق عمان</option>
                        <option>غرب عمان</option>
                        <option>غرب عمان</option>
                        <option>تجاري أولى</option>
                        <option>تجاري ثاني</option>
                        <option>أصحاب المهن</option>
                        <option>أخرى</option>
                    </select>
                </div>
            </div>
           
            <div class="d-flex justify-content-around my-2">
                <div class="d-flex flex-column align-items-start w-25">
                    <label for="addCompanyDefaultTax">ضريبة افتراضية</label>
                    <select v-model="default_tax" id="addCompanyDefaultTax" class="form-select w-100">
                        <option value="" selected>---</option>
                        <option v-for="setting in settings" :key="setting.id" :value="setting.id">{{ setting.tax }}</option>
                    </select>
                </div>

                <div class="d-flex flex-column align-items-start w-25">
                    <label for="dealingDate">تاريخ بدء التعامل</label>
                    <input type="text" id="dealingDate" v-model="dealingDate" class="form-control p-xxl-1 p-0" placeholder="اليوم - الشهر - السنة">

                    <div v-if="v$.dealingDate.$error" class="text-danger">
                        {{ v$.dealingDate.$errors[0].$message }}
                    </div>
                </div>

                <div class="d-flex flex-column align-items-start w-25">
                    <label for="endorsmentDate">تاريخ بدء الإقرارات</label>
                    <input type="text" id="endorsmentDate" v-model="endorsmentDate" class="form-control p-xxl-1 p-0" placeholder="اليوم - الشهر - السنة">

                    <div v-if="v$.endorsmentDate.$error" class="text-danger">
                        {{ v$.endorsmentDate.$errors[0].$message }}
                    </div>
                </div>
            </div>

            <button type="button" @click="addNewCompany" class="btn btn-success">
                إضافة <b-icon-plus-lg></b-icon-plus-lg>
            </button>
        </form>
    </div>
</template>

<style scoped>
    .add-form{
        background: #fbfbfb;
        padding: 14px;
        border-radius: 13px;
        box-shadow: 0px 0px 19px #f5f5f5;
    }
</style>

<script>
import config from "../../../public/js/config";
import alertFunctions from "../../../public/js/alertFunctions.js";
import useVuelidate from '@vuelidate/core';
import { required, integer, minLength } from '@vuelidate/validators';

export default {
    name: "AddCompany",
    data(){
        return {
            v$: useVuelidate(),
            companyName: "",
            taxNumber: "",
            authSign: "",
            phone: "",
            directorate: "",
            dealingDate: "",
            endorsmentDate: "",
            default_tax: "",
            settings: []
        }
    },
    validations () {
        return {
            companyName: {
                required,
                minLength: minLength(3)
            },
            phone: {
                required,
                integer,
                minLength: minLength(9)
            },
            authSign: {
                required,
            },
            taxNumber: {
                integer,
                required,
            },
            dealingDate: {
                required,
            },
            endorsmentDate: {
                required,
            }
        }
    },
    watch: {
        "dealingDate": function(){
            this.dealingDate+= this.dealingDate.length == 2 ? "-" : "";
            this.dealingDate+= this.dealingDate.length == 5 ? "-" : "";
            this.dealingDate = this.dealingDate.replace('--', '-');
            this.dealingDate = (this.dealingDate.length > 10)? this.dealingDate.slice(0,10) : this.dealingDate;
        },
        "endorsmentDate": function(){
            this.endorsmentDate+= this.endorsmentDate.length == 2 ? "-" : "";
            this.endorsmentDate+= this.endorsmentDate.length == 5 ? "-" : "";
            this.endorsmentDate = this.endorsmentDate.replace('--', '-');
            this.endorsmentDate = (this.endorsmentDate.length > 10)? this.endorsmentDate.slice(0,10) : this.endorsmentDate;
        },
    },
    methods:{
        "addNewCompany": function(){
            this.v$.$validate();
            if (!this.v$.$invalid) {
                var user = this.$store.state.auth.user;
                let formData = new FormData();
                formData.append('name', this.companyName);
                formData.append('taxNumber', this.taxNumber);
                formData.append('auth_signatory', this.authSign);
                formData.append('phone', this.phone);
                formData.append('period_type', this.periodType);
                formData.append('directorate', this.directorate);
                formData.append('dealing_date', this.dealingDate);
                formData.append('endorsment_date', this.endorsmentDate);
                formData.append('default_tax', this.default_tax);
                formData.append('user', user['id']);

                this.axios.post(config.api_url() + 'companies?user='+user['id'], formData, 
                    {headers: {"Authorization": "Bearer " + user['token'], "Accept": "application/json", "Content-Type": "application/json" } })
                .then((response) => {
                    if( response.status == 200 ){
                        if(response.data.status == true){
                            alertFunctions.SuccessAlert('تم إضافة الشركة بنجاح');
    
                            setTimeout(() => {
                                window.location.reload();
                            }, 500);
                        }
                        else{
                            let errors = Object.values(response.data.response);
                            let result = "";
                            errors.forEach(error => {
                                result+= error + "\n";
                            });
                            alertFunctions.ErrorAlert(result);
                        }
                    }
                })
                .catch(() => {
                    console.log('error');
                });
            }
        }
    },
    created: function(){
        let user = this.$store.state.auth.user;
        this.axios.get(config.api_url() + 'settings/'+user['id'], {headers: {"Authorization": "Bearer "+ user['token']} })
        .then((response) => {
            if( response.status == 200 ){
                this.settings = response.data.settings;
            }
        })
        .catch(() => {
            console.log('error');
        });
    }
}
</script>
