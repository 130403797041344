<script>
import axios from "axios";
import store from "@/store/index.js";
import config from "../../../public/js/config.js";

export default {
    name: "logout",
    store,
    created: function(){
        let user = this.$store.state.auth.user;
        let formData = new FormData();
        formData.append('id', user['id']);

        axios.post(
            config.api_url() + 'logout', 
            formData, 
            {headers: {'Authorization': "Bearer "+ user['token']}})
            .then((response) => {
                if(response.status == 200){
                    if(response.data.status == "success"){
                        window.localStorage.removeItem('user');
                        this.$store.commit('auth/setUser', null);
                        window.location.href = "/login";
                    }
                }
            })
            .catch(() => {
                console.log('some error occuerd');
            });
    }
}
</script>