export default {
    debug_mode: false,
    url: function(){
        if(this.debug_mode){
            return "http://127.0.0.1:8000/";
        }
        return "https://taxes-api.miskfinance.com/";
    },
    api_url: function(){
        if(this.debug_mode){
            return "http://127.0.0.1:8000/api/";
        }
        return "https://taxes-api.miskfinance.com/api/";
    }
}
