<template>
    <div>
        <div class="offcanvas offcanvas-top" style="height: fit-content; transition: 0.3s;" :class="showYears ? 'show' : ''" tabindex="-1" :style="{ visibility: showYears ? 'visible' : 'hidden' }">
            <div class="offcanvas-header">
                <h5 class="offcanvas-title">السنوات - {{ yearsInfo.companyName }}</h5>
                <button type="button" class="btn-close text-reset" @click.prevent="hideCanvasMenu()"></button>
            </div>
            <div class="offcanvas-body">
                <ul class="list-group">
                    
                    <li class="list-group-item" v-for="year in Object.keys(yearsInfo.years)" :key="year">
                        <div class="accordion">
                            <div class="accordion-item">
                                <a class="accordion-button collapsed" data-bs-toggle="collapse" :href="'#collapseYear'+year" role="button" aria-expanded="false" :aria-controls="'collapseYear'+year">
                                    {{ year }}
                                </a>
                            
                                <div class="collapse" :id="'collapseYear'+year">
                                    <div class="card card-body">
                                        <ul v-for="period in yearsInfo.years[year]" :key="period">
                                            <li class="period-item">
                                                <router-link :to="'/period/'+period.id" :title="(period.is_carryover)? 'مرحّل' : 'غير مرحّل'">
                                                    {{ period.period }} 
                                                    
                                                    <b-icon-check-lg v-if="period.is_carryover" class="text-danger me-2"></b-icon-check-lg>
                                                </router-link>
                                            </li>
                                        </ul>
                                    </div>
                                </div> 
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>

        <hr>
 
        <div id="printCompanies" class="mt-2" dir="rtl">
            <div class="d-flex justify-content-center">
                <h5> مجموع الشركات  {{ companies.length }}</h5>

                <button type="button" class="btn btn-outline-primary rounded-pill mx-2 dsip" @click.prevent="print">
                    طباعة <b-icon-printer-fill></b-icon-printer-fill>
                </button>
            </div>
           
            <table class="table table-bordered table-striped table-hover companies-table text-right" id="companiesTable" dir="rtl">
                <thead>
                    <tr class="companiesColumns">
                        <th>#</th>
                        <th @click.prevent="sortedData('name', !descSort)">إسم الشركة</th>
                        <th @click.prevent="sortedData('taxNumber', !descSort)">الرقم الضريبي</th>
                        <th @click.prevent="sortedData('auth_signatory', !descSort)">المفوض بالتوقيع</th>
                        <th @click.prevent="sortedData('phone', !descSort)">رقم الهاتف</th>
                        <th @click.prevent="sortedData('directorate', !descSort)">المديرية</th>
                        <th>الفترة</th>
                        <th>السنوات</th>
                        <th class="dsip">اجراءات</th>   <!-- dsip => dont show in print -->
                
                    </tr>
                </thead>

                <tbody>
                    <template v-if="companiesList.length > 0">
                        <template v-for="(company, index) in companiesList" :key="index">
                            <tr>
                                <td>{{ index+1 }}</td>
                                <td>{{ company.name }}</td>
                                <td>{{ company.taxNumber }}</td>
                                <td>{{ company.auth_signatory }}</td>
                                <td>{{ company.phone }}</td>
                                <td>{{ company.directorate }}</td>
                                <td>{{ (company.period_type == "even")? "زوجي 1+2" : "فردي 12+1" }}</td>
                                <td>
                                    <button class="btn btn-sm btn-dark yearsBtnCompany" @click.self="showOffcanvasMenu(company)">
                                        {{ Object.keys(company.years).length }}
                                    </button>
                                </td>
                                <td class="dsip">
                                    <template v-if="type == 'active'">
                                        <router-link :to="{ name: 'editCompany', params: {id: company.id } }" class="btn btn-sm btn-primary mx-1">تعديل</router-link>
                                    </template>

                                    <template v-if="(Object.keys(company.years).length == 0)">
                                        <button type="button" @click="completeDeleteCompany(company.id)" class="btn btn-sm btn-danger mx-1">
                                            حذف <b-icon-trash></b-icon-trash>
                                        </button>
                                    </template>
                                    <template v-else>
                                        <template v-if="company.deleted_at == null">
                                            <button type="button" @click="removeDialog(company.id)" class="btn btn-sm btn-warning mx-1">
                                                أرشفة <b-icon-trash></b-icon-trash>
                                            </button>
                                        </template>
                                        <template v-else>
                                            <button type="button" @click="completeDeleteCompany(company.id)" class="btn btn-sm btn-danger mx-1">
                                                حذف
                                            </button>
                                            <button type="button" @click="restoreCompany(company.id)" class="btn btn-sm btn-warning mx-1">
                                                استرجاع
                                            </button>
                                        </template>
                                    </template>
                                </td>
                            </tr>
                        </template>
                    </template>
                    <template v-else>
                        <tr>
                            <td colspan="9">لا يوجد شركات بعد ...</td>
                        </tr>
                    </template>
                </tbody>
            </table>
        </div>

    </div>
</template>

<style scoped>
    .companies-table{
        width: 90%;
        margin: 30px auto;
    }
    .offcanvas-title{
        font-size: 25px;
    }
    .period-item{
        font-size: 18px;
        background: #646464;
        padding: 4px 24px;
        border-radius: 8px;
        text-align: right;
        width: 120px;
        margin: 1px 0px;
        transition: 0.4s;
    }
    .period-item a{
        font-size: 18px;
        width: 100%;
        display: inline-block;
        margin: 1px 0px;
        color: white;
        text-decoration: none;
    }
    
    .period-item:hover{
        background: black;
        width: 130px;
    }
    .period-item a{
        color: white;
        text-decoration: none;
    }

    .companiesColumns th:hover{
        cursor: pointer;
        color: green;
    }

    thead {
        background-image: linear-gradient(120deg, #a1c4fd 0%, #c2e9fb 100%);
    }
    tfoot {
        background-image: linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%);
    }
</style>
  

<script>
import printJS from 'print-js';
import $ from 'jquery';
import config from "../../../public/js/config";

export default {
    name: "CompaniesTable",
    props: ["companies"],
    data(){
        return{
            companiesList: this.companies,
            showYears: false,
            type: this.$route.params.type,
            yearsInfo: {years:{}, companyName:""},
            descSort: false,
        }
    },
    methods: {
        "removeDialog": function(id){
            this.$swal({
                title: 'هل أنت متأكد ؟',
                text: "سيتم نقل بيانات هذه الشركة الى خانة الأرشيف, يمكنك التراجع عن ذلك فيما بعد .",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'نعم أرشفة',
                cancelButtonText: "الغاء"
            }).then((result) => {
                if( result.isConfirmed == true ){
                    let token = this.$store.state.auth.user.token;
                    this.axios.post(config.api_url() + 'companies/' + id + '/archive', {}, {headers: {"Authorization": "Bearer " + token}})
                        .then((response) => {
                            if( response.status == 200 ){
                                if(response.data.status == true){
                                    window.location.reload();
                                }
                                else{
                                    alert("error in log");
                                }
                            }
                        })
                    .catch((error) => {
                        if (error.response.status === 401) {
                            alert('تم انتهاء جلسة الدخول , أعد تسجبل الدخول .');
                            window.location.href = "/logout";
                        }
                        console.log(error);
                    });
                }
            });
        },
        'showOffcanvasMenu': function(company){
            this.yearsInfo = {
                years: company.years,
                companyName: company.name,
            };
        
            this.showYears ? this.showYears = false : this.showYears = true;
        },
        'hideCanvasMenu': function(){
            this.showYears = false;
        },
        "restoreCompany": function(companyId){
            let token = this.$store.state.auth.user.token;
            this.axios.post(config.api_url() + 'restore-company/' + companyId, {} ,{headers: {"Authorization": "Bearer " + token}})
                .then((response) => {
                    if( response.status == 200 ){
                        if(response.data.status == true){
                            window.location.reload();
                        }
                        else{
                            alert("error in log");
                        }
                    }
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        alert('تم انتهاء جلسة الدخول , أعد تسجبل الدخول .');
                        window.location.href = "/logout";
                    }
                    console.log(error);
                });
        },
        "completeDeleteCompany": function(id){
            this.$swal({
                title: 'هل أنت متأكد ؟',
                text: "سيتم حذف بيانات هذه الشركة بالكامل !",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'نعم',
                cancelButtonText: "الغاء"
            }).then((result) => {
                if( result.isConfirmed == true ){
                    let token = this.$store.state.auth.user.token;
                    this.axios.delete(config.api_url() + 'companies/' + id, {headers: {"Authorization": "Bearer " + token}})
                        .then((response) => {
                            if( response.status == 200 ){
                                if(response.data.status == true){
                                    window.location.reload();
                                }
                                else{
                                    alert("error in log");
                                }
                            }
                        })
                    .catch((error) => {
                        if (error.response.status === 401) {
                            alert('تم انتهاء جلسة الدخول , أعد تسجبل الدخول .');
                            window.location.href = "/logout";
                        }
                        console.log(error);
                    });
                }
            });
        },
        async print() {
            let target = $('#printCompanies').clone();
            $(target).attr('id', "clonePrinting");
            $(target).find('.dsip').remove();
 
            $(target).find('.yearsBtnCompany').replaceWith(function(){
                return $("<span></span>", {html: $(this).text()});
            });

            $('body').append(target);

            printJS({
                printable: "clonePrinting", 
                type: 'html', 
                properties: ['prop1'], 
                header: "<h2 class='text-center my-2' >مسك للأعمال المحاسبية</h2>" ,
                documentTitle: this.$store.state.auth.user.name,
                css: "/css/bootstrap.min.css",
            });

            $('#clonePrinting').remove();
        },

        "sortedData": function(field, desc) {
            this.companiesList.sort(function(a, b) {
                let sorting = 0;
                switch(field){
                    case "name": 
                        sorting = (desc)?
                                    (a.name > b.name)? 1 : -1 :
                                    (a.name > b.name)? -1 : 1;   break;
                        
                    case "taxNumber": 
                        sorting = (desc)?
                                    (a.taxNumber > b.taxNumber)? 1 : -1 :
                                    (a.taxNumber > b.taxNumber)? -1 : 1;   break;

                    case "auth_signatory": 
                        sorting = (desc)?
                                (a.auth_signatory > b.auth_signatory)? 1 : -1 :
                                (a.auth_signatory > b.auth_signatory)? -1 : 1;   break;

                    case "phone": 
                        sorting = (desc)?
                            (a.phone > b.phone)? 1 : -1 :
                            (a.phone > b.phone)? -1 : 1;   break;
                    
                    case "directorate": 
                        sorting = (desc)?
                            (a.directorate > b.directorate)? 1 : -1 :
                            (a.directorate > b.directorate)? -1 : 1;   break;
                }
                return sorting;
            });

            this.descSort = desc;
        },
    },
}
</script>