<template>
    <div>
        <div>
            <div class="row my-2 mx-3 p-2">
                <div class="col">
                    <label id="year">السنة المالية</label>
                    <input type="number" id="year" class="form-control" v-model="year" placeholder="السنة" required>
                </div>

                <div class="col">
                    <label>نوع الفترة</label>
                    <div class="d-flex justify-content-center">
                        <div class="mx-2">
                            <label for="evenPeriodType">زوجي</label>
                            <input type="radio" id="evenPeriodType" value="even" v-model="periodType">
                        </div>
                        <div class="mx-2">
                            <label for="oddPeriodType">فردي</label>
                            <input type="radio" id="oddPeriodType" value="odd" v-model="periodType">
                        </div>
                    </div>
                </div>

                <div class="col">
                    <label id="period">الفترة المالية</label>
                    <select v-model="selectedPeriod" id="period" class="form-select">
                        <option value="">---</option>
                        <option v-for="period in periods" :key="period">{{ period }}</option>
                    </select>
                </div>

                <div class="col">
                    <label>حالة الترحيل</label>
                    <div class="d-flex justify-content-center">
                        <div class="mx-2">
                            <label for="postedStatus">مرحّل</label>
                            <input type="radio" id="postedStatus" value="posted" v-model="postStatus">
                        </div>
                        <div class="mx-2">
                            <label for="unpostedStatus">غير مرحّل</label>
                            <input type="radio" id="unpostedStatus" value="unposted" v-model="postStatus">
                        </div>
                    </div>
                </div>
            </div>

            <button class="btn btn-success my-3" :disabled="isRequesting" @click="search">بحث</button>
        </div>

        <hr>

        <div class="w-75 mx-auto">
            {{ (isRequesting)? 'جاري التحميل ...' : '' }}

            <div>
                <button type="button" class="btn btn-outline-primary rounded-pill m-2 dsip" @click.prevent="print">
                    طباعة <b-icon-printer-fill></b-icon-printer-fill>
                </button>

                <div id="printCompanies">
                    <template v-if="result != null">
                        <div class="d-flex justify-content-between">
                            <div class="text-end">
                                <p> <b>العام</b>  : {{ result.year }}</p>
                                <p> <b>نوع الفترة</b>  : {{ (result.periodType == 'odd')? 'فردي' : 'زوجي' }}</p>
                            </div>

                            <div class="text-end">
                                <p> <b>الفترة</b> : {{ result.selectedPeriod }}</p>
                                <p> <b>حالة الترحيل</b> : {{ (result.postStatus == 'posted')? 'مرحّل' : 'غير مرحّل'  }}</p>
                            </div>
                        </div>
                    </template>
     

                    <table class="table table-bordered table-striped" dir="rtl">
                        <thead>
                            <tr>
                                <td>#</td>
                                <td>إسم الشركة</td>
                            </tr>
                        </thead>

                        <tbody>
                            <template v-if="companies != null">
                                <template v-if="companies.length > 0">
                                   <tr v-for="(company, index) in companies" :key="'company' + company.id">
                                        <td>{{ index + 1 }}</td>
                                        <td>{{ company.name }}</td>
                                    </tr>
                                </template>
                                <template v-else>
                                    <tr>
                                        <td colspan="2">لا يوجد شركات بهذه المعطيات ...</td>
                                    </tr>
                                </template>
                            </template>
                            <template v-else>
                                <tr>
                                    <td colspan="2">لا يوجد شركات حتى الآن ...</td>
                                </tr>
                            </template>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import printJS from 'print-js';
import $ from 'jquery';
import config from "../../../public/js/config";

export default {
    name: "postReport",
    data(){
        return {
            companies: null,
            year: "",
            periodType: "",
            selectedPeriod: "",
            periods: null,
            postStatus: "",
            isRequesting: false,
            result: null,
        }
    },
    watch: {
        periodType: function(){
            if(this.periodType == "even") {
                this.periods = ["1-2", "3-4", "5-6", "7-8", "9-10", "11-12"];
            }
            else {
                this.periods = ["12-1", "2-3", "4-5", "6-7", "8-9", "10-11"];
            }
        }
    },
    methods: {
        search: function(){
            var user = this.$store.state.auth.user;

            let formData = new FormData();
            formData.append('year', this.year);
            formData.append('periodType', this.periodType);
            formData.append('selectedPeriod', this.selectedPeriod);
            formData.append('postStatus', this.postStatus);

            this.isRequesting = true;

            this.axios.post(config.api_url() + 'companies/post-status-report', formData, 
                {headers: {"Authorization": "Bearer " +user['token'], "Accept": "application/json", "Content-Type": "application/json"}})
                .then((response) => {
                    // console.log(response);

                    this.companies = response.data.companies;
                    this.isRequesting = false;
                    this.result = {
                        year: this.year,
                        periodType: this.periodType,
                        selectedPeriod: this.selectedPeriod,
                        postStatus: this.postStatus,
                    };
                })
                .catch((error) => {
                    console.log(error);
                    this.isRequesting = false;
                });
        },
        print: async function() {
            let target = $('#printCompanies').clone();
            $(target).attr('id', "clonePrinting");
            $(target).find('.dsip').remove();
 
            $('body').append(target);

            printJS({
                printable: "clonePrinting", 
                type: 'html', 
                header: "<h2 class='text-center my-2' >مسك للأعمال المحاسبية</h2>" ,
                documentTitle: this.$store.state.auth.user.name,
                css: "/css/bootstrap.min.css",
            });

            $('#clonePrinting').remove();
        },
        
    }
}
</script>