<template>
  <div class="home">
    <template v-if="user != null">
      <div class="my-2">
        <b-icon-person-circle class="user-icon"></b-icon-person-circle>
      </div>

      <div class="header-title">
        مرحباً 
        {{ user['name'] }}
      </div>

      <span class="email-header">{{ user['email'] }}</span>
      <hr class="header-sperator">
    </template>

    <div class="d-flex justify-content-center my-5" v-if="user != null">
      <router-link to="/companies/active" active-class="active-nav-link" class="nav-item nav-link">
        <div class="statistics-part">
          <p>الشركات الفعّالة</p>
          <!-- <b>{{ companiesCount }}</b> -->
        </div>
      </router-link>

      <router-link to="/companies/archived" active-class="active-nav-link" class="nav-item nav-link">
        <div class="statistics-part periods-part">
          <p>الشركات المؤرشفة</p>
          <!-- <b>{{ trashedCompanies }}</b> -->
        </div>
      </router-link>
    </div>
  </div>
</template>

<style scoped>
  .user-icon{
    font-size: 61px;
    color: #00000087;
  }
  .header-title {
    font-size: 27px;
    text-shadow: 0px 0px 21px #d7d7d7;
    margin: 8px;
  }
  .email-header{
    color: #afafaf;
  }

  .header-sperator{
    width: 75%;
    margin: 10px auto;
    color: #e5e5e5;
  }

  .statistics-part{
    background: linear-gradient(45deg, #23b2c3, #44333a);
    width: 19vw;
    border-radius: 10px;
    padding: 19px 5px;
    box-shadow: 3px 5px 6px 0px #d9d9d9;
    color: white;
    margin: 3px 25px;
    transition: 0.3s;
  }

  .periods-part{
    background: linear-gradient( 195deg, #ebac88d4, #44333a);
  }

  .statistics-part p{
    font-size: 24px;
  }

  .statistics-part b{
    font-size: 30px;
  }

  .statistics-part:hover{
    border-radius: 20px;
    transform: translate(0px, -6px);
    cursor: pointer;
  }
</style>
<script>
import store from "@/store/index.js";
import config from "../../public/js/config";

export default {
  name: "Home",
  store,
  data(){
    return {
      companiesCount: 0,
      trashedCompanies: 0
    }
  },
  computed: {
    user(){
      return this.$store.state.auth.user;
    }
  },
  created: function(){
      let user = this.$store.state.auth.user;
      this.axios.get(config.api_url() +'home/'+ user['id'], {headers: {"Authorization": "Bearer " + user['token']}})
        .then((response) => {
            if(response.status == 200){
              this.companiesCount = response.data.companies;
              this.trashedCompanies = response.data.trashedCompanies;
            }
        })
        .catch((error) => {
            if (error.response.status === 401) {
                alert('تم انتهاء جلسة الدخول , أعد تسجبل الدخول .');
                window.location.href = "/logout";
            }
            console.log(error);
        });
  }
};
</script>
