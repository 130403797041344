<template>
    <div>
        <button type="button" class="btn btn-primary btn-sm mb-1 dsip" @click.prevent="print">
            طباعة <b-icon-printer-fill></b-icon-printer-fill>
        </button>
        <div id="printPurchasesReport" dir="rtl">
            <h3 class="dsip">تقارير المشتريات</h3>

            <table class="table table-bordered table-striped">
                <thead>
                    <tr>
                        <td>التقرير</td>
                        <td>القيمة</td>
                        <td>الضريبة</td>
                    </tr>
                </thead>

                <tbody>
                    <tr>
                        <td>المشتريات</td>
                        <td>{{ (purchases['value'])?? 0 }}</td>
                        <td>{{ (purchases['tax'])?? 0 }}</td>
                    </tr>
                    <tr>
                        <td>المستوردات</td>
                        <td>{{ (custards['value'])?? 0 }}</td>
                        <td>{{ (custards['tax'])?? 0 }}</td>
                    </tr>
                    <tr>
                        <td>المشتريات الصفرية</td>
                        <td>{{ (zerosPurchases['value'])?? 0 }}</td>
                        <td>0</td>
                    </tr>
                    <tr>
                        <td>المشتريات المعفاة</td>
                        <td>{{ (exemptPurchases['value'])?? 0 }}</td>
                        <td>0</td>
                    </tr>
                </tbody>

                <tfoot>
                    <tr>
                        <td>المجموع</td>
                        <td>{{ sum['value'] }}</td>
                        <td>{{ sum['tax'] }}</td>
                    </tr>
                </tfoot>
            </table>
        </div>
    </div>
</template>

<script>
import $ from 'jquery';
import GlobalFunctions from "../../../public/js/globalFunctions";

export default {
    name: "PurchasesReport",
    props: ["period", "company"],
    computed:{
        "purchases": function(){
            return this.$store.state.generalTablePeriod.purchases;
        },
        "custards": function(){
            return this.$store.state.generalTablePeriod.custards;
        },
        "zerosPurchases": function(){
            return this.$store.state.generalTablePeriod.zerosPurchases;
        },
        "exemptPurchases": function(){
            return this.$store.state.generalTablePeriod.exemptPurchases;
        },
        "sum": function(){
            let tax = 0, value=0;
            tax+= (this.purchases['tax'])?? 0;
            value+= (this.purchases['value'])?? 0;

            tax+= (this.custards['tax'])?? 0;
            value+= (this.custards['value'])?? 0;

            value+= (this.zerosPurchases['value'])?? 0;
            value+= (this.exemptPurchases['value'])?? 0;

            return {"tax": parseFloat(tax).toFixed(3), "value": parseFloat(value).toFixed(3)};
        }
    },
    methods: {
       async print() {
            let target = $('#printPurchasesReport').clone();
            $(target).attr('id', "clonePrinting");
            let title = "تقارير المشتريات";
            GlobalFunctions.print(target, this.company, this.period, title);
        },
    },
}
</script>