<template>
    <div>
        <div class="d-flex justify-content-center my-2">
            <h2>الشركات</h2>

            <button class="btn rounded-pill btn-outline-success px-3 mx-3" @click="toggleAddCompany">
                <span> إضافة شركة جديدة </span> 
                 <b-icon-plus-circle></b-icon-plus-circle>
            </button>
        </div>
        
        <div class="my-3 toggle-add-company">
            <AddCompany />
        </div>
        <hr>

        <FilterCompanies />


        <template v-if="filteredCompanies == null">
            <template v-if="companies == null">
                <img src="/images/loader.gif" alt="loader image" >
            </template>
            <template v-else>              
                <CompaniesTable :companies="companies" />
            </template>
        </template>
        <template v-else>
            <CompaniesTable :companies="filteredCompanies" :key="Math.random(10)" />
        </template>
    </div>
</template>

<style scoped>
    .toggle-add-company{
        display: none;
    }
    thead {
        background-image: linear-gradient(120deg, #a1c4fd 0%, #c2e9fb 100%);
    }
    tfoot {
        background-image: linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%);
    }
</style>

<script>
import $ from "jquery";
import CompaniesTable from "@/components/companies/companies.vue";
import AddCompany from "@/components/companies/add.vue";
import FilterCompanies from "@/components/companies/filter.vue";

export default {
    name: "Companies",
    data(){
        return {
            type: this.$route.params.type, 
            companies: [],
        }
    },
    computed:{
        user(){
            return this.$store.state.auth.user;
        },
        filteredCompanies(){
            return this.$store.state.companies.filteredCompanies;
        }
    },
    components: {
        CompaniesTable,
        FilterCompanies,
        AddCompany,
    },
    methods: {
        "toggleAddCompany": function(){
            $('.toggle-add-company').slideToggle();
        },
    },
    created: function() {
        let user = this.$store.state.auth.user;
        this.$store.commit('companies/setFilteredCompanies', []);

        // let query = "user=" + user['id'] + "&type=" + this.type;

        // axios.get(config.api_url() + 'companies?' + query, {headers: {"Authorization": "Bearer "+ user['token']} })
        //     .then((response) => {
        //         if(response.status == 200){
        //             this.companies = response.data.companies;
        //         }
        //     })
        //     .catch((error) => {
        //         if (error.response.status === 401) {
        //             alert('تم انتهاء جلسة الدخول , أعد تسجبل الدخول .');
        //             window.location.href = "/logout";
        //         }

        //         console.log(error);
        //     });
    }
}
</script>