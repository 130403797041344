<template>
    <tr>
        <td>
            <input type="text" id="invoiceNumber" v-model="invoiceNumber" class="form-control p-xxl-1 p-0" @keydown.enter="switchFocus" placeholder="رقم الفاتورة">
        </td>
        <td style="width: 150px;font-size: 12px;">
            <input type="text" id="invoiceDate" v-model="invoiceDate" class="form-control p-xxl-1 p-0" @keydown.enter="switchFocus" placeholder="اليوم - الشهر - السنة">
        </td>
        <td>
            <input type="number" min="0" v-model="invoiceValue" @change="invoiceValueChanged" id="invoiceValue" @keydown.enter="switchFocus" class="form-control p-xxl-1 p-0" placeholder="القيمة">
        </td>
        <td>
            <input type="number" min="0" v-model="invoiceTotal" id="invoiceTotal" class="form-control p-xxl-1 p-0" @keydown.enter="switchFocus" placeholder="الإجمالي" readonly>
        </td>
        <td class="d-flex">
            <button class="btn btn-success btn-sm rounded-circle save-btn" @click="editInvoice">
                <b-icon-pencil></b-icon-pencil>
            </button>
            <button class="btn btn-warning btn-sm rounded-circle me-1" @click="backFromEdit">
                <b-icon-arrow-return-left></b-icon-arrow-return-left>
            </button>
        </td>
    </tr>
</template>

<script>
import alertFunctions from "../../../public/js/alertFunctions";
import config from "../../../public/js/config";
import functions from "../../../public/js/globalFunctions";
import $ from 'jquery';

export default {
    name: "EditInvoiceWithoutTax",
    props: ['period_id'],
    data: () => {
        return {
            invoice: null,
            type: "",
            invoiceNumber: "",
            invoiceValue: "",
            invoiceDate: "",
            invoiceTotal: "",
            isRequesting: false,
        }
    },
    watch: {
        "invoiceDate": function(){
            this.invoiceDate+= this.invoiceDate.length == 2 ? "-" : "";
            this.invoiceDate+= this.invoiceDate.length == 5 ? "-" : "";
            this.invoiceDate = this.invoiceDate.replace('--', '-');
            this.invoiceDate = (this.invoiceDate.length > 10)? this.invoiceDate.slice(0,10) : this.invoiceDate;
        },
    },
    computed: {
        selectedInvoiceForEdit: function(){
           return {
                "invoice": this.$store.state.invoiceData.editInvoice , 
                "type": this.$store.state.invoiceData.typeEditInvoice
            };
        }
    },
    methods: {
        "editInvoice": function(){
            if(this.isRequesting == true)
                return false;

            let token = this.$store.state.auth.user['token'];
            let formData = new FormData();
            formData.append('invoiceNumber', this.invoiceNumber);
            formData.append('invoiceTax', "");
            formData.append('invoiceRate', "");
            formData.append('invoiceValue', this.invoiceValue);
            formData.append('invoiceDate', this.invoiceDate);
            formData.append('invoiceTotal', this.invoiceTotal);
            formData.append('invoiceType', this.type);
            formData.append('period_id', this.period_id);

            this.isRequesting = true;
            this.axios.post(config.api_url() + "update-invoice/" + this.invoice.id, formData, {headers: {"Authorization": "Bearer " + token}})
                .then((response) => {
                    if(response.data.status == true){
                        let table = null;
                        switch(this.type){
                            case "zeros_purchases":  table = this.$store.state.generalTablePeriod.zerosPurchasesTable; break;
                            case "zeros_sales":  table = this.$store.state.generalTablePeriod.zerosSalesTable; break;
                            case "exempt_sales":  table = this.$store.state.generalTablePeriod.exemptSalesTable; break;
                            case "exported_sales":  table = this.$store.state.generalTablePeriod.exportedSalesTable; break;
                            case "freeTax-sales":  table = this.$store.state.generalTablePeriod.freeTaxSalesTable; break;
                        }

                        for( let i = 0; i < table.length; i++ ){
                            if( table[i]['invoice_id'] == this.invoice.id ){
                                let obj = table[i];
                                obj.invoice.number = this.invoiceNumber;
                                obj.invoice.value = parseFloat(this.invoiceValue);
                                obj.invoice.date = this.reverseString(this.invoiceDate);
                                obj.invoice.total = parseFloat(this.invoiceTotal);
                                table[i] = obj;
                            }
                        }         

                        if(response.data.invoice != null){
                            switch(this.type){
                                case "exported_sales":  this.$store.commit('generalTablePeriod/setExportedSalesTable', table); break;
                                case "freeTax-sales":  this.$store.commit('generalTablePeriod/setFreeTaxSalesTable', table); break;
                            }
                            
                            this.backFromEdit();
                            functions.getEndorsmentData(this.period_id);
                            alertFunctions.SuccessAlert("تم التعديل على الفاتورة بنجاح .");
                        }
                    }
                    else{
                        alertFunctions.ErrorAlert(response.data.message);
                    }
                    this.isRequesting = false;
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        alert('تم انتهاء جلسة الدخول , أعد تسجبل الدخول .');
                        window.location.href = "/logout";
                    }
                    else{
                        alertFunctions.ErrorAlert("حدث خطأ ما اثناء التعديل على الفاتورة !");
                    }
                    this.isRequesting = false;
                });
        },
        "backFromEdit": function(){
            this.$store.commit('invoiceData/setEditInvoice', {invoice: null, type:''});
        },
        "invoiceValueChanged": function(){
            this.invoiceTotal = parseFloat(this.invoiceValue).toFixed(3);
        },
        "reverseString": function(str){
            var splitString = str.split("-");
            var reverseArray = splitString.reverse();
            var joinArray = reverseArray.join("-");
            return joinArray;
        },
        "switchFocus": function() {
            var next = $(':focus').parent().next();
            if ( next.find('.save-btn').length > 0 ) {
                next.find('.save-btn').trigger('click');
            }
            else {
                next.find('input').focus();
            }
        }
    },
    created: function() {
        this.invoice = this.selectedInvoiceForEdit.invoice.invoice;
        this.type = this.selectedInvoiceForEdit.type;
        
        this.invoiceNumber = this.invoice.number;
        this.invoiceValue = this.invoice.value;
        this.invoiceDate = this.reverseString(this.invoice.date);
        this.invoiceTotal = this.invoice.total;
    },
}
</script>