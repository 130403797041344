<template>
    <div>
        <div class="d-flex justify-content-evenly align-items-start">
            <button type="button" class="btn btn-outline-primary rounded rounded-pill dsip" @click.prevent="print">
                طباعة الجدول العام <b-icon-printer-fill></b-icon-printer-fill>
            </button>
            
            <template v-if="period != null">
                <FilesForm :period="period" :section="'general'" class="dsip" />
            </template>
        </div>

        <div id="printGeneralTable" class="print-section" dir="rtl">
            <table class="table table-hover table-bordered mt-2 generalTable">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>المحتويات</th>
                        <th>القيمة</th>
                        <th>الضريبة</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>1</td>
                        <td>الرصيد الضريبي من الفترة السابقة</td>
                        <td>-</td>
                        <td style="max-width: 100px;">
                            <template v-if="oldPeriodTax['from_before'] == true">
                                <template v-if="oldPeriodTax['tax'] != null">
                                    <template v-if="oldPeriodTax['tax'] > 0">
                                        {{ (oldPeriodTax['tax'] != null)? oldPeriodTax['tax'].toFixed(3) : "0.000" }}
                                    </template>
                                    <template v-else>
                                        0
                                    </template>
                                </template>
                            </template>
                            <template v-else>
                                <input type="number" @change="changeOldPeriodTax" id="oldPeriodTax" :value="oldPeriodTax['tax']" class="form-control m-auto"  :disabled="company.deleted_at != null">
                            </template>
                        </td>
                    </tr>
       
                    <tr>
                        <td>2</td>
                        <td>المشتريات الخاضعة للضريبة بنسبة 16%</td>
                        <td>{{ (purchases_details[16] != null)? purchases_details[16]['value'].toFixed(3) : "0.000" }}</td>
                        <td>{{ (purchases_details[16] != null)? purchases_details[16]['tax'].toFixed(3) : "0.000" }}</td>
                    </tr>
                    <tr>
                        <td>3</td>
                        <td>المشتريات الخاضعة للضريبة بنسبة 10%</td>
                        <td>{{ (purchases_details[10] != null)? purchases_details[10]['value'].toFixed(3) : "0.000" }}</td>
                        <td>{{ (purchases_details[10] != null)? purchases_details[10]['tax'].toFixed(3) : "0.000" }}</td>
                    </tr>
                    <tr>
                        <td>4</td>
                        <td>المشتريات الخاضعة للضريبة بنسبة 4%</td>
                        <td>{{ (purchases_details[4] != null)? purchases_details[4]['value'].toFixed(3) : "0.000" }}</td>
                        <td>{{ (purchases_details[4] != null)? purchases_details[4]['tax'].toFixed(3) : "0.000" }}</td>
                    </tr>
                    <tr>
                        <td>5</td>
                        <td>المشتريات الخاضعة للضريبة بنسبة (أخرى)</td>
                        <td>{{ (purchases_details['other'] != null)? purchases_details['other']['value'].toFixed(3) : "0.000" }}</td>
                        <td>{{ (purchases_details['other'] != null)? purchases_details['other']['tax'].toFixed(3) : "0.000" }}</td>
                    </tr>

                    <tr>
                        <td>6</td>
                        <td>المصاريف</td>
                        <td>{{ (outlays['value'] != null)? outlays['value'].toFixed(3) : "0.000" }}</td>
                        <td>{{ (outlays['tax'] != null)? outlays['tax'].toFixed(3) : "0.000" }}</td>
                    </tr>
                    
                    <tr>
                        <td>7</td>
                        <td>المستوردات الخاضعة للضريبة بنسبة 16%</td>
                        <td>{{ (custards_details[16] != null)? custards_details[16]['value'].toFixed(3) : "0.000" }}</td>
                        <td>{{ (custards_details[16] != null)? custards_details[16]['tax'].toFixed(3) : "0.000" }}</td>
                    </tr>
                    <tr>
                        <td>8</td>
                        <td>المستوردات الخاضعة للضريبة بنسبة 10%</td>
                        <td>{{ (custards_details[10] != null)? custards_details[10]['value'].toFixed(3) : "0.000" }}</td>
                        <td>{{ (custards_details[10] != null)? custards_details[10]['tax'].toFixed(3) : "0.000" }}</td>
                    </tr>
                    <tr>
                        <td>9</td>
                        <td>المستوردات الخاضعة للضريبة بنسبة 4%</td>
                        <td>{{ (custards_details[4] != null)? custards_details[4]['value'].toFixed(3) : "0.000"}}</td>
                        <td>{{ (custards_details[4] != null)? custards_details[4]['tax'].toFixed(3) : "0.000" }}</td>
                    </tr>
                    <tr>
                        <td>10</td>
                        <td>المستوردات الخاضعة للضريبة بنسبة (أخرى)</td>
                        <td>{{ (custards_details['other'] != null)? custards_details['other']['value'].toFixed(3) : "0.000" }}</td>
                        <td>{{ (custards_details['other'] != null)? custards_details['other']['tax'].toFixed(3) : "0.000" }}</td>
                    </tr>
                    <tr>
                        <td>11</td>
                        <td>المستوردات الصفرية</td>
                        <td>{{ (zeros_custards['value'] != null)? zeros_custards['value'].toFixed(3) : "0.000" }}</td>
                        <td>-</td>
                    </tr>
                    <tr>
                        <td>12</td>
                        <td>المستوردات المعفاة</td>
                        <td>{{ (exempt_custards['value'] != null)? exempt_custards['value'].toFixed(3) : "0.000" }}</td>
                        <td>-</td>
                    </tr>
                    <tr>
                        <td>13</td>
                        <td>مشتريات صفرية</td>
                        <td>{{ (zeros_purchases['value'] != null)? zeros_purchases['value'].toFixed(3) : "0.000" }}</td>
                        <td>-</td>
                    </tr>

                      <tr>
                        <td>14</td>
                        <td>مشتريات معفاة</td>
                        <td>{{ (exempt_purchases['value'] != null)? exempt_purchases['value'].toFixed(3) : "0.000" }}</td>
                        <td>-</td>
                    </tr>

                    <tr>
                        <td>15</td>
                        <td>المبيعات الخاضعة للضريبة بنسبة 16%</td>
                        <td>{{ (sales_details[16] != null)? sales_details[16]['value'].toFixed(3) : "0.000" }}</td>
                        <td>{{ (sales_details[16] != null)? sales_details[16]['tax'].toFixed(3) : "0.000" }}</td>
                    </tr>
                    <tr>
                        <td>16</td>
                        <td>المبيعات الخاضعة للضريبة بنسبة 10%</td>
                        <td>{{ (sales_details[10] != null)? sales_details[10]['value'].toFixed(3) : "0.000" }}</td>
                        <td>{{ (sales_details[10] != null)? sales_details[10]['tax'].toFixed(3) : "0.000" }}</td>
                    </tr>
                    <tr>
                        <td>17</td>
                        <td>المبيعات الخاضعة للضريبة بنسبة 4%</td>
                        <td>{{ (sales_details[4] != null)? sales_details[4]['value'].toFixed(3) : "0.000" }}</td>
                        <td>{{ (sales_details[4] != null)? sales_details[4]['tax'].toFixed(3) : "0.000" }}</td>
                    </tr>
                    <tr>
                        <td>18</td>
                        <td>المبيعات الخاضعة للضريبة بنسبة (أخرى)</td>
                        <td>{{ (sales_details['other'] != null)? sales_details['other']['value'].toFixed(3) : "0.000" }}</td>
                        <td>{{ (sales_details['other'] != null)? sales_details['other']['tax'].toFixed(3) : "0.000" }}</td>
                    </tr>

                    <tr>
                        <td>19</td>
                        <td>مبيعات صفرية</td>
                        <td>{{ (zerosSales['value'] != null)? zerosSales['value'].toFixed(3) : "0.000" }}</td>
                        <td>-</td>
                    </tr>  
                    <tr>
                        <td>20</td>
                        <td>مبيعات مصدرة</td>
                        <td>{{ (exportedSales['value'] != null)? exportedSales['value'].toFixed(3) : "0.000" }}</td>
                        <td>-</td>
                    </tr>  
                    <tr>
                        <td>21</td>
                        <td>مبيعات معفاة</td>
                        <td>{{ (exemptSales['value'] != null)? exemptSales['value'].toFixed(3) : "0.000" }}</td>
                        <td>-</td>
                    </tr>  
                    <tr>
                        <td>22</td>
                        <td>المبيعات غير الخاضعة للضريبة</td>
                        <td>{{ (freeTaxSales['value'] != null)? freeTaxSales['value'].toFixed(3) : "0.000" }}</td>
                        <td>-</td>
                    </tr>
                    <tr>
                        <td>23</td>
                        <td>خدمات مستوردة</td>
                        <td>{{ (importedServices['value'] != null)? importedServices['value'].toFixed(3) : "0.000" }}</td>
                        <td>{{ (importedServices['tax'] != null)? importedServices['tax'].toFixed(3) : "0.000" }}</td>
                    </tr>
                    <tr>
                        <td>24</td>
                        <td>ضريبة غير قابلة للخصم (تخص مبيعات معفاة)</td>
                        <td>-</td>
                        <td style="max-width: 100px;">
                            <input type="number" id="nonDeductibleTax" @change="changeNonDeductibleTax" :value="nonDeductibleTax['tax']" class="form-control m-auto" :disabled="company.deleted_at != null">
                        </td>
                    </tr>
                    <tr>
                        <td>25</td>
                        <td>حركة تعديل لصالح الدائرة</td>
                        <td>-</td>
                        <td style="max-width: 100px;">
                            <input type="number" @change="changeEditingMovement" id="editingMovement" :value="editingMovement['tax']" class="form-control m-auto" :disabled="company.deleted_at != null">
                        </td>
                    </tr>
                    <tr>
                        <td>26</td>
                        <td>حركة تعديل لصالح المسجل</td>
                        <td>-</td>
                        <td style="max-width: 100px;">
                            <input type="number"  id="registerEditingAction" @change="changeRegisterEditingAction" :value="registerEditingMovement['tax']"  class="form-control m-auto" :disabled="company.deleted_at != null">
                        </td>
                    </tr>
                </tbody>

                <tfoot>
                    <tr>
                        <td colspan="2">الرصيد</td>
                        <td> --- </td>
                        <td :class="(taxesSum >= 0)? 'text-success' : 'text-danger'" style="font-weight:bold;">
                            {{ taxesSum }} 
                            {{ (taxesSum != 0)? (taxesSum > 0)? 'رصيد' : 'دفع' : '' }}
                        </td>
                    </tr>
                </tfoot>
            </table>

            <div>
                <h5 class="text-right">الملاحظات</h5>
                <textarea name="periodNotice" id="periodNotice" @change="changePeriodNotice" rows="3" class="form-control m-2" placeholder="ملاحظات" v-model="periodNotice"></textarea>
            </div>
        </div>
    </div>
</template>

<style scoped>
    thead {
        background-image: linear-gradient(120deg, #a1c4fd 0%, #c2e9fb 100%);
    }
    tfoot {
        background-image: linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%);
    }

    .header-background{ background-color: #e9e9e9;}
    .footer-background{ background-color: #e9e9e9;}
</style>

<script>
import config from "../../../public/js/config.js";
import useVuelidate from '@vuelidate/core';
import { required, integer } from '@vuelidate/validators';
import alertFunctions from "../../../public/js/alertFunctions.js";
import GlobalFunctions from "../../../public/js/globalFunctions";
import FilesForm from "../endorsment/filesForm.vue";
import $ from 'jquery';

export default {
    name: "GeneralEndorsmentTable",
    props: ["endorsment", "company", "period"],
    data(){
        return {
            v$: useVuelidate(),
        }
    },
    components: {
        FilesForm,
    },
    validations () {
        return {
            oldPeriodTax: {
                required,
                integer
            },
            taxesSum: {
                required,
                integer,
            },
        }
    },
    computed: {
        "purchases": function(){
            return this.$store.state.generalTablePeriod.purchases;
        },
        "purchases_details": function(){
            return this.$store.state.generalTablePeriod.purchases_details;
        },
        "outlays": function(){
            return this.$store.state.generalTablePeriod.outlays;
        },
        "custards": function(){
            return this.$store.state.generalTablePeriod.custards;
        },
        "zeros_custards": function(){
            return this.$store.state.generalTablePeriod.zeros_custards;
        },
        "exempt_custards": function(){
            return this.$store.state.generalTablePeriod.exempt_custards;
        },
        "importedServices": function(){
            return this.$store.state.generalTablePeriod.importedServices;
        },
        "custards_details": function(){
            return this.$store.state.generalTablePeriod.custards_details;
        },
        "zeros_purchases": function(){
            return this.$store.state.generalTablePeriod.zerosPurchases;
        },
        "exempt_purchases": function(){
            return this.$store.state.generalTablePeriod.exemptPurchases;
        },
        "sales": function(){
            return this.$store.state.generalTablePeriod.sales;
        },
        "sales_details": function(){
            return this.$store.state.generalTablePeriod.sales_details;
        },
        "exemptSales": function(){
            return this.$store.state.generalTablePeriod.exemptSales;
        },
        "zerosSales": function(){
            return this.$store.state.generalTablePeriod.zerosSales;
        },
        "exportedSales": function(){
            return this.$store.state.generalTablePeriod.exportedSales;
        },
        "oldPeriodTax": function(){
            return this.$store.state.generalTablePeriod.oldPeriodTax;
        },
        "editingMovement": function(){
            return this.$store.state.generalTablePeriod.editingMovement;
        },
        "periodNotice": function(){
            return this.$store.state.generalTablePeriod.periodNotice;
        },
       "registerEditingMovement": function(){
            return this.$store.state.generalTablePeriod.registerEditingMovement;
        },
        "nonDeductibleTax": function(){
            return this.$store.state.generalTablePeriod.nonDeductibleTax;
        },
        "freeTaxSales": function(){
            return this.$store.state.generalTablePeriod.freeTaxSales;
        },
        "taxesSum": function(){
            let oldPeriodTax = (this.oldPeriodTax['tax'] != null)? Number.parseFloat(this.oldPeriodTax['tax']) : 0;
            
            let sum = 0;            
            sum+= (oldPeriodTax > 0)? oldPeriodTax : 0;
            sum+= Number.parseFloat( (this.purchases['tax'])?? 0 );
            sum+= Number.parseFloat( (this.outlays['tax'])?? 0 );
            sum+= Number.parseFloat( (this.custards['tax'])?? 0 );
            sum+= Number.parseFloat( (this.importedServices['tax'])?? 0 );
            sum+= Number.parseFloat( (this.registerEditingMovement['tax'])?? 0);

            sum-= Number.parseFloat( (this.sales['tax'])?? 0 );
            sum-= Number.parseFloat( (this.nonDeductibleTax['tax'])?? 0);
            sum-= Number.parseFloat( (this.editingMovement['tax'])?? 0);

            return sum.toFixed(3);
        },
    },
    methods: {
        changeOldPeriodTax: function(){
            let token = this.$store.state.auth.user['token'];
            let tax = document.getElementById('oldPeriodTax').value;
            let formData = new FormData();
            formData.append('tax', tax);

            this.axios.post(config.api_url() + "change-old-period-tax/"+this.endorsment.id, formData, {headers: {"Authorization": "Bearer " + token}})
                .then((response) => {
                    // console.log(response);
                    if( response.data.status == true ){
                        alertFunctions.SuccessAlert("تم تغيير ضريبة الفترة السابقة .");
                        this.$store.commit('generalTablePeriod/setOldPeriodTax', {'tax': tax});
                    }
                    else{
                        alertFunctions.ErrorAlert(response.data.message);
                    }
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        alert('تم انتهاء جلسة الدخول , أعد تسجبل الدخول .');
                        window.location.href = "/logout";
                    }
                    console.log(error);
                });
        },
        changeEditingMovement: function(){
            let token = this.$store.state.auth.user['token'];
            let tax = document.getElementById('editingMovement').value;
            let formData = new FormData();
            formData.append('tax', tax);

            this.axios.post(config.api_url() + "change-editing-movement/"+this.endorsment.id, formData, {headers: {"Authorization": "Bearer " + token}})
                .then((response) => {
                    if( response.data.status == true ){
                        this.$store.commit('generalTablePeriod/setEditingMovement', {'tax': tax});
                        alertFunctions.SuccessAlert("تم تعديل حركة التعديل لصالح الدائرة .");
                    }
                    else{
                        alertFunctions.ErrorAlert(response.data.message);
                    }
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        alert('تم انتهاء جلسة الدخول , أعد تسجبل الدخول .');
                        window.location.href = "/logout";
                    }
                    console.log(error);
                });
        },
        changeRegisterEditingAction: function(){
            let token = this.$store.state.auth.user['token'];
            let tax = document.getElementById('registerEditingAction').value;
            let formData = new FormData();
            formData.append('tax', tax);

            this.axios.post(config.api_url() + "change-register-editing-movement/"+this.endorsment.id, formData, {headers: {"Authorization": "Bearer " + token}})
                .then((response) => {
                    if( response.data.status == true ){
                        this.$store.commit('generalTablePeriod/setRegisterEditingMovement', {'tax': tax});
                        alertFunctions.SuccessAlert("تم تعديل حركة التعديل لصالح المسجل .");
                    }
                    else{
                        alertFunctions.ErrorAlert(response.data.message);
                    }
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        alert('تم انتهاء جلسة الدخول , أعد تسجبل الدخول .');
                        window.location.href = "/logout";
                    }
                    console.log(error);
                });
        },
        changeNonDeductibleTax: function(){
            let token = this.$store.state.auth.user['token'];
            let tax = document.getElementById('nonDeductibleTax').value;
            let formData = new FormData();
            formData.append('tax', tax);

            this.axios.post(config.api_url() + "change-nonDeductibleTax/"+this.endorsment.id, formData, {headers: {"Authorization": "Bearer " + token}})
                .then((response) => {
                    if( response.data.status == true ){
                        this.$store.commit('generalTablePeriod/setNonDeductibleTax', {'tax': tax});
                        alertFunctions.SuccessAlert("تم تعديل الضريبة غير خاضعة للخصم .");
                    }
                    else{
                        alertFunctions.ErrorAlert(response.data.message);
                    }
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        alert('تم انتهاء جلسة الدخول , أعد تسجبل الدخول .');
                        window.location.href = "/logout";
                    }
                    console.log(error);
                });
        },
        changePeriodNotice:  function(){
            let token = this.$store.state.auth.user['token'];
            let formData = new FormData();
            let notice = document.getElementById('periodNotice').value;
            formData.append('notice', notice);

            this.axios.post(config.api_url() + "change-changePeriodNotice/"+this.endorsment.id, formData, {headers: {"Authorization": "Bearer " + token}})
                .then((response) => {
                    if( response.data.status == true ){
                        this.$store.commit('generalTablePeriod/setPeriodNotice', notice);
                        alertFunctions.SuccessAlert("تم تعديل الملاحظات .");
                    }
                    else{
                        alertFunctions.ErrorAlert(response.data.message);
                    }
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        alert('تم انتهاء جلسة الدخول , أعد تسجبل الدخول .');
                        window.location.href = "/logout";
                    }
                    console.log(error);
                });
        },
        async print() {
            let target = $('#printGeneralTable').clone();
            let title = "الجدول العام";
            $(target).attr('id', "clonePrinting"); 

            $(target).find('.table').addClass('table-sm');

            $(target).find('#periodNotice').css({'width': '80%'});
            GlobalFunctions.print(target, this.company, this.period, title);
        },
    },
    created: function(){
        this.$store.commit('generalTablePeriod/setPurchases', JSON.parse(this.endorsment.purchases));
        this.$store.commit('generalTablePeriod/setPurchasesDetails', JSON.parse(this.endorsment.purchases_details));
        this.$store.commit('generalTablePeriod/setOutlays', JSON.parse(this.endorsment.outlays));
        this.$store.commit('generalTablePeriod/setCustards', JSON.parse(this.endorsment.custards));
        this.$store.commit('generalTablePeriod/setZerosCustards', JSON.parse(this.endorsment.zeros_custards));
        this.$store.commit('generalTablePeriod/setExemptCustards', JSON.parse(this.endorsment.exempt_custards));
        this.$store.commit('generalTablePeriod/setCustardsDetails', JSON.parse(this.endorsment.custards_details));
        this.$store.commit('generalTablePeriod/setZerosPurchases', JSON.parse(this.endorsment.zeros_purchases));
        this.$store.commit('generalTablePeriod/setExemptPurchases', JSON.parse(this.endorsment.exempt_purchases));
        this.$store.commit('generalTablePeriod/setImportedServices', JSON.parse(this.endorsment.imported_services));
        this.$store.commit('generalTablePeriod/setSales', JSON.parse(this.endorsment.sales));
        this.$store.commit('generalTablePeriod/setSalesDetails', JSON.parse(this.endorsment.sales_details));
        this.$store.commit('generalTablePeriod/setZerosSales', JSON.parse(this.endorsment.zeros_sales));
        this.$store.commit('generalTablePeriod/setExportedSales', JSON.parse(this.endorsment.export_sales));
        this.$store.commit('generalTablePeriod/setExemptSales', JSON.parse(this.endorsment.exempt_sales));
        this.$store.commit('generalTablePeriod/setOldPeriodTax', JSON.parse(this.endorsment.old_period_tax));
        this.$store.commit('generalTablePeriod/setEditingMovement', JSON.parse(this.endorsment.editing_action));
        this.$store.commit('generalTablePeriod/setPeriodNotice', this.endorsment.notice);
        this.$store.commit('generalTablePeriod/setNonDeductibleTax', JSON.parse(this.endorsment.nonDeductibleTax));
        this.$store.commit('generalTablePeriod/setRegisterEditingMovement', JSON.parse(this.endorsment.register_editing_action));
        this.$store.commit('generalTablePeriod/setFreeTaxSales', JSON.parse(this.endorsment.freeTax_sales));
    }
}
</script>