import { createStore } from "vuex";
import swal from 'sweetalert2';
import axios from 'axios';
import config from '../../public/js/config';
import functions from "../../public/js/globalFunctions";
import alertFunctions from "../../public/js/alertFunctions";

/* Auth Module like (user info / token ...)*/
let auth = {
  namespaced: true,
  state: {
    user: null,
    admin: null,
  },
  mutations: {
    setUser(state, user){
      state.user = user;
    },
    setAdmin(state, admin){
      state.admin = admin;
    },
  },
  actions: {},
}

let DashboardData = {
  namespaced: true,
  state: {
    users: []
  },
  mutations: {
    setUsers(state, usersData){
      state.users = usersData;
    }
  },
  actions: {}
}


/* Companies Module to share data between components in companies view*/
let companies = {
  namespaced: true,
  state: {
    filteredCompanies: null,
  },
  mutations: {
    setFilteredCompanies(state, companies){
      state.filteredCompanies = companies;
    }
  },
  actions: {},
}

/* Data For General Table For Specific Period */
let generalTablePeriod = {
  namespaced: true,
  state: {
    period: null,
    purchases: {'value': 0, 'tax': 0},
    outlays: {'value': 0, 'tax': 0},
    custards: {'value': 0, 'tax': 0},
    zeros_custards: {'value': 0},
    exempt_custards: {'value': 0},
    importedServices: {'value': 0, 'tax': 0},
    zerosPurchases: {'value': 0},
    exemptPurchases: {'value': 0},
    sales: {'value': 0, 'tax': 0},
    freeTaxSales: {'value': 0},
    exemptSales: {'value': 0},
    zerosSales: {'value': 0},
    exportedSales: {'value': 0},
    oldPeriodTax: {'tax': 0, 'from_before': true},
    editingMovement: {'tax': 0},
    purchases_details: {16: {'tax':0,'value':0}, 10: {'tax':0,'value':0}, 4: {'tax':0,'value':0}, 'other':{'tax':0,'value':0}},
    custards_details: {16: {'tax':0,'value':0}, 10: {'tax':0,'value':0}, 4: {'tax':0,'value':0}, 'other':{'tax':0,'value':0}},
    sales_details: {16: {'tax':0,'value':0}, 10: {'tax':0,'value':0}, 4: {'tax':0,'value':0}, 'other':{'tax':0,'value':0}},
    registerEditingMovement: {'tax': 0},
    nonDeductibleTax: {'tax': 0},
    periodNotice: "",

    purchasesTable: [],
    outlaysTable: [],
    custardsTable: [],
    importedServicesTable: [],
    salesTable: [],
    freeTaxSalesTable: [],
    exemptSalesTable: [],
    zerosSalesTable: [],
    exportedSalesTable: [],

    settings: [],
  },
  mutations: {
    setPeriod(state, period){
      state.period = period;
    },
    setPurchases(state, purchase){
      state.purchases = purchase;
    },
    setPurchasesDetails(state, purchasesDetails){
      state.purchases_details = purchasesDetails;
    },
    setOutlays(state, outlays){
      state.outlays = outlays;
    },
    setCustards(state, custard){
      state.custards = custard;
    },
    setZerosCustards(state, zerosCustards){
      state.zeros_custards = zerosCustards;
    },
    setExemptCustards(state, exemptCustards){
      state.exempt_custards = exemptCustards;
    },
    setImportedServices(state, importedServices){
      state.importedServices = importedServices;
    },
    setCustardsDetails(state, custardsDetails){
      state.custards_details = custardsDetails;
    },
    setZerosPurchases(state, zeros_purchases){
      state.zerosPurchases = zeros_purchases;
    },
    setExemptPurchases(state, exempt_purchases){
      state.exemptPurchases = exempt_purchases;
    },
    setSales(state, salesData){
      state.sales = salesData;
    },
    setExemptSales(state, salesData){
      state.exemptSales = salesData;
    },
    setZerosSales(state, salesData){
      state.zerosSales = salesData;
    },
    setFreeTaxSales(state, salesData){
      state.freeTaxSales = salesData;
    },
    setSalesDetails(state, salesDetails){
      state.sales_details = salesDetails;
    },
    setExportedSales(state, exportedSales){
      state.exportedSales = exportedSales;
    },
    setOldPeriodTax(state, oldPeriod){
      state.oldPeriodTax = oldPeriod;
    },
    setEditingMovement(state, movement){
      state.editingMovement = movement;
    },
    setPeriodNotice(state, notice){
      state.periodNotice = notice;
    },
    setRegisterEditingMovement(state, regsiterEditing){
      state.registerEditingMovement = regsiterEditing;
    },
    setNonDeductibleTax(state, nonDeductibleTax){
      state.nonDeductibleTax = nonDeductibleTax;
    },

    // Tables
    setPurchasesTable(state, table){
      state.purchasesTable = table;
    },
    setOutlaysTable(state, table){
      state.outlaysTable = table;
    },
    setCustardsTable(state, table){
      state.custardsTable = table;
    },
    setImportedServicesTable(state, importedServicesTable){
      state.importedServicesTable = importedServicesTable;
    },
    setSalesTable(state, table){
      state.salesTable = table;
    },
    setFreeTaxSalesTable(state, table){
      state.freeTaxSalesTable = table;
    },
    setExemptSalesTable(state, table){
      state.exemptSalesTable = table;
    },
    setZerosSalesTable(state, table){
      state.zerosSalesTable = table;
    },
    setExportedSalesTable(state, table){
      state.exportedSalesTable = table;
    },

    setSettingsList(state, list){
      state.settings = list;
    }
  },
  actions: {},
}

let invoiceData = {
  namespaced: true,
  state: {
    editInvoice: null,
    typeEditInvoice: "",
    
  },
  mutations: {
    setEditInvoice(state, data){
      state.editInvoice = data['invoice'];
      state.typeEditInvoice = data['type'];
    }
  },
  actions: {
    confirmDeleteInvoice({dispatch}, data){
      new swal({
          title: 'هل أنت متأكد ؟',
          text: "لا يمكنك التراجع عن الحذف إذا تم التأكيد !",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'حذف',
          cancelButtonText: 'لا'
      })
      .then((result) => {
        if( result.isConfirmed == true ){
          dispatch('deleteInvoice', data);
        }
      });
    },

    deleteInvoice({rootState, commit}, data) {
      let token = rootState.auth.user['token'];
      
      let query = "type=" + data['type'] + "&periodId=" + data['periodId'];
      axios.delete(config.api_url() + 'invoices/' + data['invoice'].id +"?"+ query, {headers: {"Authorization": "Bearer " + token}})
        .then((response) => {
              if(response.data.status){
                let table = null;

                switch(data['type']){
                  case "purchases":  
                    table = rootState.generalTablePeriod.purchasesTable;
                    commit('generalTablePeriod/setPurchases', JSON.parse(response.data.endorsment), {root: true});
                    break;
                  case "outlays":  
                    table = rootState.generalTablePeriod.outlaysTable;
                    commit('generalTablePeriod/setOutlays', JSON.parse(response.data.endorsment), {root: true});
                    break;
                  case "custards":  
                    table = rootState.generalTablePeriod.custardsTable;
                    commit('generalTablePeriod/setCustards', JSON.parse(response.data.endorsment), {root: true});
                    break;
                  case "imported_services":  
                    table = rootState.generalTablePeriod.importedServicesTable;
                    commit('generalTablePeriod/setImportedServices', JSON.parse(response.data.endorsment), {root: true});
                    break;
                  case "sales":  
                    table = rootState.generalTablePeriod.salesTable;
                    commit('generalTablePeriod/setSales', JSON.parse(response.data.endorsment), {root: true});
                    break;
                  case "exported_sales": 
                    table = rootState.generalTablePeriod.exportedSalesTable;
                    commit('generalTablePeriod/setExportedSales', JSON.parse(response.data.endorsment), {root: true});
                    break;
                  case "freeTax-sales": 
                    table = rootState.generalTablePeriod.freeTaxSalesTable;
                    commit('generalTablePeriod/setFreeTaxSales', JSON.parse(response.data.endorsment), {root: true});
                    break;
                }

                for( let i=0; i < table.length; i++ ){
                  if( table[i].invoice_id == data['invoice'].id ){
                    table.splice(i, 1);
                    break; 
                  }
                }

                functions.getEndorsmentData( data['periodId'] );
                new swal({
                  title: 'تم حذف الفاتورة بنجاح !',
                  icon: 'success',
                  confirmButtonColor: '#3085d6',
                });
              }
              else{
                alertFunctions.ErrorAlert( response.data.message );
              }
        })
        .catch(() => {
            console.error('error');
        });
    }
  },
}

export default createStore({
  state: {
    showNav: true,
    debug_mode: true,
    api_url: ""
  },
  mutations: {
    setShowNav: function(state, show){
      state.showNav = show;
    }
  },
  actions: {},
  modules: {
    auth,
    companies,
    generalTablePeriod,
    invoiceData,
    DashboardData
  },
});
