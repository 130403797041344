<template>
    <div>
        <button type="button" class="btn btn-primary btn-sm mb-1 dsip" @click.prevent="print">
            طباعة <b-icon-printer-fill></b-icon-printer-fill>
        </button>
        <div id="printSalesReport" dir="rtl">
            <h3 class="dsip">تقارير المبيعات</h3>

            <table class="table table-bordered table-striped">
                <thead>
                    <tr>
                        <td>التقرير</td>
                        <td>القيمة</td>
                        <td>الضريبة</td>
                    </tr>
                </thead>

                <tbody>
                    <tr>
                        <td>المبيعات</td>
                        <td>{{ (sales['value'])? parseFloat(sales['value']).toFixed(3) : 0 }}</td>
                        <td>{{ (sales['tax'])? parseFloat(sales['tax']).toFixed(3) : 0 }}</td>
                    </tr>
                    <tr>
                        <td>المبيعات الصفرية</td>
                        <td>{{ (zerosSales['value'])? parseFloat(zerosSales['value']).toFixed(3) : 0 }}</td>
                        <td>0</td>
                    </tr>
                    <tr>
                        <td>المبيعات المصدّرة</td>
                        <td>{{ (exportedSales['value'])? parseFloat(exportedSales['value']).toFixed(3) : 0 }}</td>
                        <td>0</td>
                    </tr>
                    <tr>
                        <td>المبيعات المعفاة</td>
                        <td>{{ (exemptSales['value'])? parseFloat(exemptSales['value']).toFixed(3) : 0 }}</td>
                        <td>0</td>
                    </tr>
                    <tr>
                        <td>المبيعات غير الخاضعة للضريبة</td>
                        <td>{{ (freeTaxSales['value'])? parseFloat(freeTaxSales['value']).toFixed(3) : 0 }}</td>
                        <td>0</td>
                    </tr>
                </tbody>

                <tfoot>
                    <tr>
                        <td>المجموع</td>
                        <td>{{ sum['value'] }}</td>
                        <td>{{ sum['tax'] }}</td>
                    </tr>
                </tfoot>
            </table>
        </div>
    </div>
</template>

<script>
import $ from 'jquery';
import GlobalFunctions from "../../../public/js/globalFunctions";

export default {
    name: "salesReport",
    props: ["period", "company"],
    computed:{
        "sales": function(){
            return this.$store.state.generalTablePeriod.sales;
        },
        "zerosSales": function(){
            return this.$store.state.generalTablePeriod.zerosSales;
        },
        "exportedSales": function(){
            return this.$store.state.generalTablePeriod.exportedSales;
        },
        "exemptSales": function(){
            return this.$store.state.generalTablePeriod.exemptSales;
        },
        "freeTaxSales": function(){
            return this.$store.state.generalTablePeriod.freeTaxSales;
        },
        "sum": function(){
            let tax = 0, value=0;
            tax+= (this.sales['tax'])?? 0;
            value+= (this.sales['value'])?? 0;

            value+= (this.zerosSales['value'])?? 0;

            value+= (this.exportedSales['value'])?? 0;

            value+= (this.exemptSales['value'])?? 0;

            value+= (this.freeTaxSales['value'])?? 0;

            return {"tax": parseFloat(tax).toFixed(3), "value": parseFloat(value).toFixed(3)};
        }
    },
    methods: {
       async print() {
            let target = $('#printSalesReport').clone();
            $(target).attr('id', "clonePrinting");
            let title = "تقارير المبيعات";
            GlobalFunctions.print(target, this.company, this.period, title);
        },
    },
}
</script>