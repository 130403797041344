import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import Login from "../views/Auth/login.vue";
import Logout from "../components/layouts/logout.vue";
import Companies from "../views/companies/companies.vue";
import editCompany from "../components/companies/edit.vue";
import Settings from "../views/settings.vue";
import Period from "../views/period.vue";
import mainPageDashboard from "../views/dashboard/index.vue";
import dashboardLogin from "../views/dashboard/login.vue";
import dashboardLogout from "../components/dashboard/layouts/logout";
import editUserData from "../components/dashboard/users/edit.vue";
import postReport from "../components/companies/postReport.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/logout",
    name: "logout",
    component: Logout,
  },
  {
    path: "/companies/:type",
    name: "companies",
    component: Companies,
  },
  {
    path: "/companies/edit/:id",
    name: "editCompany",
    component: editCompany,
  },
  {
    path: "/companies/post-status-report",
    name: "postReport",
    component: postReport,
  },
  {
    path: "/settings",
    name: "settings",
    component: Settings,
  },
  {
    path: "/period/:id",
    name: "period",
    component: Period,
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: mainPageDashboard,
  },
  {
    path: "/dashboard/login",
    name: "dashboardLogin",
    component: dashboardLogin,
  },
  {
    path: "/dashboard/logout",
    name: "dashboardLogout",
    component: dashboardLogout,
  },
  {
    path: "/dashboard/users/:id",
    name: "editUser",
    component: editUserData,
  },

];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
