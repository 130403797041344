<template>
    <div>
        <template v-if="company.deleted_at == null">
            <div class="d-flex justify-content-evenly dsip">
                <ImportFromExcel :invoicesType="'free_tax_sales'" :period_id="period.id" class="dsip"/>

                <template v-if="period != null">
                    <FilesForm :period="period" :section="'freeTax_sales'" />
                </template>
            </div>
        </template>

        <button type="button" class="btn btn-primary btn-sm mb-1 dsip" @click.prevent="print">
            طباعة <b-icon-printer-fill></b-icon-printer-fill>
        </button>

        <div id="printFreeTaxSalesTable" class="print-section" dir="rtl">
            <table class="table table-striped table-bordered">
                <thead>
                    <tr>
                        <th style="min-width: 150px;">رقم الفاتورة</th>
                        <th>التاريخ</th>
                        <th>القيمة</th>
                        <th>الإجمالي</th>
                        <th class="dsip"></th>
                    </tr>
                </thead>

                <tbody>
                    <template v-for="sale in freeTaxSales" :key="sale.id" :data-invoiceId="sale.invoice.id">
                        <tr v-if="selectedInvoiceForEdit.invoice?.invoice.id != sale.invoice.id">
                           <td>
                                {{ sale.invoice.number }}
                            </td>
                            <td style="width: 150px;font-size: 14px;">
                                {{ sale.invoice.date }}
                            </td>
                            <td>
                                {{ sale.invoice.value.toFixed(3) }}
                            </td>
                            <td>
                                {{ total(sale.invoice) }}
                            </td>
                            <td class="d-flex dsip" v-if="company.deleted_at == null">
                                <button class="btn btn-success btn-sm rounded-circle mx-1" 
                                        @click="this.$store.commit('invoiceData/setEditInvoice',{invoice: sale, type:'freeTax-sales'})">
                                    <b-icon-pencil-square></b-icon-pencil-square>
                                </button>
                                <button class="btn btn-danger btn-sm rounded-circle" 
                                        @click.prevent="this.$store.dispatch('invoiceData/confirmDeleteInvoice', {invoice: sale.invoice, periodId: period.id, type:'freeTax-sales'})">
                                    <b-icon-trash></b-icon-trash>
                                </button>
                            </td>
                        </tr>
                        <template v-else>
                            <EditInvoiceWithoutTax :period_id="period.id" />
                        </template>
                    </template>
     
                    <template v-if="company.deleted_at == null">
                        <AddInvoiceWithoutTax :period_id="period.id" :invoiceType="'freeTax-sales'" class="dsip" />
                    </template>
                </tbody>

                <tfoot>
                    <tr>
                        <td colspan="2">المجموع : </td>
                        <td>{{ parseFloat(sumFreeTaxSales['valueSum']).toFixed(3) }}</td>
                        <td colspan="2"></td>
                    </tr>
                </tfoot>
            </table>
        </div>
    </div>
</template>

<style scoped>
    thead {
        background-image: linear-gradient(120deg, #a1c4fd 0%, #c2e9fb 100%);
    }
    tfoot {
        background-image: linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%);
    }

    .header-background{ background-color: #e9e9e9;}
    .footer-background{ background-color: #e9e9e9;}
</style>

<script>
import AddInvoiceWithoutTax from "../invoices/addInvoiceWithoutTax.vue";
import ImportFromExcel from "../invoices/importFromExcel.vue";
import EditInvoiceWithoutTax from "../invoices/editInvoiceWithoutTax.vue";
import FilesForm from "../endorsment/filesForm.vue";
import GlobalFunctions from "../../../public/js/globalFunctions";
import $ from 'jquery';

export default {
    name: "FreeTaxSalesTable",
    props: ["period", "company"],
    components: {
        AddInvoiceWithoutTax,
        ImportFromExcel,
        EditInvoiceWithoutTax,
        FilesForm
    },
    computed: {
        'sumFreeTaxSales': function(){
            let valuesSum = 0;
            this.freeTaxSales.forEach(element => {
                valuesSum+= element.invoice.value;
            });
            return {"valueSum": valuesSum};
        },
        "invoiceTotal": function(){
            return this.invoiceValue;
        },
        "freeTaxSales": function(){
            return this.$store.state.generalTablePeriod.freeTaxSalesTable;
        },
        "selectedInvoiceForEdit": function(){
            return {
                "invoice": this.$store.state.invoiceData.editInvoice , 
                "type": this.$store.state.invoiceData.typeEditInvoice
            };
        }
    },
    methods: {
        "total": function(invoice){
            return invoice.value.toFixed(3);
        },
        async print() {
            let target = $('#printFreeTaxSalesTable').clone();
            $(target).attr('id', "clonePrinting");
            let title = "جدول المبيعات غير خاضعة للضرائب";
            GlobalFunctions.print(target, this.company, this.period, title);
        },
    },
    updated: function(){
        let valueSum = this.sumFreeTaxSales['valueSum'];
        if( valueSum != 0 ){
            this.$store.commit('generalTablePeriod/setFreeTaxSales', {'value': valueSum});
        }
    }
}
</script>
