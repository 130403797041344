<template>
    <div>
        <template v-if="company.deleted_at == null">
            <div class="d-flex justify-content-evenly dsip">
                <ImportFromExcel :invoicesType="'imported_services'" :period_id="period.id" class="dsip"/>

                <template v-if="period != null">
                    <FilesForm :period="period" :section="'imported_services'" />
                </template>
            </div>
        </template>

        <button type="button" class="btn btn-primary btn-sm mb-1 dsip" @click.prevent="print">
            طباعة <b-icon-printer-fill></b-icon-printer-fill>
        </button>

        <div id="printImportedServicesTable" class="print-section" dir="rtl">
            <table class="table table-striped table-bordered">
                <thead>
                    <tr>
                        <th>رقم الفاتورة</th>
                        <th>التاريخ</th>
                        <th>النسبة</th>
                        <th>القيمة</th>
                        <th>الضريبة</th>
                        <th>الإجمالي</th>
                        <th class="dsip"></th>
                    </tr>
                </thead>

                <tbody>
                    <template v-for="importedService in importedServices" :key="importedService.id" :data-invoiceId="importedService.invoice.id">
                        <tr v-if="selectedInvoiceForEdit.invoice?.invoice.id != importedService.invoice.id">
                            <td>
                                {{ importedService.invoice.number }}
                            </td>
                            <td style="font-size: 14px;">
                                {{ importedService.invoice.date }}
                            </td>
                            <td>
                                {{ importedService.invoice.rate + '%' }}
                            </td>
                            <td>
                                {{ importedService.invoice.value.toFixed(3) }}
                            </td>
                            <td>
                                {{ importedService.invoice.tax.toFixed(3) }}
                            </td>
                            <td>
                                {{ total(importedService.invoice) }}
                            </td>
                            <td class="d-flex dsip" v-if="company.deleted_at == null">
                                
                                <button class="btn btn-success btn-sm rounded-circle mx-1"
                                        @click="this.$store.commit('invoiceData/setEditInvoice', {invoice: importedService, type:'imported_services'})">
                                    <b-icon-pencil-square></b-icon-pencil-square>
                                </button>
                                <button class="btn btn-danger btn-sm rounded-circle" 
                                        @click.prevent="this.$store.dispatch('invoiceData/confirmDeleteInvoice', {invoice: importedService.invoice, periodId: period.id, type:'imported_services'})">
                                    <b-icon-trash></b-icon-trash>
                                </button>
                            </td>
                        </tr>
                        <template v-else>
                            <EditInvoiceWithTax :period_id="period.id" />
                        </template>
                    </template>
              
                    <template v-if="company.deleted_at == null">
                        <AddInvoiceWithTax :period_id="period.id" :invoiceType="'imported-services'" :default_tax="default_tax_val" class="dsip"/>
                    </template>
                </tbody>

                <tfoot>
                    <tr>
                        <td colspan="3">المجموع : </td>
                        <td>{{ parseFloat(sumImportedServices['valueSum']).toFixed(3) }}</td>
                        <td>{{ parseFloat(sumImportedServices['taxSum']).toFixed(3) }}</td>
                        <td colspan="2"></td>
                    </tr>
                </tfoot>
            </table>
        </div>

    </div>
</template>

<style scoped>
    .print-section{
        page-break-after: always;
    }

    thead {
        background-image: linear-gradient(120deg, #a1c4fd 0%, #c2e9fb 100%);
    }
    tfoot {
        background-image: linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%);
    }

    .header-background{ background-color: #e9e9e9;}
    .footer-background{ background-color: #e9e9e9;}
</style>

<script>
import AddInvoiceWithTax from "../invoices/addInvoiceWithTax.vue";
import EditInvoiceWithTax from "../invoices/editInvoiceWithTax.vue";
import ImportFromExcel from "../invoices/importFromExcel.vue";
import FilesForm from "../endorsment/filesForm.vue";
import GlobalFunctions from "../../../public/js/globalFunctions";
import $ from 'jquery';

export default {
    name: "importedServicesTable",
    props: ["period", "company"],

    components: {
        AddInvoiceWithTax,
        ImportFromExcel,
        EditInvoiceWithTax,
        FilesForm
    },
    computed: {
        'sumImportedServices': function(){
            let taxesSum = 0, valuesSum = 0, ratesSum = 0;
            this.importedServices.forEach(element => {
                taxesSum+= element.invoice.tax;
                valuesSum+= element.invoice.value;
                ratesSum+= element.invoice.rate;
            });
            return {"taxSum": taxesSum, "valueSum": valuesSum, "rateSum": ratesSum};
        },
        "invoiceTotal": function(){
            return ((this.invoiceRate/100) * this.invoiceValue) + this.invoiceValue;
        },
        "importedServices": function(){
            return this.$store.state.generalTablePeriod.importedServicesTable;
        },
        "default_tax_val": function(){
            return (this.company.default_tax_object != null)? this.company.default_tax_object.tax : null;
        },
        "selectedInvoiceForEdit": function(){
            return {
                "invoice": this.$store.state.invoiceData.editInvoice , 
                "type": this.$store.state.invoiceData.typeEditInvoice
            };
        }
    },
    methods: {
        "total": function(invoice){
            // return (((invoice.rate/100) * invoice.value) + invoice.value).toFixed(3);
            return (invoice.tax + invoice.value).toFixed(3);
        },
        async print() {
            let target = $('#printImportedServicesTable').clone();
            $(target).attr('id', "clonePrinting");
            let title = "جدول الخدمات المستوردة";
            GlobalFunctions.print(target, this.company, this.period, title);
        },
    },
    updated: function(){
        let valueSum = this.sumImportedServices['valueSum'];
        let taxSum = this.sumImportedServices['taxSum'];

        if( valueSum != 0 && taxSum != 0 ){
            this.$store.commit('generalTablePeriod/setImportedServices', {'value': valueSum, 'tax': taxSum});
        }
    }
}
</script>