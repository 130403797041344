<template>
    <div class="my-5">
        <h2 class="py-2">تعديل بيانات الشركة</h2>

        <form class="w-75 m-auto add-form">
            <div class="d-flex justify-content-around">
                <div class="d-flex flex-column align-items-start mx-2 w-25">
                    <label for="editCompanyName">إسم الشركة</label>
                    <input type="text" class="form-control" id="editCompanyName" v-model="companyName" placeholder="إسم الشركة">
                </div>
                <div class="d-flex flex-column align-items-start mx-2 w-25">
                    <label for="editCompanyAuth">المفوّض بالتوقيع</label>
                    <input type="text" class="form-control"  id="editCompanyAuth" v-model="authSign" placeholder="المفوّض بالتوقيع">
                </div>
                <div class="d-flex flex-column align-items-start mx-2 w-25">
                    <label for="editCompanyPhone">رقم الهاتف</label>
                    <input type="text" class="form-control" id="editCompanyPhone" v-model="phone" placeholder="رقم الهاتف">
                </div>
            </div>
           
            <div class="d-flex justify-content-center my-2">
                <div class="d-flex flex-column align-items-start mx-2 w-25">
                    <label for="addCompanyDefaultTax">ضريبة افتراضية</label>
                    <select v-model="default_tax" id="addCompanyDefaultTax" class="form-select w-100">
                        <option value="" selected>---</option>
                        <option v-for="setting in settings" :key="setting.id" :value="setting.id">{{ setting.tax }}</option>
                    </select>
                </div>

                <div class="d-flex flex-column align-items-start w-25">
                    <label for="addCompanyNum">الرقم الضريبي</label>
                    <input type="text" class="form-control" v-model="taxNumber" id="addCompanyNum" placeholder="الرقم الضريبي">
                </div>

                <div class="d-flex flex-column align-items-start mx-2 w-25">
                    <label for="directoryForCompany">المديرية</label>
                    <select v-model="directorate" class="form-control" id="directoryForCompany">
                        <option value="" selected>---</option>
                        <option>كبار المكلفين</option>
                        <option>شمال عمان</option>
                        <option>شرق عمان</option>
                        <option>غرب عمان</option>
                        <option>غرب عمان</option>
                        <option>تجاري أولى</option>
                        <option>تجاري ثاني</option>
                        <option>أصحاب المهن</option>
                        <option>أخرى</option>
                    </select>                
                </div>
            </div>

            <div class="d-flex justify-content-center my-2">
                <div class="d-flex flex-column align-items-start w-25 mx-2">
                    <label for="dealingDate">تاريخ بدء التعامل</label>
                    <input type="text" id="dealingDate" v-model="dealingDate" class="form-control p-xxl-1 p-0" placeholder="اليوم - الشهر - السنة">
                </div>

                <div class="d-flex flex-column align-items-start w-25 mx-2">
                     <div>
                        <label for="editEndorsmentDate">تعديل تاريخ بدء الاقرارات</label>
                        <input type="checkbox" id="editEndorsmentDate" v-model="editEndorsmentDate">
                    </div>
                    
                    <div class="d-flex flex-column align-items-start w-100" v-if="editEndorsmentDate">
                        <input type="text" id="endorsmentDate" v-model="endorsmentDate" class="form-control p-xxl-1 p-0" placeholder="اليوم - الشهر - السنة">
                    </div>
                </div>
            </div>

            <button type="button" @click="editCompany" class="btn btn-success">تعديل</button>
        </form>
    </div>
</template>

<style scoped>
    .add-form{
        background: #fbfbfb;
        padding: 14px;
        border-radius: 13px;
        box-shadow: 0px 0px 19px #f5f5f5;
    }
</style>

<script>
import config from "../../../public/js/config";
import alertFunctions from "../../../public/js/alertFunctions.js";

export default {
    name: "EditCompany",
    data(){
        return {
            company : {},
            editEndorsmentDate: false,
            companyName: "",
            taxNumber: "",
            authSign: "",
            phone: "",
            directorate: "",
            dealingDate: "",
            endorsmentDate: "",
            default_tax: "",
            settings: []
        }
    },

    watch: {
        "dealingDate": function(){
            this.dealingDate+= this.dealingDate.length == 2 ? "-" : "";
            this.dealingDate+= this.dealingDate.length == 5 ? "-" : "";
            this.dealingDate = this.dealingDate.replace('--', '-');
            this.dealingDate = (this.dealingDate.length > 10)? this.dealingDate.slice(0,10) : this.dealingDate;
        },
        "endorsmentDate": function(){
            this.endorsmentDate+= this.endorsmentDate.length == 2 ? "-" : "";
            this.endorsmentDate+= this.endorsmentDate.length == 5 ? "-" : "";
            this.endorsmentDate = this.endorsmentDate.replace('--', '-');
            this.endorsmentDate = (this.endorsmentDate.length > 10)? this.endorsmentDate.slice(0,10) : this.endorsmentDate;
        },
        "editEndorsmentDate": function(){
            if(this.editEndorsmentDate){
                alert('انتبه... التعديل على تاريخ بدء الاقرارات سيتم اعادة انشاء الفترات للشركة وحذف ما تم ادخاله مسبقاً !');
            }
        }
    },
    methods:{
        "editCompany": function(){
            var user = this.$store.state.auth.user;
            let formData = {
                "name": this.companyName,
                "taxNumber": this.taxNumber,
                "auth_signatory": this.authSign,
                "phone": this.phone,
                "directorate": this.directorate,
                "dealing_date": this.dealingDate,
                "endorsment_date": this.endorsmentDate,
                "default_tax": this.default_tax,
                "editEndorsmentDate": this.editEndorsmentDate,
            }

            let url = config.api_url() + 'companies/'+ this.company.id+ "?user="+user['id'];
            this.axios.put(url, formData, 
                {headers: {"Authorization": "Bearer " +user['token'], "Accept": "application/json", "Content-Type": "application/json"}})
                .then((response) => {
                    if( response.status == 200 ){
                        if(response.data.status == true){
                            this.$swal({
                                position: 'top-start',
                                icon: 'success',
                                title: 'تم حفظ التعديلات بنجاح على الشركة',
                                confirmButtonText: 'رجوع',
                                showConfirmButton: true,
                                timer: 5000
                            }).then((result) => {
                                if(result.isConfirmed){
                                    this.$router.go(-1);
                                }
                            });
                        }
                        else{
                            let errors = Object.values(response.data.response);
                            let result = "";
                            errors.forEach(error => {
                                result+= error + "\n";
                            });
                            alertFunctions.ErrorAlert(result);
                        }
                    }
                })
                .catch(() => {
                    console.log('error');
                });
        },
        "reverseString": function(str){
            var splitString = str.split("-");
            var reverseArray = splitString.reverse();
            var joinArray = reverseArray.join("-");
            return joinArray;
        }
    },
    created: function(){
        let id = this.$route.params.id;
        let user = this.$store.state.auth.user;
        let query = "user="+user['id'] + "&settings=1";    // settings=1  => mean with settings

        this.axios.get(config.api_url() + 'companies/'+id + "?" + query, {headers: {"Authorization": "Bearer " + user['token']}})
            .then((response) => {
                if( response.status == 200 ){
                    this.company = response.data.company;
                    this.companyName = this.company.name;
                    this.taxNumber = this.company.taxNumber;
                    this.authSign = this.company.auth_signatory;
                    this.phone = this.company.phone;
                    this.directorate = this.company.directorate;
                    this.dealingDate = this.reverseString(this.company.dealing_date);
                    this.endorsmentDate = this.reverseString(this.company.endorsment_date);
                    this.default_tax = this.company.default_tax;
                    this.settings = response.data.settings;
                }
            })
            .catch(() => {
                console.error('error');
            });  
    },
}
</script>
