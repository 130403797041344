<template>
    <div class="container my-4">
        <template v-if="user != null">
            <h4>تعديل بيانات المستخدم</h4>
            
            <form>
                <div class="row m-auto my-1">
                    <div class="col d-flex flex-column align-items-start">
                        <label for="username">إسم المستخدم</label>
                        <input type="text" id="username" class="form-control" v-model="user.name" placeholder="إسم المستخدم">
                    </div>
                    
                    <div class="col d-flex flex-column align-items-start">
                        <label for="useremail">البريد الإلكتروني</label>
                        <input type="text" id="useremail" class="form-control" v-model="user.email" placeholder="البريد الإلكتروني">
                    </div>

                    <div class="col d-flex flex-column align-items-start">
                        <label for="main_user_id">حساب فرعي من</label>
                        <select class="form-control" id="main_user_id" v-model="user.main_user_id">
                            <option>حساب رئيسي</option>
                            <option v-for="user in users" :key="user.id" :value="user.id">{{ user.name }}</option>
                        </select>
                    </div>

                </div>
            
                <button @click.prevent="editUser" class="btn btn-success">تعديل</button>
            </form>

            <hr style="color: #ccc;">
            <div class="mt-5">
                <h4>تغيير كلمة المرور</h4>
                <form>
                    <div class="row m-auto my-1">
                        <div class="col d-flex flex-column align-items-start">
                            <label for="password">كلمة المرور الجديدة</label>
                            <input type="password" id="password" class="form-control" v-model="password" placeholder="كلمة المرور الجديدة">
                            <div v-if="v$.password.$error" class="text-danger">
                                {{ v$.password.$errors[0].$message }}
                            </div>
                        </div>
                        
                        <div class="col d-flex flex-column align-items-start">
                            <label for="confirmedPassword">تأكيد كلمة المرور</label>
                            <input type="password" class="form-control" id="confirmedPassword" v-model="confirmedPassword" placeholder="تأكيد كلمة المرور" >
                            <div v-if="v$.confirmedPassword.$error" class="text-danger">
                                {{ v$.confirmedPassword.$errors[0].$message }}
                            </div>
                        </div>
                    </div>
                
                    <button @click.prevent="changePassword" class="btn btn-success">تغيير</button>
                </form>
            </div>

            <hr style="color: #ccc;">
            <div class="mt-5">
                <h4>الحسابات الفرعية</h4>
                <div>
                    <ul v-if="user.sub_users.length > 0">
                        <li v-for="subUser in user.sub_users" :key="subUser.id" class="text-end">
                            <router-link :to="'/dashboard/users/'+ subUser.id">{{ subUser.name }}</router-link>
                        </li>
                    </ul>
                    <p v-else>لا يوجد حسابات فرعية ...</p>
                </div>
            </div>
        </template>
        <template v-else>
            <img src="/images/loader.gif" alt="loader image" >
        </template>
    </div>
</template>

<style scoped>
.editUserLayout{
    position: absolute;
    top:20vh;
    left: 25%;
    width: 50%;
    background: white;
    display: none;
}
</style>

<script>
import axios from "axios";
import config from "../../../../public/js/config";
import alertFunctions from "../../../../public/js/alertFunctions.js";
import useVuelidate from '@vuelidate/core';
import { required, sameAs, minLength } from '@vuelidate/validators';

export default {
    name: "editUserTemplate",
    data(){
        return {
            v$: useVuelidate(),
            id: this.$route.params.id,
            user: null,
            users: [],

            password: "",
            confirmedPassword: "",
        }
    },
    validations () {
        return {
            password: {
                required,
                minLength: minLength(8)
            },
            confirmedPassword: {
                sameAsPassword: sameAs(this.password),
            },
        }
    },
    methods: {
        "editUser": function(){
            let admin = JSON.parse(window.localStorage.getItem('admin'));

            axios.put(config.api_url() + 'dashboard/users/'+this.id, this.user, {headers: {"admin-token": admin.token} })
                .then((response) => {
                    if(response.status == 200){
                        console.log(response.data);
                        if(response.data.status){
                            alertFunctions.SuccessAlert("تم التعديل على المستخدم بنجاح");                        
                        }
                        else{
                            let errors = "";
                            for (const key in response.data['data']) {
                                const message = response.data['data'][key];
                                errors+= message +"\n";
                            }

                            alertFunctions.ErrorAlert(errors);
                        }
                    }
                })
                .catch(() => {
                    console.log('error');
                });
        },
        "changePassword": function(){
            let admin = JSON.parse(window.localStorage.getItem('admin'));
            
            this.v$.$validate();
            if (!this.v$.$invalid) {
                let formData = new FormData();
                formData.append('password', this.password );
                formData.append('confirmedPassword', this.confirmedPassword );

                axios.post(config.api_url() + 'dashboard/change-user-password/'+this.id, formData, {headers: {"admin-token": admin.token} })
                    .then((response) => {
                        if(response.status == 200){
                            if(response.data.status){
                                alertFunctions.SuccessAlert("تم تغيير كلمة المرور بنجاح");                        
                                this.password = "";
                                this.confirmedPassword = "";
                            }
                            else{
                                alertFunctions.ErrorAlert(response.data.message);
                            }
                        }
                    })
                    .catch(() => {
                        console.log('error');
                    });
            }
        }
    },
    created: function(){
        let admin = this.$store.state.auth.admin;
        axios.get(config.api_url() + 'dashboard/users/'+this.id, {headers: {"admin-token": admin.token} })
            .then((response) => {
                if(response.status == 200){
                    this.user = response.data.user;
                    this.users = response.data.users;
                }
            })
            .catch(() => {
                console.log('error');
            });
    },
}
</script>    
    
