<template>
    <div class="d-flex flex-column mt-2 print-section" dir="rtl">
        <div class="d-flex justify-content-between">
            <h4>جدول تحليل {{ typeTxt }}</h4>

            <button type="button" class="btn btn-outline-primary btn-sm mb-1 dsip" @click.prevent="print">
                طباعة <b-icon-printer-fill></b-icon-printer-fill>
            </button>
        </div>

        <div :id="`print${typeAsId}AnalysisTable`" dir="rtl">
            <div>   
                <table class="table table-striped table-bordered">
                    <thead>
                        <tr>
                            <th>النسبة</th>
                            <th>القيمة</th>
                            <th>الضريبة</th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr v-for="analysisItem in analysis.keys()" :key="analysisItem">
                            <td>{{ analysisItem }}</td>
                            <td>{{ analysis.get(analysisItem)['value'].toFixed(3) }}</td>
                            <td>{{ (isNaN(analysis.get(analysisItem)['tax']) || analysis.get(analysisItem)['tax'] == null)? '--' : analysis.get(analysisItem)['tax'].toFixed(3) }}</td>
                        </tr>
                    </tbody>

                    <tfoot>
                        <tr>
                            <td>المجموع</td>
                            <td>{{ valueSum }}</td>
                            <td>{{ taxesSum }}</td>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </div>
    </div>
</template>

<style scoped>
    thead {
        background-image: linear-gradient(120deg, #a1c4fd 0%, #c2e9fb 100%);
    }
    tfoot {
        background-image: linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%);
    }

    .header-background{ background-color: #e9e9e9;}
    .footer-background{ background-color: #e9e9e9;}
</style>

<script>
import $ from 'jquery';
import GlobalFunctions from "../../../public/js/globalFunctions";

export default {
    name: "AnalysisTable",
    props: ["invoices", "invoicesType", "company", "period"],
    data(){
        return {
            analysis: new Map(),
        }
    },
    computed: {
        "typeTxt": function(){
            switch(this.invoicesType) {
                case 'sales': return 'المبيعات';
                case 'purchases+outlays': return 'المشتريات + المصاريف';
                case 'custards': return 'المستوردات';
            }
        },
        "typeAsId": function() {
            return this.invoicesType.replaceAll('+', '');
        },
        "valueSum": function(){
            let valueSum = 0;
            this.analysis.forEach((index, analysisItem) => {
                valueSum+= this.analysis.get(analysisItem)['value'];
            });
            return parseFloat(valueSum).toFixed(3);
        },
        "taxesSum": function(){
            let taxesSum = 0;
            this.analysis.forEach((index, analysisItem) => {
                taxesSum+= this.analysis.get(analysisItem)['tax'];
            });
            return parseFloat(taxesSum).toFixed(3);
        },
    },
    methods: {
        async print() {
            let target = $(`#print${this.typeAsId}AnalysisTable`).clone();
            $(target).attr('id', "clonePrinting");
            let title = "جدول تحليل "+ this.typeTxt;
            GlobalFunctions.print(target, this.company, this.period, title);
        },
    },
    created: function(){
        this.invoices.forEach(item => {
            let invoice = item.invoice;
            let key = invoice.rate + ' %';
            let content = {"tax": invoice.tax, 'value': invoice.value};

            if(this.invoicesType == 'sales') {
                if(item.sales_type != null) {
                    key = (item.sales_type == 'zero')? 'صفرية' : 'معفاة';
                }
            }
            if(this.invoicesType == 'purchases+outlays') {
                if(item.purchases_type != null) {
                    key = (item.purchases_type == 'zero')? 'صفرية' : 'معفاة';
                }
            }
            if(this.invoicesType == 'custards') {
                if(item.custards_type != null) {
                    key = (item.custards_type == 'zero')? 'صفرية' : 'معفاة';
                }
            }

            if( this.analysis.has(key) ) {
                let oldContent = this.analysis.get(key);
                let value = parseFloat(oldContent['value']) + parseFloat(invoice.value);
                let tax = parseFloat(oldContent['tax']) +  parseFloat(invoice.tax);
                content = {"tax": tax, 'value': value};
            }

            this.analysis.set(key, content);
        });
    }
}
</script>