<template>
    <div>
        <div class="my-2">
            <div class="filter-layout">
                <h4 style="text-align:right; my-2">ابحث عن شركة معينة</h4>
                <div class="d-flex flex-row justify-content-around ">

                    <div class="w-25 d-flex flex-column align-items-start">
                        <label for="filterCompanyName">إسم الشركة</label>
                        <input type="text" class="form-control" v-model="filterCompanyName" id="filterCompanyName" placeholder="إسم الشركة">
                    </div>

                    <div class="w-25 d-flex flex-column align-items-start">
                        <label for="filterTaxNumber">الرقم الضريبي</label>
                        <input type="text" class="form-control" v-model="filterTaxNumber" id="filterTaxNumber" placeholder="الرقم الضريبي">
                    </div>

                    <div class="w-25 d-flex flex-column align-items-start">
                        <label for="filterPhone">رقم الهاتف</label>
                        <input type="text" class="form-control" v-model="filterPhone" id="filterPhone" placeholder="رقم الهاتف">
                    </div>

                    <div class="d-flex flex-column align-items-start">
                        <label for="filterPeriodType">نوع الفترة</label>

                        <div class="btn-group">
                            <button class="btn border" :class="(filterPeriodType['even'])? 'btn-secondary': ''" @click.prevent="filterPeriodType['even']= !filterPeriodType['even']">زوجي 1+2</button>
                            <button class="btn border" :class="(filterPeriodType['odd'])? 'btn-secondary': ''" @click.prevent="filterPeriodType['odd']= !filterPeriodType['odd']">فردي 12+1</button>
                        </div>

                        <!-- <select v-model="filterPeriodType" class="form-select" id="filterPeriodType">
                            <option value="" selected>---</option>
                            <option value="even">زوجي 1+2</option>
                            <option value="odd">فردي 12+1</option>
                        </select> -->
                    </div>
                </div>
                <button type="button" class="btn btn-primary rounded-pill m-2" @click="filterCompanies">
                    بحث <b-icon-search></b-icon-search>
                </button>

                <button type="button" class="btn btn-warning rounded-pill m-2" @click="resetFilters">
                    إعادة ضبط <b-icon-arrow-counterclockwise></b-icon-arrow-counterclockwise>
                </button>
            </div>

        </div>
    </div>
</template>

<style scoped>
    .filter-layout{
        width: 85%;
        margin: 10px auto;
        background: #fbfbfb;
        padding: 25px;
    }
</style>

<script>
import config from "../../../public/js/config";

export default {
    name: "FilterCompanies",
    data(){
        return {
            type: this.$route.params.type,
            filterCompanyName: "",
            filterTaxNumber: "",
            filterPhone: "",
            filterPeriodType: {'odd': true, 'even': true},
        }
    },
    methods: {
        "filterCompanies": function() {
            var user = this.$store.state.auth.user;
            let periodType = (this.filterPeriodType['odd'] + 0) + '' +  (this.filterPeriodType['even'] + 0);
            let query = "companyName="+ this.filterCompanyName;
            query += "&companyTaxNumber="+ this.filterTaxNumber;
            query += "&companyPhone="+ this.filterPhone;
            query += "&periodType="+ periodType;
            query += "&type="+ this.type;
            query += "&user="+ user['id'];

            this.axios.get(config.api_url() + 'companies?'+query, 
                    {headers: {"Authorization": "Bearer " + user['token']}} )
                .then((response) => {
                    if( response.status == 200 ){
                        this.$store.commit('companies/setFilteredCompanies', response.data.companies);
                    }
                })
                .catch(() => {
                    console.log('error');
                });
        },
        "resetFilters": function(){
            this.filterCompanyName = "";
            this.filterTaxNumber = "";
            this.filterPhone = "";
            this.filterPeriodType = {'odd': true, 'even': true};

            this.filterCompanies();
        }
    },
}
</script>
