<template>
    <div>
        <h1>الاعدادات</h1>

        <table class="table table-bordered table-hover table-stripped w-50 m-auto">
            <thead>
                <tr>
                    <th>#</th>
                    <th>الضريبة</th>
                    <th>اجراءات</th>
                </tr>
            </thead>

            <tbody>
                <tr v-for="(setting,index) in settings" :key="setting.id" >
                    <td>{{ index+1 }}</td>
                    <td>{{ setting.tax }} %</td>
                    <td>
                        <div class="form-check form-switch w-50 m-auto">
                            <input class="form-check-input" type="checkbox" :id="setting.id+'switch'" @click="changeActivate(setting.id)" :checked="setting.active" >
                            <label class="form-check-label" :for="setting.id+'switch'">تفعيل / الغاء التفعيل</label>
                        </div>
                    </td>
                </tr>

                <tr>
                    <td colspan="3">
                        <div class="d-flex w-50 m-auto">
                            <input type="text" id="taxRatio" v-model="newTaxValue" class="form-control w-75 mx-2" placeholder="إضافة ضريبة جديدة">
                            <button class="btn btn-success" type="button" @click="addNewTax">اضافة</button>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
        
    </div>
</template>

<style scoped>
    thead {
        background-image: linear-gradient(120deg, #a1c4fd 0%, #c2e9fb 100%);
    }
    tfoot {
        background-image: linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%);
    }
</style>

<script>
import config from "../../public/js/config.js";

export default {
    name: "Settings",
    data(){
        return {
            settings: [],
            newTaxValue: null,
        }
    },
    methods: {
        "addNewTax": function(){
            let user = this.$store.state.auth.user;
            this.axios.post(config.api_url() + 'settings/'+user['id'], {"tax": this.newTaxValue} , {headers: {"Authorization": "Bearer "+ user['token']} })
                .then((response) => {
                    if( response.status == 200 ){
                        // console.log(response.data);
                        this.newTaxValue = "";
                        this.settings.push(response.data.setting);
                    }
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        alert('تم انتهاء جلسة الدخول , أعد تسجبل الدخول .');
                        window.location.href = "/logout";
                    }
                    console.log(error);
                });
        },
        "changeActivate": function(id){
            let token = this.$store.state.auth.user['token'];
            let isChecked = document.getElementById(id + 'switch').checked;

            this.axios.post(config.api_url() + 'settings/activate/'+id, {"active": isChecked} , {headers: {"Authorization": "Bearer "+ token} })
            .then((response) => {
                if( response.status == 200 ){
                    // console.log(response.data);
                }
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    alert('تم انتهاء جلسة الدخول , أعد تسجبل الدخول .');
                    window.location.href = "/logout";
                }
                console.log(error);
            });
        },
    },
    created: function(){
        let user = this.$store.state.auth.user;
        this.axios.get(config.api_url() + 'settings/'+user['id'], {headers: {"Authorization": "Bearer "+ user['token']} })
        .then((response) => {
            if( response.status == 200 ){
                this.settings = response.data.settings;
            }
        })
        .catch((error) => {
            if (error.response.status === 401) {
                alert('تم انتهاء جلسة الدخول , أعد تسجبل الدخول .');
                window.location.href = "/logout";
            }
            console.log(error);
        });
    }
}
</script>