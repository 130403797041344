<template>
    <div>
        <template v-if="company.deleted_at == null">
            <div class="d-flex justify-content-evenly dsip">
                <ImportFromExcel :invoicesType="'sales'" :period_id="period.id" class="dsip"/>

                <template v-if="period != null">
                    <FilesForm :period="period" :section="'sales'" />
                </template>
            </div>
        </template>

        <button type="button" class="btn btn-primary btn-sm mb-1 dsip" @click.prevent="print">
            طباعة <b-icon-printer-fill></b-icon-printer-fill>
        </button>

        <div id="printSalesTable" class="print-section" dir="rtl">
            <table class="table table-striped table-bordered">
                <thead>
                    <tr>
                        <th>رقم الفاتورة</th>
                        <th>التاريخ</th>
                        <th>النسبة</th>
                        <th>القيمة</th>
                        <th>الضريبة</th>
                        <th>الإجمالي</th>
                        <th class="dsip"></th>
                    </tr>
                </thead>

                <tbody>
                    <template v-for="sale in sales" :key="sale.id" :data-invoiceId="sale.invoice.id">
                        <tr v-if="selectedInvoiceForEdit.invoice?.invoice.id != sale.invoice.id">
                            <td>
                                {{ sale.invoice.number }}
                            </td>
                            <td style="font-size: 14px;">
                                {{ sale.invoice.date }}
                            </td>
                            <td>
                                {{ 
                                    (sale.sales_type == null)? 
                                        sale.invoice.rate + '%' : 
                                        ( (sale.sales_type == 'exempt')? 'معفاة' : 'صفرية' )
                                }}
                            </td>
                            <td>
                                {{ sale.invoice.value.toFixed(3) }}
                            </td>
                            <td>
                                {{ (sale.sales_type == null)? sale.invoice.tax : '-' }}
                            </td>
                            <td>
                                {{ total(sale.invoice) }}
                            </td>
                            <td class="d-flex dsip" v-if="company.deleted_at == null">
                                <button class="btn btn-success btn-sm rounded-circle mx-1" 
                                        @click="this.$store.commit('invoiceData/setEditInvoice', {invoice: sale, type:'sales'})">
                                    <b-icon-pencil-square></b-icon-pencil-square>
                                </button>
                                <button class="btn btn-danger btn-sm rounded-circle" 
                                        @click.prevent="this.$store.dispatch('invoiceData/confirmDeleteInvoice', {invoice: sale.invoice, periodId: period.id, type:'sales'})">
                                    <b-icon-trash></b-icon-trash>
                                </button>
                            </td>
                        </tr>

                        <template v-else>
                            <EditInvoiceWithTax :period_id="period.id"/>
                        </template>
                    </template>

                    <template v-if="company.deleted_at == null">
                        <AddInvoiceWithTax :period_id="period.id" :invoiceType="'sales'" :default_tax="default_tax_val" class="dsip" />
                    </template>
                </tbody>

                <tfoot>
                    <tr>
                        <td colspan="3">المجموع : </td>
                        <td>{{ parseFloat(sumSales['valueSum']).toFixed(3) }}</td>
                        <td>{{ parseFloat(sumSales['taxSum']).toFixed(3) }}</td>
                        <td colspan="2"></td>
                    </tr>
                </tfoot>
            </table>
        </div>

        <AnalysisTable :invoices="sales" :invoicesType="'sales'" :company="company" :period="period" :key="Math.random(10)" />
    </div>
</template>

<style scoped>
    thead {
        background-image: linear-gradient(120deg, #a1c4fd 0%, #c2e9fb 100%);
    }
    tfoot {
        background-image: linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%);
    }
    
    .header-background{ background-color: #e9e9e9;}
    .footer-background{ background-color: #e9e9e9;}
</style>

<script>
import AddInvoiceWithTax from "../invoices/addInvoiceWithTax.vue";
import ImportFromExcel from "../invoices/importFromExcel.vue";
import EditInvoiceWithTax from "../invoices/editInvoiceWithTax.vue";
import AnalysisTable from "../endorsment/analysisTable.vue";
import FilesForm from "../endorsment/filesForm.vue";
import GlobalFunctions from "../../../public/js/globalFunctions";
import $ from 'jquery';

export default {
    name: "SalesTable",
    props: ["period", "company"],
    data(){
        return {}
    },
    components: {
        AddInvoiceWithTax,
        ImportFromExcel,
        EditInvoiceWithTax,
        AnalysisTable,
        FilesForm
    },
    computed: {
        'sumSales': function(){
            let taxesSum = 0, valuesSum = 0, ratesSum = 0;
            this.sales.forEach(element => {
                taxesSum+= element.invoice.tax;
                valuesSum+= element.invoice.value;
                ratesSum+= element.invoice.rate;
            });
            return {"taxSum": taxesSum, "valueSum": valuesSum, "rateSum": ratesSum};
        },
        "sales": function(){
            return this.$store.state.generalTablePeriod.salesTable;
        },
        "default_tax_val": function(){
            return (this.company.default_tax_object != null)? this.company.default_tax_object.tax : null;
        },
        "selectedInvoiceForEdit": function(){
            return {
                "invoice": this.$store.state.invoiceData.editInvoice , 
                "type": this.$store.state.invoiceData.typeEditInvoice
            };
        }
    },
    methods: {
        "total": function(invoice){
            return (((invoice.rate/100) * invoice.value) + invoice.value).toFixed(3);
        },
        async print() {
            let target = $('#printSalesTable').clone();
            $(target).attr('id', "clonePrinting");
            let title = "جدول المبيعات";
            GlobalFunctions.print(target, this.company, this.period, title);
        },
    },
    updated: function(){
        let valueSum = this.sumSales['valueSum'];
        let taxSum = this.sumSales['taxSum'];

        if( valueSum != 0 && taxSum != 0 ){
            this.$store.commit('generalTablePeriod/setSales', {'value': valueSum, 'tax': taxSum});
        }
    }
}
</script>