import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import boostrap from 'bootstrap';
import "bootstrap/dist/css/bootstrap.min.css";
import { BootstrapIconsPlugin } from 'bootstrap-icons-vue';

import axios from 'axios'
import VueAxios from 'vue-axios'

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

// import Vuelidate from 'vuelidate';
import { VuelidatePlugin } from '@vuelidate/core'

createApp(App)
    .use(store)
    .use(router)
    .use(boostrap)
    .use(BootstrapIconsPlugin)
    .use(VueAxios, axios)
    .use(VuelidatePlugin)
    .use(VueSweetalert2)
    .mount("#app");
