<template>
    <div>
        <div class="print-section">
            <h4 class="text-end mt-3">جدول المشتريات</h4>
            <template v-if="company.deleted_at == null">
                <div class="d-flex justify-content-evenly dsip">
                    <ImportFromExcel :invoicesType="'purchases'" :period_id="period.id" class="dsip"/>

                    <template v-if="period != null">
                        <FilesForm :period="period" :section="'purchases'" />
                    </template>
                </div>
            </template>
            

            <button type="button" class="btn btn-primary btn-sm mb-1 dsip" @click.prevent="printPurchases">
                طباعة <b-icon-printer-fill></b-icon-printer-fill>
            </button>

            <div id="printPurchasesTable" dir="rtl">
                <table class="table table-striped table-bordered">
                    <thead>
                        <tr>
                            <th>رقم الفاتورة</th>
                            <th>التاريخ</th>
                            <th>النسبة</th>
                            <th>القيمة</th>
                            <th>الضريبة</th>
                            <th>الإجمالي</th>
                            <th class="dsip"></th>
                        </tr>
                    </thead>

                    <tbody>
                        <template v-for="pruchase in purchases" :key="pruchase.id" :data-invoiceId="pruchase.invoice.id">
                            <tr v-if="selectedInvoiceForEdit.invoice?.invoice.id != pruchase.invoice.id" >
                                <td>
                                    {{ pruchase.invoice.number }}
                                </td>
                                <td style="font-size: 14px;">
                                    {{ pruchase.invoice.date }}
                                </td>
                                <td>
                                    {{ 
                                        (pruchase.purchases_type == null)? 
                                            pruchase.invoice.rate + '%' : 
                                            ( (pruchase.purchases_type == 'exempt')? 'معفاة' : 'صفرية' )
                                    }}
                                </td>
                                
                                <td>
                                    {{ pruchase.invoice.value.toFixed(3) }}
                                </td>
                                <td>
                                    {{ (pruchase.purchases_type == null)? pruchase.invoice.tax.toFixed(3) : '-' }}
                                </td>
                                <td>
                                    {{ total(pruchase.invoice) }}
                                </td>
                                <td class="d-flex dsip" v-if="company.deleted_at == null">
                                    <button class="btn btn-success btn-sm rounded-circle mx-1" 
                                            @click="this.$store.commit('invoiceData/setEditInvoice', {'invoice': pruchase, 'type': 'purchases'})">
                                        <b-icon-pencil-square></b-icon-pencil-square>
                                    </button>
                                    <button class="btn btn-danger btn-sm rounded-circle" 
                                            @click.prevent="this.$store.dispatch('invoiceData/confirmDeleteInvoice', {invoice: pruchase.invoice, periodId: period.id, type:'purchases'} )">
                                        <b-icon-trash></b-icon-trash>
                                    </button>
                                </td>
                            </tr>
                            <template v-else>
                                <EditInvoiceWithTax :period_id="period.id" />
                            </template>
                        </template>


                        <template v-if="company.deleted_at == null">
                            <AddInvoiceWithTax :period_id="period.id" :invoiceType="'purchases'" :default_tax="default_tax_val" class="dsip" />
                        </template>
                    </tbody>

                    <tfoot>
                        <tr>
                            <td colspan="3">المجموع : </td>
                            <td>{{ parseFloat(sumPurchases['valueSum']).toFixed(3) }}</td>
                            <td>{{ parseFloat(sumPurchases['taxSum']).toFixed(3) }}</td>
                            <td colspan="2"></td>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </div>
       
        <!-- ################# Another Table Section ################# -->
        <hr>
        <div class="print-section">
            <h4 class="text-end mt-3">جدول المصاريف</h4>
            
            <template v-if="company.deleted_at == null">
                <div class="d-flex justify-content-evenly">
                    <ImportFromExcel :invoicesType="'outlays'" :period_id="period.id" class="dsip"/>

                    <template v-if="period != null">
                        <FilesForm :period="period" :section="'outlays'" />
                    </template>
                </div>
            </template>

            <button type="button" class="btn btn-primary btn-sm mb-1 dsip" @click.prevent="printOutlays">
                طباعة <b-icon-printer-fill></b-icon-printer-fill>
            </button>

            <div id="printOutlaysTable" dir="rtl">
                <table class="table table-striped table-bordered">
                    <thead>
                        <tr>
                            <th>رقم الفاتورة</th>
                            <th>التاريخ</th>
                            <th>النسبة</th>
                            <th>القيمة</th>
                            <th>الضريبة</th>
                            <th>الإجمالي</th>
                            <th class="dsip"></th>
                        </tr>
                    </thead>

                    <tbody>
                        <template v-for="outlay in outlays" :key="outlay.id" :data-invoiceId="outlay.invoice.id">
                            <tr v-if="selectedInvoiceForEdit.invoice?.invoice.id != outlay.invoice.id">
                                <td>
                                    {{ outlay.invoice.number }}
                                </td>
                                <td style="font-size: 14px;">
                                    {{ outlay.invoice.date }}
                                </td>
                                <td>
                                    {{ outlay.invoice.rate + '%' }}
                                </td>
                                <td>
                                    {{ outlay.invoice.value.toFixed(3) }}
                                </td>
                                <td>
                                    {{ outlay.invoice.tax.toFixed(3) }}
                                </td>
                                <td>
                                    {{ total(outlay.invoice) }}
                                </td>
                                <td class="d-flex dsip" v-if="company.deleted_at == null">
                                    <button class="btn btn-success btn-sm rounded-circle mx-1" 
                                                @click="this.$store.commit('invoiceData/setEditInvoice', {'invoice': outlay, 'type': 'outlays'})">
                                            <b-icon-pencil-square></b-icon-pencil-square>
                                    </button>
                                    <button class="btn btn-danger btn-sm rounded-circle" 
                                            @click.prevent="this.$store.dispatch('invoiceData/confirmDeleteInvoice',{invoice: outlay.invoice, periodId: period.id, type:'outlays'})">
                                        <b-icon-trash></b-icon-trash>
                                    </button>
                                </td>
                            </tr>

                            <template v-else>
                                <EditInvoiceWithTax :period_id="period.id"/>
                            </template>
                        </template>


                        <template v-if="company.deleted_at == null">
                            <AddInvoiceWithTax :period_id="period.id" :invoiceType="'outlays'" :default_tax="default_tax_val" class="dsip" />
                        </template>
                    </tbody>

                    <tfoot>
                        <tr>
                            <td colspan="3">المجموع : </td>
                            <td>{{ parseFloat(sumOutlays['valueSum']).toFixed(3) }}</td>
                            <td>{{ parseFloat(sumOutlays['taxSum']).toFixed(3) }}</td>
                            <td colspan="2"></td>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </div>

        <AnalysisTable :invoices="[...purchases , ...outlays]" :invoicesType="'purchases+outlays'" :period="period" :company="company" :key="Math.random(10)" />

    </div>
</template>

<style scoped>
    thead {
        background-image: linear-gradient(120deg, #a1c4fd 0%, #c2e9fb 100%);
    }
    tfoot {
        background-image: linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%);
    }

    .header-background{ background-color: #e9e9e9;}
    .footer-background{ background-color: #e9e9e9;}
</style>

<script>
import AddInvoiceWithTax from "../invoices/addInvoiceWithTax.vue";
import ImportFromExcel from "../invoices/importFromExcel.vue";
import EditInvoiceWithTax from "../invoices/editInvoiceWithTax.vue";
import AnalysisTable from "../endorsment/analysisTable.vue";
import GlobalFunctions from "../../../public/js/globalFunctions";
import FilesForm from "../endorsment/filesForm.vue";
import $ from 'jquery';

export default {
    name: "PurchasesTables",
    props: ["period", "company"],
    components: {
        AddInvoiceWithTax,
        ImportFromExcel,
        EditInvoiceWithTax,
        AnalysisTable,
        FilesForm
    },
    computed: {
        'sumPurchases': function(){
            let taxesSum = 0, valuesSum = 0, ratesSum = 0;
            this.purchases.forEach(element => {
                taxesSum+= element.invoice.tax;
                valuesSum+= element.invoice.value;
                ratesSum+= element.invoice.rate;
            });
            return {"taxSum": taxesSum, "valueSum": valuesSum, "rateSum": ratesSum};
        },
        'sumOutlays': function(){
            let taxesSum = 0, valuesSum = 0, ratesSum = 0;
            this.outlays.forEach(element => {
                taxesSum+= element.invoice.tax;
                valuesSum+= element.invoice.value;
                ratesSum+= element.invoice.rate;
            });
            return {"taxSum": taxesSum, "valueSum": valuesSum, "rateSum": ratesSum};
        },
        "purchases": function(){
            return this.$store.state.generalTablePeriod.purchasesTable;
        },
        "outlays": function(){
            return this.$store.state.generalTablePeriod.outlaysTable;
        },
        "default_tax_val": function(){
            return (this.company.default_tax_object != null)? this.company.default_tax_object.tax : null;
        },
        "selectedInvoiceForEdit": function(){
            return {
                "invoice": this.$store.state.invoiceData.editInvoice , 
                "type": this.$store.state.invoiceData.typeEditInvoice
            };
        }
    },
    methods: {
        "total": function(invoice){
            return (((invoice.rate/100) * invoice.value) + invoice.value).toFixed(3);
        },
        async printPurchases() {
            let target = $('#printPurchasesTable').clone();
            $(target).attr('id', "clonePrinting");
            let title = "جدول المشتريات";
            GlobalFunctions.print(target, this.company, this.period, title);
        },
        async printOutlays() {
            let target = $('#printOutlaysTable').clone();
            $(target).attr('id', "clonePrinting");
            let title = "جدول المصاريف";
            GlobalFunctions.print(target, this.company, this.period, title);
        },
    },
    updated: function(){  
        this.$store.commit('generalTablePeriod/setPurchases', {'value': this.sumPurchases['valueSum'], 'tax': this.sumPurchases['taxSum']});
        this.$store.commit('generalTablePeriod/setOutlays', {'value': this.sumOutlays['valueSum'], 'tax': this.sumOutlays['taxSum']});
    },
}
</script>