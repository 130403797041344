<template dir="rtl" lang="ar">
  <!-- Author: Yazan Qwaider -->
  <!-- 2021/10/25 -->

  <div v-if="user != null && showNav == true">
    <NavBar />
  </div>

  <div v-if="admin != null">
    <DashboardNavBar />
  </div>
  

  <router-view :key="$route.path" />
</template>

<style lang="scss">
@font-face {
  font-family: 'times-san-serif';
  src: url('/fonts/Times-Sans-Serif-Regular.ttf') format('woff'), /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
   url('/fonts/Times-Sans-Serif-Regular.ttf') format('truetype'); /* Chrome 4+, Firefox 3.5, Opera 10+, Safari 3—5 */
}  

@import url('https://fonts.googleapis.com/css2?family=Tajawal&display=swap');

html{
  direction: rtl;
}

#app {
  font-family: 'Tajawal', Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>


<script> 
import NavBar from '@/components/layouts/navbar';
import DashboardNavBar from '@/components/dashboard/layouts/navbar';
import store from "@/store/index.js";
import config from "../public/js/config";
import $ from 'jquery';

export default {
  name: "App",
  store,
  computed: {
    user(){
      return this.$store.state.auth.user;
    },
    admin(){
      return this.$store.state.auth.admin;
    },
    showNav(){
      return this.$store.state.showNav;
    }
  },
  components: {
    NavBar,
    DashboardNavBar
  },
  created: function(){
      let user = this.$store.state.auth.user;
      let is_admin = window.localStorage.getItem('admin');

      if(user == null && is_admin == null){
        let user = window.localStorage.getItem('user');
        user = JSON.parse(user);
        this.$store.commit('auth/setUser', user);

        // if no user data exists, redirect to login page
        if( user == null ){
          if( window.location.pathname != "/login" && window.location.pathname != "/dashboard/login" ){
            window.location.href = "/login";
          }
        }
      }
      else if( is_admin != null ){
        let admin = window.localStorage.getItem('admin');
        admin = JSON.parse(admin);
        this.$store.commit('auth/setAdmin', admin);
      }

      // if no session-tab and the current page is not logout or login
      if( !sessionStorage.tabID && window.location.pathname != "/logout" && window.location.pathname != "/login"){
        // if user has old tabid in localStorage
        if(localStorage.getItem('tabID')){
          logout();
        }
        else{
          // if the user enter the first time, no old localStorge tabId, but exists user data
          sessionStorage.tabID = Date.now();
          localStorage.setItem('tabID', Date.now());
        }
      }
      else{
        // if enter to website again with new session-tab
        if(sessionStorage.tabID != localStorage.getItem('tabID')){
          logout();
        }
      }

      window.onbeforeunload = function(e) {
        localStorage.setItem('tabID', sessionStorage.tabID);
        return null;
      }

      // logout, remove from storage and store-management, and reload
      function logout(){
        let user = store.state.auth.user;
        if(user != null){
          var fd = new FormData();
          fd.append('id', user['id']);
          navigator.sendBeacon(config.api_url() + 'logout', fd);

          window.localStorage.removeItem('user');
          window.localStorage.removeItem('tabs');
          window.localStorage.removeItem('tabID');

          sessionStorage.removeItem('tabID');
          store.commit('auth/setUser', null);
          window.location.reload();
        }
      }

  },
}
</script>