<template>
    <div class="my-4">
        <h4>إضافة مستخدم جديد</h4>
        <hr style="color: #ccc;">
        
        <form>
            <div class="row m-auto my-1">
                <div class="col d-flex flex-column align-items-start">
                    <label for="username">إسم المستخدم</label>
                    <input type="text" id="username" class="form-control" v-model="username" placeholder="إسم المستخدم">
                </div>
                
                <div class="col d-flex flex-column align-items-start">
                    <label for="useremail">البريد الإلكتروني</label>
                    <input type="text" id="useremail" class="form-control" v-model="useremail" placeholder="البريد الإلكتروني">
                </div>

                <div class="col d-flex flex-column align-items-start">
                    <label for="username">كلمة المرور</label>
                    <input type="password" id="userpassword" class="form-control" v-model="userpassword" placeholder="كلمة المرور">
                </div>

                <div class="col d-flex flex-column align-items-start">
                    <label for="main_user_id">حساب فرعي من</label>
                    <select class="form-control" id="main_user_id" v-model="main_user_id">
                        <option value="">حساب رئيسي</option>
                        <option v-for="user in users" :key="user.id" :value="user.id">{{ user.name }}</option>
                    </select>
                </div>

            </div>
        
            <button @click.prevent="addNewUser" class="btn btn-success">إضافة</button>
        </form>
    </div>
</template>

<style scoped>
.editUserLayout{
    position: absolute;
    top:20vh;
    left: 25%;
    width: 50%;
    background: white;
    display: none;
}
</style>

<script>
import axios from "axios";
import config from "../../../../public/js/config";
import $ from "jquery";
import alertFunctions from "../../../../public/js/alertFunctions.js";

export default {
    name: "addUserTemplate",
    data(){
        return {
            username: null,
            useremail: null,
            userpassword: null,
            main_user_id: "",
        }
    },
    computed: {
        "users": function(){
            return this.$store.state.DashboardData.users;
        }
    },
    methods: {
        "addNewUser": function(){
            let admin = JSON.parse(window.localStorage.getItem('admin'));            
            let formData = new FormData();
            formData.append('name', $('#username').val() );
            formData.append('email', $('#useremail').val() );
            formData.append('password', $('#userpassword').val() );
            formData.append('main_user_id', this.main_user_id );


            axios.post(config.api_url() + 'dashboard/users', formData, {headers: {"admin-token": admin.token} })
                .then((response) => {
                    if(response.status == 200){
                        if(response.data.status){
                            alertFunctions.SuccessAlert("تم إضافة المستخدم بنجاح");

                            let arr = this.users;
                            arr.push(response.data['user']);
                            this.$store.commit('DashboardData/setUsers', arr );
     
                            this.username = "";
                            this.useremail = "";
                            this.userpassword = "";
                            this.main_user_id = null;
                        }
                        else{
                            let errors = "";
                            for (const key in response.data['data']) {
                                const message = response.data['data'][key];
                                errors+= message +"\n";
                            }

                            alertFunctions.ErrorAlert(errors);
                        }
                    }
                })
                .catch(() => {
                    alertFunctions.ErrorAlert("لقد حدثت مشكلة ما في عملية الاضافة !");
                });
        },
    },
}
</script>    
    
