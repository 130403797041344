import config from "./config";
import axios from 'axios';
import store from '../../src/store/index';
import printJS from 'print-js';
import $ from 'jquery';

export default {
    getEndorsmentData: function(periodId){
        let token = store.state.auth.user['token'];

        axios.get(config.api_url() +'periods/'+ periodId+'/endorsment', {headers: {"Authorization": "Bearer " + token}})
         .then((response) => {
             // console.log(response);
             if(response.status == 200){
                let endorsment = response.data.endorsment;

                store.commit('generalTablePeriod/setPurchases', JSON.parse(endorsment.purchases));
                store.commit('generalTablePeriod/setPurchasesDetails', JSON.parse(endorsment.purchases_details));
                store.commit('generalTablePeriod/setOutlays', JSON.parse(endorsment.outlays));
                store.commit('generalTablePeriod/setCustards', JSON.parse(endorsment.custards));
                store.commit('generalTablePeriod/setZerosCustards', JSON.parse(endorsment.zeros_custards));
                store.commit('generalTablePeriod/setExemptCustards', JSON.parse(endorsment.exempt_custards));
                store.commit('generalTablePeriod/setCustardsDetails', JSON.parse(endorsment.custards_details));
                store.commit('generalTablePeriod/setImportedServices', JSON.parse(endorsment.imported_services));
                store.commit('generalTablePeriod/setZerosPurchases', JSON.parse(endorsment.zeros_purchases));
                store.commit('generalTablePeriod/setExemptPurchases', JSON.parse(endorsment.exempt_purchases));
                store.commit('generalTablePeriod/setSales', JSON.parse(endorsment.sales));
                store.commit('generalTablePeriod/setSalesDetails', JSON.parse(endorsment.sales_details));
                store.commit('generalTablePeriod/setZerosSales', JSON.parse(endorsment.zeros_sales));
                store.commit('generalTablePeriod/setExportedSales', JSON.parse(endorsment.export_sales));
                store.commit('generalTablePeriod/setExemptSales', JSON.parse(endorsment.exempt_sales));
                store.commit('generalTablePeriod/setOldPeriodTax', JSON.parse(endorsment.old_period_tax));
                store.commit('generalTablePeriod/setEditingMovement', JSON.parse(endorsment.editing_action));
                store.commit('generalTablePeriod/setPeriodNotice', endorsment.notice);
                store.commit('generalTablePeriod/setNonDeductibleTax', JSON.parse(endorsment.nonDeductibleTax));
                store.commit('generalTablePeriod/setRegisterEditingMovement', JSON.parse(endorsment.register_editing_action));
                store.commit('generalTablePeriod/setFreeTaxSales', JSON.parse(endorsment.freeTax_sales));
             }
         })
         .catch((error) => {
             if (error.response.status === 401) {
                 alert('تم انتهاء جلسة الدخول , أعد تسجبل الدخول .');
                 window.location.href = "/logout";
             }
             console.log(error);
         });
    },

    print: function(target, company, period, title){
        $(target).find('.dsip').remove();

        $(target).find('input').replaceWith(function(){
            return $("<span></span>", {html: $(this).val()});
        });

        $(target).find('.table-striped').removeClass('table-striped');
        $(target).find('thead tr').addClass('header-background');
        $(target).find('tfoot tr').addClass('footer-background');
        $(target).append('<div class="footer-print">'+
                            '<span class="mx-3">'+ store.state.auth.user.name + '</span> - ' + 
                            '<span class="mx-2 text-muted">'+ new Date().toLocaleString() + '</span></div>');
   
        $('body').append(target);

        let header = `
            <div>
                <h2 class='text-center my-2'>
                    ${company.name} - الفترة ${period.year} / ${period.period}
                </h2>
                <h5> ${title}</h5>
            </div>
        `;
     
        printJS({
            printable: "clonePrinting", 
            type: 'html', 
            header: header,
            css: "/css/bootstrap.min.css",
        });

        $('#clonePrinting').remove();
    }
}
