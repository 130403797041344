<template>
    <tr>
        <td>
            <input type="text" id="invoiceNumber" v-model="invoiceNumber" class="form-control p-xxl-1 p-0" v-on:keyup.enter="switchFocus" placeholder="رقم الفاتورة">
        </td>
        <td style="width: 150px;font-size: 12px;">
            <input type="text" id="invoiceDate" v-model="invoiceDate" class="form-control p-xxl-1 p-0" v-on:keyup.enter="switchFocus" placeholder="اليوم - الشهر - السنة">
        </td>
        <td>
            <select id="invoiceRate" class="form-control py-0 px-1 p-xxl-1" @keydown.enter="switchFocus" v-model="invoiceRateFromSelect">
                <option selected>-</option>
                <option v-for="setting in settings" :key="setting.id" :value="setting.tax" >{{ setting.tax }}</option>

                <template v-if="withZeroAndExemptTaxes">
                    <option value="zero">صفرية</option>
                    <option value="exempt">معفاة</option>
                </template>
             
                <option value="other">أخرى</option>
            </select>
            <input type="number" min="0" step="0.5" v-if="invoiceRateFromSelect == 'other'" v-model="invoiceRate" v-on:keyup.enter="switchFocus" id="invoiceRate" class="form-control p-0" placeholder="النسبة">
        </td>
        <td>
            <input type="number" min="0" v-model="invoiceValue" @change="invoiceValueChanged" v-on:keydown.enter="switchFocus" id="invoiceValue" class="form-control p-xxl-1 p-0" placeholder="القيمة">
        </td>
        <td>
            <input type="number" min="0" v-model="invoiceTax" @change="invoiceTaxChanged" v-on:keydown.enter="switchFocus" id="invoiceTax" class="form-control p-xxl-1 p-0" placeholder="الضريبة" 
                v-show="invoiceRateFromSelect != 'exempt' && invoiceRateFromSelect != 'zero'">
        </td>
        <td>
            <input type="number" min="0" v-model="invoiceTotal" id="invoiceTotal" @keydown.enter="switchFocus" class="form-control p-xxl-1 p-0" placeholder="الإجمالي" readonly>
        </td>
        <td class="d-flex">
            <button class="btn btn-dark btn-sm rounded-circle save-btn" @click="saveInvoice">
                <b-icon-plus-lg></b-icon-plus-lg>
            </button>
            <button class="btn btn-danger btn-sm rounded-circle me-1" @click="resetInputs">
                <b-icon-x-lg></b-icon-x-lg>
            </button>
        </td>
    </tr>
</template>

<script>
import alertFunctions from "../../../public/js/alertFunctions.js";
import config from "../../../public/js/config";
import functions from "../../../public/js/globalFunctions";
import $ from 'jquery';

export default {
    name: "AddInvoiceWithTax",
    props: ["period_id", "invoiceType", "default_tax"],
    data: () => {
        return {
            invoiceNumber: "",
            invoiceDate: "",
            invoiceTotal: "",
            invoiceTax: "",
            invoiceValue: "",
            invoiceRate: "",
            invoiceRateFromSelect: "",
            isRequesting: false,
        }
    },
    computed: {
        settings: function(){
            return this.$store.state.generalTablePeriod.settings;
        },
        rightRateInput: function(){
            if(['zero', 'exempt'].includes(this.invoiceRateFromSelect)) {
                return {value: 0, type: this.invoiceRateFromSelect};
            }
            else {
                return (this.invoiceRateFromSelect != 'other')? 
                    {value: this.invoiceRateFromSelect, type: null} :
                    {value: this.invoiceRate, type: 'other'};
            }
        },
        withZeroAndExemptTaxes: function(){
            return (['sales', 'purchases', 'custards'].includes(this.invoiceType));
        }
    },
    watch: {
        "invoiceDate": function(){
            this.invoiceDate+= this.invoiceDate.length == 2 ? "-" : "";
            this.invoiceDate+= this.invoiceDate.length == 5 ? "-" : "";
            this.invoiceDate = this.invoiceDate.replace('--', '-');
            this.invoiceDate = (this.invoiceDate.length > 10)? this.invoiceDate.slice(0,10) : this.invoiceDate;
        },
        "invoiceRate": function(){
            if( this.invoiceType != "custards" ){   // prevent calculate for custards section
                if(this.invoiceTax != null && this.invoiceTax != undefined){
                    this.invoiceValue = (this.invoiceTax / (this.rightRateInput.value /100)).toFixed(3);
                    this.invoiceTotal = parseFloat(this.invoiceTax + this.invoiceValue).toFixed(3);
                }
                else if(this.invoiceValue != null && this.invoiceValue != undefined){
                    this.invoiceTax = ((this.rightRateInput.value/100) * this.invoiceValue).toFixed(3);
                    this.invoiceTotal = parseFloat(this.invoiceTax + this.invoiceValue).toFixed(3);
                }
            }
        },
    },
    methods: {
        "saveInvoice": function(){
            if(this.isRequesting == true)
                return false;

            if( this.validateInputs() == false ) {
                alertFunctions.ErrorAlert("يرجى تعبئة قيم بيانات الفاتورة قبل الحفظ !");
                return false;
            }

            let token = this.$store.state.auth.user['token'];
            let formData = new FormData();
            let rate = (this.rightRateInput.type == null || this.rightRateInput.type == 'other')? 
                            this.rightRateInput.value : this.rightRateInput.type; 
            formData.append('invoiceNumber', this.invoiceNumber);
            formData.append('invoiceTax', this.invoiceTax);
            formData.append('invoiceValue', this.invoiceValue);
            formData.append('invoiceRate', rate);
            formData.append('invoiceDate', this.invoiceDate);
            formData.append('invoiceTotal', this.invoiceTotal);
            formData.append('period_id', this.period_id);

            this.isRequesting = true;
            this.axios.post(config.api_url() + this.invoiceType, formData, {headers: {"Authorization": "Bearer " + token}})
                .then((response) => {
                    // console.log(response);
                    if( response.data.status == "success" ){
                        alertFunctions.SuccessAlert("تم حفظ الفاتورة بنجاح");

                        let arr = [];
                        switch (this.invoiceType) {
                            case "purchases":
                                arr = this.$store.state.generalTablePeriod.purchasesTable;
                                arr.push(response.data.purchase);
                                this.$store.commit('generalTablePeriod/setPurchasesTable', arr);
                                break;
                        
                            case "outlays":
                                arr = this.$store.state.generalTablePeriod.outlaysTable;
                                arr.push(response.data.outlay);
                                this.$store.commit('generalTablePeriod/setOutlaysTable', arr);
                                break;
                        
                            case "custards":
                                arr = this.$store.state.generalTablePeriod.custardsTable;
                                arr.push(response.data.custard);
                                this.$store.commit('generalTablePeriod/setCustardsTable', arr);
                                break;

                            case "sales":
                                arr = this.$store.state.generalTablePeriod.salesTable;
                                arr.push(response.data.sale);
                                this.$store.commit('generalTablePeriod/setSalesTable', arr);
                                break;

                            case "imported-services":
                                arr = this.$store.state.generalTablePeriod.importedServicesTable;
                                arr.push(response.data.importedService);
                                this.$store.commit('generalTablePeriod/setImportedServicesTable', arr);
                                break;

                            default: break;
                        }
                        
                        functions.getEndorsmentData(this.period_id);
                        this.resetInputs();
                    }
                    else{
                        let errors = response.data.messages;
                        let result = "";
                        errors.forEach(error => {
                            result+= error + "\n";
                        });
                        alertFunctions.ErrorAlert(result);
                    }
                    this.isRequesting = false;
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        alert('تم انتهاء جلسة الدخول , أعد تسجبل الدخول .');
                        window.location.href = "/logout";
                    }
                    else{
                        alertFunctions.ErrorAlert("حدث خطأ ما !!");
                    }
                    this.isRequesting = false;
                });
        },
        "validateInputs": function(){
            if( this.invoiceNumber == "" ||
                this.invoiceDate == "" ||
                (this.invoiceTax == "" && !(this.invoiceRateFromSelect == 'zero' || this.invoiceRateFromSelect == 'exempt') ) ||
                this.invoiceValue == "" ||
                (this.invoiceRate == "" && this.invoiceRateFromSelect == "")
            ){
                return false;
            }

            if( this.invoiceRateFromSelect == "other" && this.invoiceRate == "")
                return false;

            return true;
        },
        "resetInputs": function(){
            this.invoiceNumber = "";
            this.invoiceDate = "";
            this.invoiceRate = "";
            this.invoiceValue = "";
            this.invoiceTax = "";
            this.invoiceRateFromSelect = this.default_tax;
            this.invoiceTotal = "";
        },
        "invoiceValueChanged": function(){
            if( this.invoiceType != "custards" ){   // prevent calculate for custards section
                if(this.rightRateInput != null && this.rightRateInput != undefined)
                    this.invoiceTax = ((this.rightRateInput.value/100) * this.invoiceValue).toFixed(3);
            }
         
            this.invoiceTotal = (parseFloat(this.invoiceTax) + parseFloat(this.invoiceValue)).toFixed(3);
        },
        "invoiceTaxChanged": function(){
            if( this.invoiceType != "custards" ){   // prevent calculate for custards section
                if(this.rightRateInput != null && this.rightRateInput != undefined)
                    this.invoiceValue = (this.invoiceTax / (this.rightRateInput.value /100)).toFixed(3);
            }
            this.invoiceTotal = (parseFloat(this.invoiceTax) + parseFloat(this.invoiceValue)).toFixed(3);
        },
        "switchFocus": function(e) {
            e.preventDefault();

            var next = $(':focus').parent().next();
            if ( next.find('.save-btn').length > 0 ) {
                next.find('.save-btn').trigger('click');
            }
            else {
                next.find('input,select').focus();
            }
        }
    },
    created: function(){
        if( this.default_tax != undefined ){
            this.invoiceRateFromSelect = this.default_tax;
        }
    },
}
</script>