<template>
    <div class="login-layout">
        <form class="m-auto p-4 from-layout">
            <h3>تسجيل الدخول</h3>

            <div id="status-layout"></div>
            <input type="email" v-model="email" class="form-control my-2" placeholder="البريد الالكتروني">
            <input type="password" v-model="password" class="form-control my-2" placeholder="كلمة المرور">
            <button type="button" @click="login" class="btn btn-success">تسجيل الدخول</button>
        </form>
    </div>
</template>

<style scoped>
    .login-layout{
        height: 80vh;
        display: flex;
    }

    .from-layout{
        width: 35%;
        background: #fbfbfb;
        border: 1px solid transparent;
        border-radius: 13px;
        box-shadow: 0px 0px 12px #d6d6d67a;
    }

    @media(min-width: 1400px){
        .from-layout{
            width: 30%;
        }
    }
</style>

<script>
import axios from "axios";
import config from "../../../public/js/config.js";

export default {
    name: "Login",
    data(){
        return {
            email: "",
            password: ""
        }
    },
    methods: {
        login: function(){
            let formData = new FormData();
            formData.append('email', this.email);
            formData.append('password', this.password);

            axios.post(config.api_url() + 'login', formData)
                .then((response) => {
                    if(response.status == 200){
                        let data = response.data;
                        if( data['status'] == "success" ){
                            let user = {
                                id: (data['user'].main_user_id != null)? data['user'].main_user_id : data['user'].id,
                                name: data['user'].name,
                                email: data['user'].email,
                                main_user_id: data['user'].main_user_id,
                                expiry_date: data['user'].expiry_date,
                                is_suspended: data['user'].is_suspended,
                                token: data['token']
                            };

                            window.localStorage.setItem('user', JSON.stringify(user));
                            sessionStorage.tabID = Date.now();
                            localStorage.setItem('tabID', Date.now());
                            window.location.href = "/";
                        }
                        else{
                            let errorLayout = document.getElementById('status-layout');
                            errorLayout.innerHTML = "";
                            let newError = document.createElement('div');
                            newError.classList.add('alert', 'alert-danger');
                            newError.innerHTML = data['message'];
                            errorLayout.appendChild(newError);
                        }
                    }
                })
                .catch(() => {
                    console.log('error');
                });
        }
    }

}
</script>