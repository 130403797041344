<template>
    <div>
        <nav class="navbar navbar-expand-lg navbar-light p-3">
            <a class="navbar-brand" href="/">
                <img src="/logo.png" style="width: 115px;" alt="logo image">
            </a>
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarNavAltMarkup">
                <ul class="d-flex m-0 w-100">
                    <router-link to="/" active-class="active-nav-link" class="nav-item nav-link">الرئيسية</router-link>

                    <template v-if="user != null">
                        <router-link to="/companies/active" active-class="active-nav-link" class="nav-item nav-link">الشركات الفعّالة</router-link>
                        <router-link to="/companies/archived" active-class="active-nav-link" class="nav-item nav-link">الشركات المؤرشفة</router-link>
                        <router-link to="/companies/post-status-report" active-class="active-nav-link" class="nav-item nav-link">كشف الترحيل</router-link>
                        <router-link to="/settings" active-class="active-nav-link"  class="nav-item nav-link">الاعدادات</router-link>

                        <template class="nav-item nav-link last-item">
                            <button @click.prevent="logout" class="btn btn-danger">
                                تسجيل الخروج 
                                <b-icon-box-arrow-in-left />
                            </button>
                        </template>
                    </template>
                    <template v-else>
                        <router-link to="/login" class="nav-item nav-link last-item">
                            <button type="button" class="btn btn-dark">
                                تسجيل الدخول
                                <b-icon-box-arrow-in-right />
                            </button>
                        </router-link>
                    </template>
                </ul>
            </div>
        </nav>
    </div>
</template>

<style scoped>
    .navbar {
        background-image: linear-gradient(120deg, #a1c4fd 0%, #c2e9fb 100%);
    }
    .nav-link{
        font-size: 18px;
        color: black;
        text-shadow: 0px 0px 3px #dedede;
        height: max-content;
        margin: auto 5px;
        border-radius: 7px;
        transition: 0.2s;
    }

    .nav-link:not(.last-item):hover,
    .active-nav-link {
        color: white !important;
        background: black;
        transform: scale(1.03);
        box-shadow: 1px 1px 4px #ccc;
    }

    .last-item{
        margin-right: auto;
    }
</style>

<script>
import store from "@/store/index.js";

export default {
    name: "NavBar",
    store,
    computed: {
        user(){
            return this.$store.state.auth.user;
        }
    },
    methods: {
        "logout": function(){
            let question = window.confirm('هل أنت متأكد من تسجيل الخروج ؟');
            if( question == true ){
                window.location.href = "/logout";
            }
        }
    }
}
</script>