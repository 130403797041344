<script>
import axios from "axios";
import store from "@/store/index.js";
import config from "../../../../public/js/config";

export default {
    name: "logout",
    store,
    created: function(){
        let admin = this.$store.state.auth.admin;
        let formData = new FormData();
        formData.append('id', admin['id']);

        axios.post(
            config.api_url() + 'dashboard/logout', formData,
            { headers: {"admin-token": admin.token} })
            .then((response) => {
                if(response.status == 200){
                    if(response.data.status){
                        window.localStorage.removeItem('admin');
                        this.$store.commit('auth/setAdmin', null);
                        window.location.href = "/dashboard/login";
                    }
                }
            })
            .catch(() => {
                console.log('some error occuerd');
            });
    }
}
</script>