<template>
    <div>
        <template v-if="company.deleted_at == null">
            <div class="d-flex justify-content-evenly dsip">
                <ImportFromExcel :invoicesType="'custards'" :period_id="period.id" class="dsip"/>

                <template v-if="period != null">
                    <FilesForm :period="period" :section="'custards'" />
                </template>
            </div>
        </template>

        <button type="button" class="btn btn-primary btn-sm mb-1 dsip" @click.prevent="print">
            طباعة <b-icon-printer-fill></b-icon-printer-fill>
        </button>

        <div id="printCustardsTable" class="print-section" dir="rtl">
            <table class="table table-striped table-bordered">
                <thead>
                    <tr>
                        <th>رقم الفاتورة</th>
                        <th>التاريخ</th>
                        <th>النسبة</th>
                        <th>القيمة</th>
                        <th>الضريبة</th>
                        <th>الإجمالي</th>
                        <th class="dsip"></th>
                    </tr>
                </thead>

                <tbody>
                    <template v-for="custard in custards" :key="custard.id" :data-invoiceId="custard.invoice.id">
                        <tr v-if="selectedInvoiceForEdit.invoice?.invoice.id != custard.invoice.id">
                            <td>
                                {{ custard.invoice.number }}
                            </td>
                            <td style="font-size: 14px;">
                                {{ custard.invoice.date }}
                            </td>
                            <td>
                                {{ 
                                    (custard.custards_type == null)? 
                                        custard.invoice.rate + '%' : 
                                        ( (custard.custards_type == 'exempt')? 'معفاة' : 'صفرية' )
                                }}
                            </td>
                            <td>
                                {{ custard.invoice.value.toFixed(3) }}
                            </td>
                            <td>
                                {{ (custard.custards_type == null)? custard.invoice.tax : '-' }}
                            </td>
                            <td>
                                {{ total(custard) }}
                            </td>
                            <td class="d-flex dsip" v-if="company.deleted_at == null">
                                
                                <button class="btn btn-success btn-sm rounded-circle mx-1"
                                        @click="this.$store.commit('invoiceData/setEditInvoice', {invoice: custard, type:'custards'})">
                                    <b-icon-pencil-square></b-icon-pencil-square>
                                </button>
                                <button class="btn btn-danger btn-sm rounded-circle" 
                                        @click.prevent="this.$store.dispatch('invoiceData/confirmDeleteInvoice', {invoice: custard.invoice, periodId: period.id, type:'custards'})">
                                    <b-icon-trash></b-icon-trash>
                                </button>
                            </td>
                        </tr>
                        <template v-else>
                            <EditInvoiceWithTax :period_id="period.id" />
                        </template>
                    </template>
              
                    <template v-if="company.deleted_at == null">
                        <AddInvoiceWithTax :period_id="period.id" :invoiceType="'custards'" :default_tax="default_tax_val" class="dsip"/>
                    </template>
                </tbody>

                <tfoot>
                    <tr>
                        <td colspan="3">المجموع : </td>
                        <td>{{ parseFloat(sumCustards['valueSum']).toFixed(3) }}</td>
                        <td>{{ parseFloat(sumCustards['taxSum']).toFixed(3) }}</td>
                        <td colspan="2"></td>
                    </tr>
                </tfoot>
            </table>
        </div>

        <AnalysisTable :invoices="custards" :invoicesType="'custards'" :company="company" :period="period" :key="Math.random(10)" />
    </div>
</template>

<style scoped>
    .print-section{
        page-break-after: always;
    }

    thead {
        background-image: linear-gradient(120deg, #a1c4fd 0%, #c2e9fb 100%);
    }
    tfoot {
        background-image: linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%);
    }

    .header-background{ background-color: #e9e9e9;}
    .footer-background{ background-color: #e9e9e9;}
</style>

<script>
import AddInvoiceWithTax from "../invoices/addInvoiceWithTax.vue";
import EditInvoiceWithTax from "../invoices/editInvoiceWithTax.vue";
import AnalysisTable from "../endorsment/analysisTable.vue";
import ImportFromExcel from "../invoices/importFromExcel.vue";
import FilesForm from "../endorsment/filesForm.vue";
import GlobalFunctions from "../../../public/js/globalFunctions";
import $ from 'jquery';

export default {
    name: "CustardsTable",
    props: ["period", "company"],
    data(){
        return {}
    },
    components: {
        AddInvoiceWithTax,
        ImportFromExcel,
        EditInvoiceWithTax,
        AnalysisTable,
        FilesForm
    },
    computed: {
        'sumCustards': function(){
            let taxesSum = 0, valuesSum = 0, ratesSum = 0;
            this.custards.forEach(element => {
                taxesSum+= element.invoice.tax;
                valuesSum+= element.invoice.value;
                ratesSum+= element.invoice.rate;
            });
            return {"taxSum": taxesSum, "valueSum": valuesSum, "rateSum": ratesSum};
        },
        "invoiceTotal": function(){
            return ((this.invoiceRate/100) * this.invoiceValue) + this.invoiceValue;
        },
        "custards": function(){
            return this.$store.state.generalTablePeriod.custardsTable;
        },
        "default_tax_val": function(){
            return (this.company.default_tax_object != null)? this.company.default_tax_object.tax : null;
        },
        "selectedInvoiceForEdit": function(){
            return {
                "invoice": this.$store.state.invoiceData.editInvoice , 
                "type": this.$store.state.invoiceData.typeEditInvoice
            };
        }
    },
    methods: {
        "total": function(custard){
            // return (((invoice.rate/100) * invoice.value) + invoice.value).toFixed(3);
            let tax = (custard.custards_type == null)? custard.invoice.tax : 0;
            return (tax + custard.invoice.value).toFixed(3);
        },
        async print() {
            let target = $('#printCustardsTable').clone();
            $(target).attr('id', "clonePrinting");
            let title = "جدول المستوردات";
            GlobalFunctions.print(target, this.company, this.period, title);
        },
    },
    updated: function(){
        let valueSum = this.sumCustards['valueSum'];
        let taxSum = this.sumCustards['taxSum'];

        if( valueSum != 0 && taxSum != 0 ){
            this.$store.commit('generalTablePeriod/setCustards', {'value': valueSum, 'tax': taxSum});
        }
    }
}
</script>